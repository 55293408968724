

import { useContext } from "react";
import ThemeContext from '../contexts/themeContext';

export default function UniMail(props) {
  const themeCtx = useContext(ThemeContext);

  return <img src={themeCtx.theme === 'dark' ? `/dark/${props.source}` : `light/${props.source}`} {...props}>
        {props.children}
    </img>
}