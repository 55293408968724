import { Popover, Box, Typography, Divider, Button } from '@mui/material';

import { useContext, useEffect } from "react";
import AuthContext from '../../contexts/authContext';
import { getEmailDateTimeFormat } from '../../utils';

export default function Detail({pos, show, setShow, item}) {
    const authCtx = useContext(AuthContext);

    const handleClose = (e) => {
        setShow(false);
        e.preventDefault();
    };

    // useEffect(() => {
    //     console.log(item);
    // }, [item])

    return <Popover
        sx={{
            "& .MuiPaper-root": {
                display : 'flex',
                border: 'solid',
                borderWidth: '0px',
                borderColor: 'gray'
            }
        }}
        anchorReference="anchorPosition"
        anchorPosition={pos}
        open={show}
        disableEnforceFocus={true}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        onContextMenu={handleClose}
    >
        <Box className="flex flex-col p-[15px]">
            <Box className="flex flex-row">
                <span className="w-24 font-medium text-black text-sm text-right text-gray-400">from:</span>
                <span className="pl-5 font-medium text-sm">{item?.from_name} &lt; {item?.from} &gt;</span>
            </Box>
            <Box className="flex flex-row">
                <span className="w-24 font-medium text-black text-sm text-right text-gray-400">to:</span>
                <span className="pl-5 font-medium text-sm">{item?.to.startsWith("[") ? JSON.parse(item?.to).map((itm) => {return <span>{itm}<br></br></span>}) : item?.to}</span>
            </Box>
            {
                (item?.cc !== "")?
                <Box className="flex flex-row flex-wrap">
                    <span className="w-24 font-medium text-black text-sm text-right text-gray-400">cc:</span>
                    <span className="pl-5 font-medium text-sm">{item?.cc.startsWith("[") ? JSON.parse(item?.cc).map((itm) => {return <span>{itm}<br></br></span>}) : item?.cc}</span>
                </Box>:""
            }
            {
                (item?.bcc !== "")?
                <Box className="flex flex-row flex-wrap">
                    <span className="w-24 font-medium text-black text-sm text-right text-gray-400">bcc:</span>
                    <span className="pl-5 font-medium text-sm">{item?.bcc.startsWith("[") ? JSON.parse(item?.bcc).map((itm) => {return <span>{itm}<br></br></span>}) : item?.bcc}</span>
                </Box>:""
            }
            <Box className="flex flex-row">
                <span className="w-24 font-medium text-black text-sm text-right text-gray-400">date:</span>
                <span className="pl-5 font-medium text-sm">{getEmailDateTimeFormat(item?.date, 4)}</span>
            </Box>
            <Box className="flex flex-row">
                <span className="w-24 font-medium text-black text-sm text-right text-gray-400">subject:</span>
                <span className="pl-5 font-medium text-sm">{item?.subject}</span>
            </Box>
            <Box className="flex flex-row">
                <span className="w-24 font-medium text-black text-sm text-right text-gray-400">mailed-by:</span>
                <span className="pl-5 font-medium text-sm">unimail.in</span>
            </Box>
        </Box>
    </Popover>
}