
import { useContext, useState, useEffect } from "react";
import { Button, Typography } from '@mui/material';
import { ListItem } from '../../Components';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingContext from '../../contexts/loadingContext';
import AuthContext from "../../contexts/authContext";
import { formatPhoneNumber, hidePhoneInfo, hideMailInfo } from '../../utils';
import { getRecoveryInfo, sendSms } from "../../apis/user";

export default function ChooseMethod({goToSignIn, onNext, setEncryptCode, setRecoveryContact, contact}) {
    const loadingCtx = useContext(LoadingContext);
    const authCtx = useContext(AuthContext);

    const [recoveryPhone, setRecoveryPhone] = useState("");
    const [recoveryMail, setRecoveryMail] = useState("");

    const sendVerifyCode = (address, phone) => {
        loadingCtx.setLoading(true);
        setTimeout(() => {
            sendSms(address, phone, "")
            .then((res) => {
                setEncryptCode(res.message);
                setRecoveryContact({address, phone});
                onNext();
                loadingCtx.setLoading(false);
            })
            .catch((e) => {
                loadingCtx.setLoading(false);
            })
        }, 1000);
    }

    useEffect(() => {
        // console.log('contact', contact)
        getRecoveryInfo(contact)
        .then((res) => {
            // console.log('res', res)
            setRecoveryPhone(res.phone);
            setRecoveryMail(res.address)
        })
        .catch((e) => {})
    }, [contact]);

    return (
        <>
            <p className="text-2xl text-center pb-2">Account recovery</p>
            <p className="text-base text-center pb-4">This helps show that this account really belongs to you</p>
            <p className="text-base text-center pb-8">
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: "none",
                        borderRadius: "99px"
                    }}
                    startIcon={<AccountCircleIcon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={()=>{authCtx.setAddress(contact.address); goToSignIn()}}>
                    {contact.address}
                    {formatPhoneNumber(contact.phone, true)}
                </Button>
            </p>
            <p className="text-base font-bold pb-2">Try another way to sign in</p>
            {
                recoveryMail === "" ? "" :
                <ListItem 
                    onClick={() => sendVerifyCode(recoveryMail, "")}
                    className="flex justify-items-center items-center bg-white hover:bg-sky-100 text-sky-600 px-1 py-2 cursor-pointer"
                    style={{borderTop: '1px solid #e0e0e0'}}
                >
                    <CommentOutlinedIcon className="mr-2" />
                    <Typography variant="body1" className="py-2">
                        Get a verification code at {hideMailInfo(recoveryMail)}
                    </Typography>
                </ListItem>
            }
            {
                recoveryPhone === "" ? "" :
                <ListItem 
                    onClick={() => sendVerifyCode("", recoveryPhone)}
                    className="bg-white hover:bg-sky-100 text-sky-600 border-t border-slate-300 px-1 py-2 cursor-pointer"
                >
                    <div className="flex justify-items-center items-center ">
                        <CommentOutlinedIcon className="mr-2" />
                        <Typography className="pb-1" variant="body1">
                            Get a verification code at {hidePhoneInfo(recoveryPhone)}
                        </Typography>
                    </div>
                    <Typography variant="body2" color="text.disabled">
                        Standard rates apply
                    </Typography>
                </ListItem>
            }
        </>
    );
}