
import { createContext } from 'react';

const SearchContext = createContext({
	search: '',
    setSearch: (search) => {},
    input: '',
    setInput: (input) => {},
});

export default SearchContext;