import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Terms() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Introduction" {...a11yProps(0)} />
        <Tab label="Information Collection" {...a11yProps(1)} />
        <Tab label="Information Use" {...a11yProps(2)} />
        <Tab label="Information Sharing" {...a11yProps(3)} />
        <Tab label="Security Measures" {...a11yProps(4)} />
        <Tab label="Cookies and Tracking" {...a11yProps(5)} />
        <Tab label="Data Retention" {...a11yProps(6)} />
        <Tab label="Third-Party Links" {...a11yProps(7)} />
        <Tab label="Users' Rights" {...a11yProps(8)} />
        <Tab label="Policy Changes" {...a11yProps(9)} />
        <Tab label="Contact Information" {...a11yProps(10)} />
      </Tabs>
      <TabPanel value={value} index={0} style={{ width: '70%' }}>
      At Unimail, your privacy is of paramount importance to us. This Detailed Privacy Policy provides a comprehensive explanation of how we collect, use, manage, and protect your personal information when you use our services. By using our platform, you consent to the practices outlined in this policy. If you have any questions or concerns, please don't hesitate to reach out to us.
      </TabPanel>
      <TabPanel value={value} index={1} style={{ width: '70%' }}>
      We collect various types of personal information to deliver and improve our services. This information includes:

Account information: When you create an account with us, we collect your name, email address, and a password to manage and secure your account.

Contact information: We may store the email addresses of your contacts to facilitate communication through our platform.

Usage data: We gather data on your usage patterns and interactions with our service, such as the features you access, the frequency of use, and time spent on our platform.

Technical data: We collect technical information, such as your IP address, browser type, device information, and other technical details necessary for providing our service.
      </TabPanel>
      <TabPanel value={value} index={2} style={{ width: '70%' }}>
      We use the collected information for various purposes, including:
Providing and maintaining our service: We use your information to ensure that our platform functions smoothly and meets your needs.
Enhancing and personalizing your experience: We analyze usage data to improve our features and tailor them to your preferences.
Identifying and preventing fraud, abuse, and security issues: We monitor for any suspicious activities and take appropriate actions to protect our users.
Communicating with you: We may send you updates, promotional offers, and other relevant communications related to our services.
Legal compliance and law enforcement cooperation: We may use or disclose your information to comply with legal requirements or respond to law enforcement requests.
      </TabPanel>
      <TabPanel value={value} index={3} style={{ width: '70%' }}>
      We are committed to safeguarding your personal information and do not sell, rent, or share it with third parties, except under the following circumstances:
With your consent: We may share your information with third parties when you explicitly give us permission to do so.
Legal compliance: We may disclose your information if legally required or in cooperation with a legal investigation.
Business transfers: In the event of a merger, acquisition, or sale of assets, we may transfer your information to the new entity, subject to the terms of this Privacy Policy.
      </TabPanel>
      <TabPanel value={value} index={4} style={{ width: '70%' }}>
      We implement a range of security measures to protect your personal information, including:
Encryption: We use encryption to protect sensitive data during transmission and storage.

Secure storage and access controls: We store your information in secure data centers with restricted access controls.

Regular monitoring and testing: We continuously monitor our systems for potential threats and vulnerabilities and conduct regular security assessments.
      </TabPanel>
      <TabPanel value={value} index={5} style={{ width: '70%' }}>
      We use cookies and other tracking technologies to enhance your experience on our platform and analyze the performance of our website. You can manage your preferences regarding cookies through your browser settings.
      </TabPanel>
      <TabPanel value={value} index={6} style={{ width: '70%' }}>
      We retain your personal information for as long as necessary to provide our services, comply with legal obligations, or fulfill any other relevant purposes. You can request the deletion of your data by contacting us.
      </TabPanel>
      <TabPanel value={value} index={7} style={{ width: '70%' }}>
      Our service may contain links to third-party websites or services. We are not responsible for the privacy practices of these external sites and encourage you to review their privacy policies.
      </TabPanel>
      <TabPanel value={value} index={8} style={{ width: '70%' }}>
      As a user, you have the following rights concerning your personal information:

Access: You can request a copy of your personal information held by us.
Correction: You can ask us to correct any inaccurate or incomplete information about you.
Deletion: You can request that we delete your personal data.
Objection or restriction: You can object to or request restrictions on the processing of your personal information under certain circumstances.

To exercise these rights, please contact us, and we will respond to your request within a reasonable time frame, as required by applicable laws.
      </TabPanel>
      <TabPanel value={value} index={9} style={{ width: '70%' }}>
      We may occasionally update this Privacy Policy to reflect changes in our practices or to comply with legal requirements. When significant changes occur, we will notify you through email, our website, or other appropriate communication methods. We will also update the "Effective Date" at the beginning of the policy. Your continued use of our services after such updates constitutes your acceptance of the revised Privacy Policy.
      </TabPanel>
      <TabPanel value={value} index={10} style={{ width: '70%' }}>
      If you have any questions, concerns, or requests related to our Privacy Policy or your personal information, please contact us at:

Unimail Customer Support
Email: support@unimail.com

We are committed to addressing your concerns and resolving any issues related to your privacy promptly and professionally.
      </TabPanel>
    </Box>
  );
}