import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Divider, IconButton, Link, Typography } from '@material-ui/core';
import { Avatar } from "../Components";
import { useNavigate, Navigate } from 'react-router-dom';
import AuthContext from '../contexts/authContext';
import LoadingContext from '../contexts/loadingContext';
import MenuContext from "../contexts/menuContext";
import axios from 'axios';
import { io } from 'socket.io-client';
import Tooltip from '@mui/material/Tooltip';
import { getDomainLogo } from '../apis';
const socket = io();

function Migration() {
  const [migrationStatus, setMigrationStatus] = useState('');
  const [fetchStatus, setFetchStatus] = useState('');
  const [email, setEmail] = useState('');
  const [authStatus, setAuthStatus] = useState(false);
  // const [authComment, setAuthComment] = useState('');
  // const [initiateComment, setInitiateComment] = useState('');
  const [initiateStatus, setInitiateStatus] = useState(false);
  const [gtinbox, setGtinbox] = useState(0);
  const [gminbox, setGminbox] = useState(0);
  const [tinbox, setTinbox] = useState(0);
  const [finbox, setFinbox] = useState(0);
  const [uinbox, setUinbox] = useState(0);
  const [gtpromotions, setGtpromotions] = useState(0);
  const [gmpromotions, setGmpromotions] = useState(0);
  const [tpromotions, setTpromotions] = useState(0);
  const [fpromotions, setFpromotions] = useState(0);
  const [upromotions, setUpromotions] = useState(0);
  const [gtsocial, setGtsocial] = useState(0);
  const [gmsocial, setGmsocial] = useState(0);
  const [tsocial, setTsocial] = useState(0);
  const [fsocial, setFsocial] = useState(0);
  const [usocial, setUsocial] = useState(0);
  const [gtupdates, setGtupdates] = useState(0);
  const [gmupdates, setGmupdates] = useState(0);
  const [tupdates, setTupdates] = useState(0);
  const [fupdates, setFupdates] = useState(0);
  const [uupdates, setUupdates] = useState(0);
  const [gtsent, setGtsent] = useState(0);
  const [gmsent, setGmsent] = useState(0);
  const [tsent, setTsent] = useState(0);
  const [fsent, setFsent] = useState(0);
  const [usent, setUsent] = useState(0);
  const [gtjunk, setGtjunk] = useState(0);
  const [gmjunk, setGmjunk] = useState(0);
  const [tjunk, setTjunk] = useState(0);
  const [fjunk, setFjunk] = useState(0);
  const [ujunk, setUjunk] = useState(0);
  const [gttrash, setGttrash] = useState(0);
  const [gmtrash, setGmtrash] = useState(0);
  const [ttrash, setTtrash] = useState(0);
  const [ftrash, setFtrash] = useState(0);
  const [utrash, setUtrash] = useState(0);
  
  const [count, setCount] = useState(0);
  const [count_skipped, setCountSkipped] = useState(0);
  const [count_imported, setCountImported] = useState(0);

  const [count_fetch, setCountFetch] = useState(0);
  const [count_skipped_fetch, setCountSkippedFetch] = useState(0);
  const [count_imported_fetch, setCountImportedFetch] = useState(0);

  const [fetching, setFetching] = useState(false);
  const [importing, setImporting] = useState(false);

  const [isFetchStartButtonDisabled, setFetchStartButtonDisabled] = useState(false);
  const [isFetchStopButtonDisabled, setFetchStopButtonDisabled] = useState(false);
  const [isSyncButtonDisabled, setSyncButtonDisabled] = useState(false);
  const [isAbortButtonDisabled, setAbortButtonDisabled] = useState(false);

  const authCtx = useContext(AuthContext);
  const loadingCtx = useContext(LoadingContext);
  const navigate = useNavigate();
  const menuCtx = useContext(MenuContext);
  const [logo, setLogo] = useState("");

  const domain = authCtx.getAddress().split('@')[1];
  const domain_name = domain.split('.')[0].charAt(0).toUpperCase() + domain.split('.')[0].slice(1);

  useEffect(() => {
    getDomainLogo(domain)
    .then((res) => { 
      if (res.result) {   
        setLogo("/" + res.logo);
      } else {
        console.log(res.message);
      } 
    })
    .catch((e) => {});
  }, [domain])

  useEffect(() => {
    
    if (!authCtx.isLogin()) {        
        navigate('/signin');
    }
    
  }, [authCtx]) 

  useEffect(() => {
    const fetchTokens = async () => {
      
      const { search } = window.location;
      const match = search.match(/code=(.*?)&/);
      const code = match && match[1];
      if (code) {
        loadingCtx.setLoading(true);
        const res = await axios.get(`https://mail.unimail.in/token?code=${code}&token=${authCtx.token}`);
        console.log(res);
        setAuthStatus(res.data.result);
        setInitiateStatus(res.data.result);
        setEmail(res.data.email);
        if (!res.data.result) {
          setFetchStatus('Authenticate failed: ' + res.data.error);
        } else {
          // setGtinbox(res.data.gcounts.t_inbox);
          // setGminbox(res.data.gcounts.m_inbox);
          // setGtpromotions(res.data.gcounts.t_promotions);
          // setGmpromotions(res.data.gcounts.m_promotions);
          // setGtsocial(res.data.gcounts.t_social);
          // setGmsocial(res.data.gcounts.m_social);
          // setGtsent(res.data.gcounts.t_sent);
          // setGmsent(res.data.gcounts.m_sent);
          // setGtjunk(res.data.gcounts.t_junk);
          // setGmjunk(res.data.gcounts.m_junk);
          // setGttrash(res.data.gcounts.t_trash);
          // setGmtrash(res.data.gcounts.m_trash);
          
          setTinbox(res.data.counts.t_inbox);
          setFinbox(res.data.counts.f_inbox);
          setUinbox(res.data.counts.u_inbox);
          setTpromotions(res.data.counts.t_promotions);
          setFpromotions(res.data.counts.f_promotions);
          setUpromotions(res.data.counts.u_promotions);
          setTsocial(res.data.counts.t_social);
          setFsocial(res.data.counts.f_social);
          setUsocial(res.data.counts.u_social);
          setTupdates(res.data.counts.t_updates);
          setFupdates(res.data.counts.f_updates);
          setUupdates(res.data.counts.u_updates);
          setTsent(res.data.counts.t_sent);
          setFsent(res.data.counts.f_sent);
          setUsent(res.data.counts.u_sent);
          setTjunk(res.data.counts.t_junk);
          setFjunk(res.data.counts.f_junk);
          setUjunk(res.data.counts.u_junk);
          setTtrash(res.data.counts.t_trash);
          setFtrash(res.data.counts.f_trash);
          setUtrash(res.data.counts.u_trash);
          setFetchStatus('Fetched mails from Gmail');
          setCount(res.data.total);
        }
        
        loadingCtx.setLoading(false);
      }
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    // Function to run when the page is about to be unloaded
    const handleBeforeUnload = (event) => {
      // Pause the migration
      handleAbort();
      handleFetchStop();
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };
    
    // Add the event listener when the component is mounted
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  const handleAuth = async () => {
    setMigrationStatus('Authenticating...');
    try {
      loadingCtx.setLoading(true);
      const res = await axios.get(`https://mail.unimail.in/auth?token=${authCtx.token}`);
      window.location.href = res.data.url;
    } catch (error) {
      console.error(error);
      setMigrationStatus('Authenticating error: ' + error);
      loadingCtx.setLoading(false);
    }
  };

  const handleFetchStart = async () => {
    setCountFetch(0);
    if (!authStatus) {
      setFetchStatus('Please authenticate your Gmail account!');
      return;
    }    
    setFetchStatus('Migrating from Gmail...');
    setFetchStartButtonDisabled(true);
    setSyncButtonDisabled(true);
    setAbortButtonDisabled(true);
    setFetching(true);
    setImporting(false);
    
    try {
      
      socket.on('count-fetch', (param) => {
        console.log('passed fetch mails: ', param);
        if (param.user === authCtx.getAddress())
          setCountFetch(param.count);
      });

      socket.on('skipped-fetch', (param) => {
        console.log('skipped fetch mails: ', param);
        if (param.user === authCtx.getAddress())
          setCountSkippedFetch(param.count_skipped);
      });

      socket.on('imported-fetch', (param) => {
        console.log('fetched really mails: ', param);
        if (param.user === authCtx.getAddress())
          setCountImportedFetch(param.count_imported);
      });

      const res = await axios.post('https://mail.unimail.in/fetch_start', {token: authCtx.token});
      setInitiateStatus(true); 
      console.log('fetching-error: ', res.data.error);
      setFetchStatus(res.data.result ?'Migrating done':'Migrating has some issues now, you can stop and restart the actions');     
      
      setFetchStartButtonDisabled(false);
      setSyncButtonDisabled(false);
      setAbortButtonDisabled(false);

      if (!res.data.result) {
        if (res.data?.error.includes('No access') || res.data.error.includes('refresh token') || res.data.error.includes('API key')) {
          setAuthStatus(false);
          return;
        }
      }

      setTinbox(res.data.counts.t_inbox);
      setFinbox(res.data.counts.f_inbox);
      setUinbox(res.data.counts.u_inbox);
      setTpromotions(res.data.counts.t_promotions);
      setFpromotions(res.data.counts.f_promotions);
      setUpromotions(res.data.counts.u_promotions);
      setTsocial(res.data.counts.t_social);
      setFsocial(res.data.counts.f_social);
      setUsocial(res.data.counts.u_social);
      setTupdates(res.data.counts.t_updates);
      setFupdates(res.data.counts.f_updates);
      setUupdates(res.data.counts.u_updates);
      setTsent(res.data.counts.t_sent);
      setFsent(res.data.counts.f_sent);
      setUsent(res.data.counts.u_sent);
      setTjunk(res.data.counts.t_junk);
      setFjunk(res.data.counts.f_junk);
      setUjunk(res.data.counts.u_junk);
      setTtrash(res.data.counts.t_trash);
      setFtrash(res.data.counts.f_trash);
      setUtrash(res.data.counts.u_trash);
      setCountFetch(res.data.total); 

    } catch (error) {
      setFetchStatus('Migrating has some issues now, you can stop and restart the actions');
      console.error(error);
      loadingCtx.setLoading(false);
      setFetchStartButtonDisabled(false);
      setSyncButtonDisabled(false);
      setAbortButtonDisabled(false);
    }
    
  };

  const handleFetchStop = async () => {
    setFetchStatus('Stoping Migrating...');
    setCountFetch(0);
    try {
      setFetchStartButtonDisabled(true);
      setFetchStopButtonDisabled(true);
      setSyncButtonDisabled(true);      
      setAbortButtonDisabled(true);
      const res = await axios.post('https://mail.unimail.in/fetch_stop', {token: authCtx.token});
      setFetchStatus(res.data.result ? 'Migrating aborting...' : 'Migrating aborting failed');
      
      setFetchStartButtonDisabled(false);
      setFetchStopButtonDisabled(false);
      setSyncButtonDisabled(false);      
      setAbortButtonDisabled(false);
    } catch (error) {
      setFetchStatus('Migrating has some issues now, you can stop and restart the actions');
      console.error(error);
      setFetchStartButtonDisabled(false);
      setFetchStopButtonDisabled(false);
      setSyncButtonDisabled(false);      
      setAbortButtonDisabled(false);
    }
  }

  const handleSync = async () => {
    setCount(0);
    if (!authStatus) {
      setMigrationStatus('Please authenticate your Gmail account!');
      return;
    }
    setMigrationStatus('Processing...');
    setFetchStartButtonDisabled(true);
    setFetchStopButtonDisabled(true);
    setSyncButtonDisabled(true);      
    setFetching(false);
    setImporting(true);

    try {
      
      socket.on('count', (param) => {
        console.log('migrated mails: ', param);
        if (param.user === authCtx.getAddress())
          setCount(param.count);
      });

      socket.on('skipped', (param) => {
        console.log('skipped mails: ', param);
        if (param.user === authCtx.getAddress())
          setCountSkipped(param.count_skipped);
      });

      socket.on('imported', (param) => {
        console.log('imported mails: ', param);
        if (param.user === authCtx.getAddress())
          setCountImported(param.count_imported);
      });

      const res = await axios.post('https://mail.unimail.in/sync', {token: authCtx.token});
      setMigrationStatus(res.data.result ? 'Importing done' : 'Importing has some issues now, you can stop and restart the actions');
      
      setFetchStartButtonDisabled(false);
      setFetchStopButtonDisabled(false);
      setSyncButtonDisabled(false);      
      setAbortButtonDisabled(false);

      if (!res.data.result) {
        if (res.data?.error.includes('No access') || res.data.error.includes('refresh token') || res.data.error.includes('API key')) {
          setAuthStatus(false);
          return;
        }
        return;
      }

      setTinbox(res.data.counts.t_inbox);
      setFinbox(res.data.counts.f_inbox);
      setUinbox(res.data.counts.u_inbox);
      setTpromotions(res.data.counts.t_promotions);
      setFpromotions(res.data.counts.f_promotions);
      setUpromotions(res.data.counts.u_promotions);
      setTsocial(res.data.counts.t_social);
      setFsocial(res.data.counts.f_social);
      setUsocial(res.data.counts.u_social);
      setTupdates(res.data.counts.t_updates);
      setFupdates(res.data.counts.f_updates);
      setUupdates(res.data.counts.u_updates);
      setTsent(res.data.counts.t_sent);
      setFsent(res.data.counts.f_sent);
      setUsent(res.data.counts.u_sent);
      setTjunk(res.data.counts.t_junk);
      setFjunk(res.data.counts.f_junk);
      setUjunk(res.data.counts.u_junk);
      setTtrash(res.data.counts.t_trash);
      setFtrash(res.data.counts.f_trash);
      setUtrash(res.data.counts.u_trash);
      setCount(res.data.total);
      
    } catch (error) {
      setMigrationStatus('Importing has some issues now, you can stop and restart the actions');
      console.error(error);
      setFetchStartButtonDisabled(false);
      setFetchStopButtonDisabled(false);
      setSyncButtonDisabled(false);      
      setAbortButtonDisabled(false);
    }
  };

  const handleAbort = async () => {
    setMigrationStatus('Aborting...');
    setCount(0);
    try {
      setFetchStartButtonDisabled(true);
      setFetchStopButtonDisabled(true);
      setSyncButtonDisabled(true);      
      setAbortButtonDisabled(true);
      const res = await axios.post('https://mail.unimail.in/abort', {token: authCtx.token});
      setMigrationStatus(res.data.result ? 'Importing aborted' : 'Importing aborting failed');
      
      setFetchStartButtonDisabled(false);
      setFetchStopButtonDisabled(false);
      setSyncButtonDisabled(false);      
      setAbortButtonDisabled(false);
    } catch (error) {
      setMigrationStatus('Importing has some issues now, you can stop and restart the actions');
      console.error(error);
      setFetchStartButtonDisabled(false);
      setFetchStopButtonDisabled(false);
      setSyncButtonDisabled(false);      
      setAbortButtonDisabled(false);
    }
  }

  return (
    
    <Box className="App">
      <div className="header desktop bg-slate-200 flex flex-nowrap">
        <div className="header-left">
          <Tooltip title="Unimail" placement="right-end">
            <Link href="/" underline="none" className='flex items-center'>
              <img className="p-4 pr-2 pl-4 h-[64px]" src="/unimail-logo.svg" alt="Unimail" /> 
              <Typography variant="h5" className="text-gray-800 !font-bold">Unimail</Typography>
            </Link>
          </Tooltip>
          
        </div>
        <div className="header-main">
          <div className=" flex justify-center">
            <h1>Email Migration to Unimail from Gmail</h1>
          </div>
          <div className="header-right">             
            {
              logo === '/' 
              ? 
              <Tooltip title="Unimail Account" placement="bottom-start">
                <IconButton size="small" onClick={(e) => menuCtx.showProfile(e)}>
                  <Avatar size={36} isMe={true}/>
                </IconButton>
              </Tooltip> 
              :
              <div className="flex items-center justify-center border rounded-lg border-slate-200 border-solid pr-1">         
                <img className="py-2 pr-2 pl-2 h-[48px]" src={logo} alt={domain_name} /> 
                <Tooltip title="Unimail Account" placement="bottom-start">
                  <IconButton size="small" onClick={(e) => menuCtx.showProfile(e)}>
                    <Avatar size={32} isMe={true}/>
                  </IconButton>
                </Tooltip>
              </div> 
            }            
          </div>
        </div>
      </div>
      <div className="py-[20px] px-[100px]">
        <div calssName="">
            { authStatus
            ?
            <>
              <p>Successfully authenticated</p>
              <h2>{email} {'->'} {authCtx.getAddress()}</h2>
              <br></br>
              <p>Please fetch your gmail messages to import into unimail </p>
              {/* <p>INBOX: {gminbox}({gtinbox}), Promotions: {gmpromotions}({gtpromotions}), Social: {gmsocial}({gtsocial}), Sent: {gmsent}({gtsent}), Junk: {gmjunk}({gtjunk}), Trash: {gmtrash}({gttrash}) </p>
              <br></br> */}
              <Button 
                onClick={handleFetchStart} 
                disabled={isFetchStartButtonDisabled}
                style={{backgroundColor: 'green', borderRadius:'5px', color:'white', minWidth:'20px', marginRight:'10px'}}
              >
                Start Migrating
              </Button>  
              <Button 
                onClick={handleFetchStop} 
                disabled={isFetchStopButtonDisabled}
                style={{backgroundColor: 'red', borderRadius:'5px', color:'white', minWidth:'20px', marginRight:'10px'}}
              >
                Stop Migrating
              </Button>
            </>         
            :
            <Button 
              onClick={handleAuth}
              style={{backgroundColor: '#3079ed', borderRadius:'5px', color:'white', minWidth:'20px', marginRight:'10px'}}
            >
              Authenticate Gmail
            </Button>
          }                      
          <br></br>
          { (authStatus && initiateStatus)
            && 
            <>
              <p>Fetched Gmail messages [<span style={{color:'black'}}>mailbox:</span> <span style={{color:'green'}}>migrated messages count</span>/<span style={{color:'blue'}}>fetched messages count</span>(<span style={{color:'red'}}>fetched threads count</span>)]</p>
              <p><span style={{color:'black'}}>INBOX:</span> <span style={{color:'green'}}>{finbox}</span>/<span style={{color:'blue'}}>{tinbox}</span>(<span style={{color:'red'}}>{uinbox}</span>), <span style={{color:'black'}}>Promotions:</span> <span style={{color:'green'}}>{fpromotions}</span>/<span style={{color:'blue'}}>{tpromotions}</span>(<span style={{color:'red'}}>{upromotions}</span>), <span style={{color:'black'}}>Social:</span> <span style={{color:'green'}}>{fsocial}</span>/<span style={{color:'blue'}}>{tsocial}</span>(<span style={{color:'red'}}>{usocial}</span>), <span style={{color:'black'}}>Updates:</span> <span style={{color:'green'}}>{fupdates}</span>/<span style={{color:'blue'}}>{tupdates}</span>(<span style={{color:'red'}}>{uupdates}</span>), <span style={{color:'black'}}>Sent:</span> <span style={{color:'green'}}>{fsent}</span>/<span style={{color:'blue'}}>{tsent}</span>(<span style={{color:'red'}}>{usent}</span>), <span style={{color:'black'}}>Junk:</span> <span style={{color:'green'}}>{fjunk}</span>/<span style={{color:'blue'}}>{tjunk}</span>(<span style={{color:'red'}}>{ujunk}</span>), <span style={{color:'black'}}>Trash:</span> <span style={{color:'green'}}>{ftrash}</span>/<span style={{color:'blue'}}>{ttrash}</span>(<span style={{color:'red'}}>{utrash}</span>) </p>
              <br></br>
              <span className="bg-slate-100">Migrate Status: {authStatus?fetchStatus:'Please authenticate your Gmail account!'} </span> 
              {
                fetching 
                && 
                <span className="bg-slate-100"> 
                  {
                    (count_fetch>0)
                    ?
                    <>
                      {count_fetch}{'(fetched: '}{count_imported_fetch}{'/skipped: '}{count_skipped_fetch}{')'}
                    </>
                    :
                    null
                  }
                </span>
              }
              <br></br>
              <br></br>
              <div>
                <Button 
                  onClick={()=>handleSync()} 
                  disabled={isSyncButtonDisabled}
                  style={{backgroundColor: 'green', borderRadius:'5px', color:'white', minWidth:'20px', marginRight:'10px'}}
                >
                  Start Importing to Unimail
                </Button> 
                <Button 
                  onClick={()=>handleAbort()} 
                  disabled={isAbortButtonDisabled}
                  style={{backgroundColor: 'red', borderRadius:'5px', color:'white', minWidth:'20px', marginRight:'10px'}}
                >
                  Stop Importing
                </Button>
              </div>              
            </>
          }      
          <br></br>
          <div> 
            <span className="bg-slate-100">Import Status: {authStatus?migrationStatus:'Please authenticate your Gmail account!'} </span>            
            {
              importing 
              && 
              <span className="bg-slate-100">
                {
                  (count>0)
                  ?
                  <>
                    {count}{'(imported: '}{count_imported}{'/skipped: '}{count_skipped}{')'}
                  </>
                  :
                  null
                }
              </span>
            }
          </div>
        </div>
        
      </div>     
    </Box>
  );
}

export default Migration;
