
import { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Link, Box, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingContext from '../../contexts/loadingContext';
import AuthContext from "../../contexts/authContext";
import { verifyPassword } from '../../apis';
import { getPublicIp, formatPhoneNumber } from '../../utils';
import CryptoJS from 'crypto-js';

const JWT_SECRET_KEY = 'unimailTokenJwt123';

const t_inputError = "Enter a password";
const t_invalidError = "Wrong password. Try again or click ‘Forgot password’ to reset it.";

export default function VerifyPassword({onBack, onNext, contact, goToHome, setVerifyData}) {
    const navigate = useNavigate();
    const loadingCtx = useContext(LoadingContext);
    const authCtx = useContext(AuthContext);
    const passwordRef = useRef(null);
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const handleForgotPassword = () => {
        navigate('/passwordrecovery', {state: {contact}});
    }

    const handleNext = () => {
        if (password === "") {
            setError(t_inputError);
            passwordRef.current.focus();
        }
        else {
            setError("");
            loadingCtx.setLoading(true);
            setTimeout(() => {
                getPublicIp()
                .then(async (publicIp) => {
                    // Encrypt the username and password
                    const encryptedContact = CryptoJS.AES.encrypt(JSON.stringify(contact), JWT_SECRET_KEY).toString();
                    const encryptedPassword = CryptoJS.AES.encrypt(password, JWT_SECRET_KEY).toString();

                    verifyPassword(encryptedContact, encryptedPassword, publicIp)
                    .then((res) => {
                        if (res.result) {
                            setError("");
                            console.log("token", res.token);
                            if (res.token === null) {
                                setVerifyData({
                                    ...res.data,
                                    password
                                });
                                onNext();
                            }
                            else
                                goToHome(res.token);
                        }
                        else {
                            setError(t_invalidError);
                            passwordRef.current.focus();
                        }
                        loadingCtx.setLoading(false);
                    })
                    .catch((e) => {})
                })
                .catch((e) => {})
            }, 1000);
        }
    }

    useEffect(() => {
        passwordRef.current.focus();
    }, []);
    
    return (
        <>
            <p className="text-2xl text-center pb-2">Welcome</p>
            <p className="text-base text-center pb-12">
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: "none",
                        borderRadius: "99px"
                    }}
                    startIcon={<AccountCircleIcon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={()=>{authCtx.setAddress(contact.address); onBack();}}>
                    {contact.address}
                    {formatPhoneNumber(contact.phone, true)}
                </Button>
            </p>
            <form className="pb-2">
                <TextInput
                    label="Enter your password"
                    autoComplete="off"
                    value={password}
                    size="default"
                    type={showPassword ? "default" : "password"}
                    onChange={(e) => {
                        setPassword(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        
                        if (e.key === 'Enter') {
                            handleNext();
                            e.preventDefault();
                        }
                    }}
                    error={error !== ""}
                    helperText={<HelperText error={error} />}
                    inputRef={passwordRef} />
            </form>
            <Box className="pb-16">
                <FormGroup>
                    <FormControlLabel control={<Checkbox value={showPassword} onChange={(e) => {setShowPassword(e.target.checked)}} />} label="Show password" />
                </FormGroup>
            </Box>
            <Box className="flex">
                <Link
                    component="button"
                    underline="none"
                    onClick={handleForgotPassword}>
                    Forgot password?
                </Link>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Next</Button>
            </Box>
        </>
    );
}