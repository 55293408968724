import React, { useCallback, useContext, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  Grid,
} from '@material-ui/core';
import { Tooltip } from "@mui/material";
import AttachmentIcon from '@material-ui/icons/Attachment';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import prettyBytes from 'pretty-bytes';
import * as WM_S from '../styled';
import * as S from './styled';
import { useDispatchForWM, useStateForWM } from '../ContextProvider';
import { UPDATE_FILES } from '../ContextProvider/reducer/action-type';
import UNAVAILABLE_EXTENSION from '../../../utils/unavailable-extension';
import { AppDispatchContext } from '../../../contexts/index';
import { getSnackbarState, SNACKBAR_VARIANT } from '../../Snackbar';
import { handleSnackbarState } from '../../../contexts/reducer';
import PopoverPopupState from './PopoverPopupState';
import DialogContext from '../../../contexts/dialogContext';

const MB = 1000 ** 2;
const FILE_MAX_SIZE = 10 * MB;
const FILE_MAX_COUNT = 5;
const PRETTY_FILE_MAX_SIZE = prettyBytes(FILE_MAX_SIZE);
const FILEUPLOAD_ERROR = {
  OVER_FILE_COUNT: 'Up to 5 files can be uploaded.',
  OVER_FILE_TOTAL_SIZE: 'Up to 10MB size of files can be uploaded. Please use UniDrive instead.',
  UNAVAILABLE_EXTENSION: 'It is not allowed file extension.',
};

const UNAVAILABLE_EXTENSION_STRING = Object.keys(UNAVAILABLE_EXTENSION).join(', ');

const addFileSize = (a, b) => a + b.size;
const add = (a, b) => a + b;

const checkOverSize = sizes => {
  const sum = sizes.reduce(add, 0);
  return sum <= FILE_MAX_SIZE;
};

const checkExtension = fileNames => {
  const errors = [];
  for (const fileName of fileNames) {
    const splitedFileName = fileName.split('.');
    let extension = splitedFileName[splitedFileName.length - 1];
    extension = extension.toLowerCase();
    if (UNAVAILABLE_EXTENSION[extension]) {
      errors.push(fileName);
    }
  }
  if (errors.length > 0) {
    throw ` ${errors.join('  ')}`;
  }

  return true;
};

const DropZone = ({handleInsertDrive}) => {
  const { dispatch: appDispatch } = useContext(AppDispatchContext);
  const { files } = useStateForWM();
  const dispatch = useDispatchForWM();
  const totalFileSize = files.reduce(addFileSize, 0);
  const diagCtx = useContext(DialogContext);

  useEffect(() => {
    dispatch({ type: UPDATE_FILES, payload: { files: diagCtx.initFileList } });
  }, [diagCtx.initFileList]);

  const onDrop = useCallback(
    newFiles => {
      console.log(files.length, newFiles.length);
      const totalFileCount = files.length + newFiles.length;
      if (FILE_MAX_COUNT < totalFileCount) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.ERROR, FILEUPLOAD_ERROR.OVER_FILE_COUNT),
          ),
        );
        return;
      }

      const fileNames = newFiles.map(file => file.name);

      try {
        checkExtension(fileNames);
      } catch (error) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(
              SNACKBAR_VARIANT.ERROR,
              FILEUPLOAD_ERROR.UNAVAILABLE_EXTENSION + error,
            ),
          ),
        );
        return;
      }

      const nextFiles = [...files, ...newFiles];
      const fileSizes = nextFiles.map(file => file.size);
      if (!checkOverSize(fileSizes)) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.ERROR, FILEUPLOAD_ERROR.OVER_FILE_TOTAL_SIZE),
          ),
        );
        handleInsertDrive(3);
        return;
      }

      dispatch({ type: UPDATE_FILES, payload: { files: nextFiles } });
    },
    [appDispatch, dispatch, files],
  );

  const { isDragActive, getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    multiple: true,
  });

  const delBtnHandler = number => {
    dispatch({
      type: UPDATE_FILES,
      payload: { files: files.filter((file, index) => index !== number) },
    });
  };

  return (
    <div style={{ alignSelf:'end' }} >
      {files.length > 0 ? (
        <WM_S.RowWrapper>
          <div></div>
          <div>
            <Grid item xs={12} md={12}>
              <div>
                <List className="text-xs">
                  {files.map((file, idx) => (
                    <ListItem key={idx} style={{ backgroundColor: '#e5e5e5', marginBottom: '2px' }}>
                      <ListItemText className="text-xs"
                      style={{ marginTop: '0px', marginBottom: '0px', paddingBottom: '0px' }} 
                      primary={<Typography style={{ fontSize: '12px', fontWeight: '600', color: '#1976d2' }}>
                      {file.name} <span style={{ color: '#5f6368' }}>({prettyBytes(file.size)})</span></Typography>} />
                      <ListItemSecondaryAction>
                        <IconButton  
                          size="small"
                          edge="end"
                          aria-label="delete"
                          onClick={() => delBtnHandler(idx)}>
                          <DeleteIcon style={{ fontSize: '14px' }} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
            </Grid>
          </div>
          <div></div>
        </WM_S.RowWrapper>
      ) : (
        <></>
      )}
      <Tooltip title="Attach files" placement="top">
        <IconButton { ...getRootProps() }>
          <input {...getInputProps()}/>
          <AttachFileIcon />    
        </IconButton>
      </Tooltip>
      {/* <WM_S.RowWrapper>
        <div></div>
        <div>          
          <S.UploadArea {...getRootProps()}>
            <input {...getInputProps()} />
            {isDragActive
              ? 'Drop files here!'
              : 'Click here or drag files to upload!'}
          </S.UploadArea>
          
          <S.FlexRowWrap>
            <S.FlexItem>
              <PopoverPopupState
                text={'View file extensions that cannot be uploaded'}
                hoverText={UNAVAILABLE_EXTENSION_STRING}
              />
            </S.FlexItem>

            <S.FlexItem>
              <S.FileUploadInfo>
                Files : {files.length} / {FILE_MAX_COUNT},  Size : {prettyBytes(totalFileSize)} / {PRETTY_FILE_MAX_SIZE}
              </S.FileUploadInfo>
            </S.FlexItem>
          </S.FlexRowWrap>
        </div>
        <div></div>
      </WM_S.RowWrapper> */}
      
    </div>
  );
};

export default DropZone;
