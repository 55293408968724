
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, HashRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Box } from '@mui/material';
import AuthContext from './contexts/authContext';
import { AppProvider } from './contexts';
import Home from './Pages/Home';
import SignIn from './Pages/SignIn';
import Signup from './Pages/Signup';
import MailRecovery from './Pages/MailRecovery';
import PasswordRecovery from './Pages/PasswordRecovery';
import PageNotFound from './Pages/PageNotFound';
import Migration from './Pages/Migration';
import AddDomainTest from './Pages/AddDomainTest';
import Helps from './Pages/Helps';
import Loading from './Pages/Loading';
import { useSelector } from "react-redux";
import { selectSendMessageIsOpen } from "./features/mailSlice";

import { SnackbarProvider } from 'notistack';
import LoadingProvider from './providers/loadingProvider';
import SearchProvider from "./providers/searchProvider";
import AuthProvider from './providers/authProvider';
import MenuProvider from './providers/menuProvider';
import DialogProvider from './providers/dialogProvider';

import createHost from './cross-domain-storage';

createHost([
  {
    origin: 'http://localhost:3000',
    allowedMethods: ['get', 'remove'],
  },
	{
    origin: 'http://drive.unimail.in:8080',
    allowedMethods: ['get', 'remove'],
  },
  {
    origin: 'http://docs.unimail.in:8080',
    allowedMethods: ['get', 'remove'],
  },
	{
    origin: 'http://drive.unimail.in',
    allowedMethods: ['get', 'remove'],
  },
  {
    origin: 'http://docs.unimail.in',
    allowedMethods: ['get', 'remove'],
  },
	{
    origin: 'http://drive.unimail.in:3000',
    allowedMethods: ['get', 'remove'],
  },
  {
    origin: 'http://docs.unimail.in:3000',
    allowedMethods: ['get', 'remove'],
  },
	{
    origin: 'https://drive.unimail.in:3000',
    allowedMethods: ['get', 'remove'],
  },
	{
    origin: 'https://drive.unimail.in',
    allowedMethods: ['get', 'remove'],
  },
	{
    origin: 'https://docs.unimail.in',
    allowedMethods: ['get', 'remove'],
  },
]);

export default function App() {
  
  return (
    <AppProvider>
      <AuthProvider>
        <LoadingProvider>
          <SnackbarProvider maxSnack={3}>
            {/* <DialogProvider> */}
              <BrowserRouter>
                <SearchProvider>
                  <MenuProvider>
                    <Box onContextMenu={(e) => e.preventDefault()}  className="min-h-screen flex flex-col relative">
                      {/* <LoadingProvider> */}
                      <DialogProvider>
                        <Routes>
                          <Route path="/" exact element={<Home path={"inbox"} />} />
                          <Route path="/inbox" exact element={<Home path={"inbox"} />} />
                          <Route path="/primary" exact element={<Home path={"inbox"} cat={0} />} />
                          <Route path="/promotions" exact element={<Home path={"inbox"} cat={1} />} />
                          <Route path="/social" exact element={<Home path={"inbox"} cat={2} />} />
                          <Route path="/updates" exact element={<Home path={"inbox"} cat={3} />} />
                          <Route path="/starred" exact element={<Home path={"starred"} />} />
                          <Route path="/important" exact element={<Home path={"important"} />} />
                          <Route path="/sent" exact element={<Home path={"sent"} />} />
                          <Route path="/scheduled" exact element={<Home path={"scheduled"} />} />
                          <Route path="/drafts" exact element={<Home path={"drafts"} />} />
                          <Route path="/spam" exact element={<Home path={"spam"} />} />
                          <Route path="/trash" exact element={<Home path={"trash"} />} />
                          <Route path="/search" exact element={<Home path={"search"} />} />
                          <Route path="/mail" exact element={<Home path={"mail"} />} />
                          <Route path="/signin" exact element={<SignIn />} />
                          <Route path="/signup" exact element={<Signup />} />
                          <Route path="/mailrecovery" exact element={<MailRecovery />} />
                          <Route path="/passwordrecovery" exact element={<PasswordRecovery />} />
                          <Route path="/terms" exact element={<Helps path={"terms"} />} />
                          <Route path="/privacy" exact element={<Helps path={"privacy"} />} />
                          <Route path="/help" exact element={<Helps path={"help"} />} />
                          <Route path="/migration" exact element={<Migration />} />

                          {/* <Route path="/add_domain" exact element={<AddDomainTest />} /> */}

                          <Route path="*" element={<PageNotFound />} />
                        </Routes>
                      </DialogProvider>
                      {/* </LoadingProvider> */}
                    </Box>
                  </MenuProvider>
                </SearchProvider>
              </BrowserRouter>
            {/* </DialogProvider> */}
          </SnackbarProvider>
        </LoadingProvider>
      </AuthProvider>
    </AppProvider>
    
  );
}