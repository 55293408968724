import React, { useEffect, useState, useContext, useRef } from "react";
import "./Header.css";
import MenuIcon from "@material-ui/icons/Menu";
import { Box, Button, Divider, IconButton, Link, Typography } from '@material-ui/core';
import { Avatar } from "..";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import CloseIcon from '@mui/icons-material/Close';
import TuneIcon from '@mui/icons-material/Tune';
// import AppsIcon from "@material-ui/icons/Apps";
import AppsIcon from '@mui/icons-material/Apps';
import NotificationsIcon from "@material-ui/icons/Notifications";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import AuthContext from '../../contexts/authContext';
import SearchContext from '../../contexts/searchContext';
import MenuContext from "../../contexts/menuContext";
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { getDomainLogo } from '../../apis';
import LoadingContext from '../../contexts/loadingContext';

function Header({path}) {
  
  const authCtx = useContext(AuthContext);
  const searchCtx = useContext(SearchContext);
  const menuCtx = useContext(MenuContext);
  const loadingCtx = useContext(LoadingContext);
  const navigate = useNavigate();
  const [logo, setLogo] = useState("");
  const [showClearBtn, setShowClearBtn] = useState(false)
  const elementRef = useRef();

  const domain = authCtx.getAddress().split('@')[1];
  const domain_name = domain.split('.')[0].charAt(0).toUpperCase() + domain.split('.')[0].slice(1);

  useEffect(() => {
    // console.log(domain)
    getDomainLogo(domain)
    .then((res) => { 
      if (res.result) {   
        //  console.log(res); 
        setLogo("/" + res.logo);
        // console.log('logo:', logo);
      } else {
        console.log(res.message);
      } 
    })
    .catch((e) => {});
  }, [domain])

  useEffect(() => {
    if (path === "starred" || path === "important" || path === "sent" || path === "spam" || path === "trash") {
      searchCtx.setInput(`in:${path} `);
    } else if (path === "inbox") {
      // if (loadingCtx.category === 1)
      //   searchCtx.setInput(`in:promotions `);
      // else if (loadingCtx.category === 2)
      //   searchCtx.setInput(`in:social `);
      // else if (loadingCtx.category === 3)
      //   searchCtx.setInput(`in:updates `);
      // else
        searchCtx.setInput(``);
    } else if (path === "drafts" || path === "scheduled") {   
      searchCtx.setInput(``);
    }
  }, [path, loadingCtx.category])

  useEffect(() => {
    // alert('d')
    // searchCtx.setInput(searchCtx.input.trim())
    // console.log('search', searchCtx.input);
    
    // if(searchCtx.input === "")
    //   return;

    // const search = {
    //   from: "",
    //   to: "",
    //   subject: "",
    //   hasWords: "",
    //   doesntHave: "",
    //   hasAttachment: false,
    //   in: "all",
    // };
    // const queryComponents = searchCtx.input.split(' ');

    // queryComponents.forEach((component, index) => {
    //   if (component.startsWith('from:')) search.from = (search.from + ' ' + component.slice(5)).trim();
    //   else if (component.startsWith('to:')) search.to = (search.to + ' ' + component.slice(3)).trim();
    //   else if (component.startsWith('subject:')) search.subject = (search.subject + ' ' + component.slice(8)).trim();
    //   else if (component.startsWith('-')) search.doesntHave = (search.doesntHave + ' ' + component.slice(1)).trim();
    //   else if (!component.includes(':')) search.hasWords = (search.hasWords + ' ' + component).trim();
    //   else if (component.startsWith('in:')) search.in = component.slice(3);
    //   else if (component === 'has:attachment') search.hasAttachment = true;
    // });

    // searchCtx.setSearch(search);
    // console.log('search', searchCtx.search);

  }, [searchCtx.input])

  const handleChange = (event) => {
    searchCtx.setInput(event.target.value);
    if (event.target.value !== "")
      setShowClearBtn(true);
    else 
      setShowClearBtn(false);
  };

  const handleClear = (event) => {
    searchCtx.setInput("");
    const search = {
      from: "",
      to: "",
      subject: "",
      hasWords: "",
      doesntHave: "",
      hasAttachment: false,
      in: "all",
    };
    searchCtx.setSearch(search);
    setShowClearBtn(false);
  };

  const handleSearch = () => {
    searchCtx.setInput(searchCtx.input.trim())
    console.log('header-searchinput', searchCtx.input);
    if(searchCtx.input.trim() === "")
      return;

    const search = {
      from: "",
      to: "",
      subject: "",
      hasWords: "",
      doesntHave: "",
      hasAttachment: false,
      in: "all",
    };

    let inputString = searchCtx.input;     
    // let inputString = 'aaa, bbb ,ccc ddd to:(eee,fff ggg) subject:(hhh,iiii) to:jjj from:kkk from:(lll mmm) nnn has:attachment in:trash in:spam xxx -{www yyy},-zzz';

    // Define a regular expression to match "from:" followed by either parentheses or a word
    const fromPattern = /from:\(([^)]+)\)|from:([^\s*,\s*]+)/g;
    const toPattern = /to:\(([^)]+)\)|to:([^\s*,\s*]+)/g;
    const subjectPattern = /subject:\(([^)]+)\)|subject:([^\s*,\s*]+)/g;
    const notPattern = /-\{([^}]+)\}|-([^\s*,\s*]+)/g;
    const inPattern = /in:([^\s*,\s*]+)/g;
    const attachPattern = /has:attachment/g;
    const hasPattern = /([^\s*,\s*]+)/g;

    // Extract data using the regular expression and populate the array
    let match;
    let a = [];
    let b = [];
    // Extract data for "from" property
    while ((match = fromPattern.exec(inputString)) !== null) {
      // console.log(match)
      if (match[1]) {
        const insideValues = match[1].split(/\s*,\s*/);
        search.from += insideValues.join(' ') + ' ';
        a.push(match[0]);
      } else {
        const outsideValue = match[2].split(/\s*,\s*/);
        search.from += outsideValue.join(' ') + ' ';    
        b.push(match[0]);
      }    
      // inputString = inputString.replace(match[2], '');
    }
    // inputString = inputString.replace(a, '');
    // inputString = inputString.replace(b, '');

    // Extract data for "to" property
    while ((match = toPattern.exec(inputString)) !== null) {
      if (match[1]) {
        const insideValues = match[1].split(/\s*,\s*/);
        search.to += insideValues.join(' ') + ' ';
        a.push(match[0]);
      } else {
        const outsideValue = match[2].split(/\s*,\s*/);
        search.to += outsideValue.join(' ') + ' ';
        b.push(match[0]);
      }
    }
    // inputString = inputString.replace(a, '');
    // inputString = inputString.replace(b, '');

    // Extract data for "subject" property
    while ((match = subjectPattern.exec(inputString)) !== null) {
      console.log(match)
      if (match[1]) {
        const insideValues = match[1].split(/\s*,\s*/);
        search.subject += insideValues.join(' ') + ' ';
        a.push(match[0]);
      } else {
        const outsideValue = match[2].split(/\s*,\s*/);
        search.subject += outsideValue.join(' ') + ' ';
        b.push(match[0]);
      }
    }
    // inputString = inputString.replace(a, '');
    // inputString = inputString.replace(b, '');

    // Extract data for "not" property
    while ((match = notPattern.exec(inputString)) !== null) {
      if (match[1]) {
        const insideValues = match[1].split(/\s*,\s*/);
        search.doesntHave += insideValues.join(' ') + ' ';
        a.push(match[0]);
      } else {
        const outsideValue = match[2].split(/\s*,\s*/);
        search.doesntHave += outsideValue.join(' ') + ' ';
        b.push(match[0]);
      }
    }
    // inputString = inputString.replace(a, '');
    // inputString = inputString.replace(b, '');

    // Extract data for "attach" property
    while ((match = attachPattern.exec(inputString)) !== null) {
      search.hasAttachment = true;
      a.push(match[0]);
    }
    // inputString = inputString.replace(a, '');

    // Extract data for "in" property
    // let ina = [];
    while ((match = inPattern.exec(inputString)) !== null) {
      // console.log(match)
      if (match[1]) {
        const insideValues = match[1].split(/\s*,\s*/);
        search.in = insideValues[insideValues.length-1];
        a.push(match[0]);
      }
    }
    // console.log(ina);
    a.map(word => {
      inputString = inputString.replace(word, '');
    })
    b.map(word => {
      inputString = inputString.replace(word, '');
    })

    // Extract data for "has" property
    while ((match = hasPattern.exec(inputString)) !== null) {
      if (match[1]) {
        const insideValues = match[1].split(/\s*,\s*/);
        search.hasWords += insideValues.join(' ') + ' ';
      }
    }

    console.log('search', search);

    // const queryComponents = searchCtx.input.split(' ');

    // queryComponents.forEach((component, index) => {
    //   if (component.startsWith('from:')) search.from = (search.from + ' ' + component.slice(5)).trim();
    //   else if (component.startsWith('to:')) search.to = (search.to + ' ' + component.slice(3)).trim();
    //   else if (component.startsWith('subject:')) search.subject = (search.subject + ' ' + component.slice(8)).trim();
    //   else if (component.startsWith('-')) search.doesntHave = (search.doesntHave + ' ' + component.slice(1)).trim();
    //   else if (!component.includes(':')) search.hasWords = (search.hasWords + ' ' + component).trim();
    //   else if (component.startsWith('in:')) search.in = component.slice(3);
    //   else if (component === 'has:attachment') search.hasAttachment = true;
    // });

    searchCtx.setSearch(search);
    
    loadingCtx.setCurpage(1);
    navigate("/search");
  };
  
  const signOut = () => {
    authCtx.logout();
  };

  return (
    <div className="header desktop">
        <div className="header-left">
          {/* <IconButton>
            <MenuIcon />
          </IconButton> */}
          <Tooltip title="Unimail" placement="right-end">
            <Link href="/" underline="none" className='flex items-center'>
              <img className="p-4 pr-2 pl-4 h-[64px]" src="/unimail-logo.svg" alt="Unimail" /> 
              <Typography variant="h5" className="text-gray-800 !font-bold">Unimail</Typography>
            </Link>
          </Tooltip>
          
        </div>
        <div className="header-main">
          <div className="header-middle" id="myElement" ref={elementRef}>
            <IconButton onClick={() => handleSearch()}>
              <SearchIcon />
            </IconButton>
            <input type="text" placeholder="Search in emails" id="header-search" 
              onChange={handleChange} 
              onKeyDown={(e) => {                        
                  if (e.key === 'Enter') { // || e.key === ' '
                    handleSearch();
                    //  e.preventDefault();
                  }
              }} 
              value={searchCtx.input} />
            { showClearBtn &&
              <IconButton onClick={() => handleClear()}>
                <CloseIcon />
              </IconButton>
            }
            {
              <IconButton 
                onClick={(e) => {
                  if (elementRef.current) {
                    const width = elementRef.current.offsetWidth;
                    const { top, left, right, bottom } = elementRef.current.getBoundingClientRect();
                    menuCtx.showFilter(e, width, left, path)
                  }
                }}
              >
                <TuneIcon />
              </IconButton>
            }            
          </div>
          <div className="header-right">
            <Tooltip title="Support" placement="bottom">
              <IconButton> {/*  onClick={(e) => menuCtx.showHelp(e)} */}
                <Link href="/help" color='inherit' underline="none" style={{ display: 'flex' }} target="_blank"><HelpOutlineIcon /></Link>
              </IconButton>
            </Tooltip>
            {/* <IconButton>
              <NotificationsIcon />
            </IconButton> */}
            <Tooltip title="Unimail apps" placement="bottom">
              <IconButton onClick={(e) => menuCtx.showApps(e)}>
                <AppsIcon />
              </IconButton>
            </Tooltip>  
            {
              logo === '/' 
              ? 
              <Tooltip title="Unimail Account" placement="bottom-start">
                <IconButton size="small" onClick={(e) => menuCtx.showProfile(e)}>
                  <Avatar size={32} isMe={true}/>
                </IconButton>
              </Tooltip> 
              :
              <div className="flex items-center justify-center border rounded-lg border-slate-200 border-solid pr-1">         
                <img className="py-2 pr-2 pl-2 h-[48px]" src={`/domain/${logo}`} alt={domain_name} /> 
                <Tooltip title="Unimail Account" placement="bottom-start">
                  <IconButton size="small" onClick={(e) => menuCtx.showProfile(e)}>
                    <Avatar size={32} isMe={true}/>
                  </IconButton>
                </Tooltip>
              </div> 
            }            
          </div>
        </div>
    </div>
  );
}

export default Header;
