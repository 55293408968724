export const initialState = {
  receivers: [],
  ccs: [],
  bccs: [],
  froms: [],
  tos: [],
  subject: '',
  html: '',
  text: '',
  files: [],
  date: null,
};
