
import { TextField } from '@mui/material';

export default function TextInput(props) {
  return <TextField
    fullWidth
    size="small"
    sx={{
        "& .Mui-error": { marginLeft: 0, marginRight: 0 }
    }}
    { ... props }
    variant="outlined" />
}