                                
import { Button } from '@mui/material';
import { useContext } from "react";
import ThemeContext from '../contexts/themeContext';

export default function OutlinedButton(props) {
    const themeCtx = useContext(ThemeContext);
    const borderColor = themeCtx.isDark() ? "dimGrey" : "lightGrey";
    const color = themeCtx.isDark() ? "white" : "dimGrey";

    if (props.sx === undefined)
        props = {
            ...props,
            sx: {}
        }
    if (props.grey) {
        props.sx.color = color;
    }
    
    return <Button
        variant="outlined"
        { ...props }
        sx={{
            textTransform: 'none',
            borderColor: borderColor,
            "&:hover": {
                borderColor: borderColor,
            },
            ...props.sx
        }}>
        { props.children }
    </Button>
}