import { Box, Checkbox, IconButton, Divider, Button } from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./EmailList.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import RefreshIcon from '@mui/icons-material/Refresh';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import SearchIcon from "@material-ui/icons/Search";
////////// for test of spam /////////
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
/////////////////////////////
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import InboxIcon from "@material-ui/icons/Inbox";
import PeopleIcon from "@material-ui/icons/People";
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Section from "../Section/Section";
import EmailRowM from "../EmailRow/EmailRowM";
import LoadingContext from '../../contexts/loadingContext';
import { setStatusMail } from '../../apis';
import AuthContext from '../../contexts/authContext';
import SearchContext from "../../contexts/searchContext";

function EmailListM({ _emails, path, getMainMails, total, perpage }) {
  const navigate = useNavigate(); 
  const loadingCtx = useContext(LoadingContext);
  const searchCtx = useContext(SearchContext);
  const authCtx = useContext(AuthContext);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedSome, setSelectedSome] = useState(false);
  const [emails, setEmails] = useState(_emails);

  useEffect(() => {
    setEmails(_emails);
  }, [_emails])

  useEffect(() => {
   
    let isSelectedAll = true;
    let isSelectedSome = false;
    if (emails.length > 0) {
      for(let item of emails) {
        if (!item.selected)
          isSelectedAll = false;
        if (item.selected)
          isSelectedSome = true;
      } 
    } else {
      isSelectedAll = false;
      isSelectedSome = false;
    }       
    setSelectedAll(isSelectedAll);
    setSelectedSome(isSelectedSome);
    
  }, [emails])
  
  const handleChange = (event) => {
    searchCtx.setInput(event.target.value);
  };

  const handleSearch = () => {
    
    if(searchCtx.input === "")
      return;
    searchCtx.setSearch(searchCtx.input);
    navigate("/search");
  };
  
  const onChangeSelectedRow = (id, checked) => {
    setEmails(emails.map((item) => {
      if(item.message_id === id)
        item.selected = checked;
      return item;
    }));   
    
  };

  const onSelectedAllChange = (event) => {    
    setEmails(emails.map((item) => {
      item.selected = event.target.checked;
      return item;
    }));
    
  };

  const onSetStatusMail = (onlyunreadcount=0) => {
    
    getMainMails(path, onlyunreadcount);
  }

  const onSetStatusMails = (field, value) => {
    
    emails.map((item) => {      
      if(item.selected) {            
        setStatusMail(authCtx.token, item.message_id, field, value)
        .then(function(res) { 
          if (res.result) {
            console.log("Document successfully updated!");    
            getMainMails(path);        
          }
        })
        .catch(function(error) {
          console.error("Error updating document: ", error);
          getMainMails(path);
        });      
      }
    });
  };

  const onRefreshAll = () => {
    
    getMainMails(path);
  };

  const onPrev = () => {
    
    loadingCtx.setCurpage(loadingCtx.curpage - 1);
  };

  const onNext = () => {
    
    loadingCtx.setCurpage(loadingCtx.curpage + 1);
  };

  const renderSelectedActions = () => {
    let render = <></>
    if (path === "inbox") {
      render = 
        <>
          <IconButton onClick={() => onSetStatusMails('is_trashed', 1)} >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => onSetStatusMails('is_spam', 1)} >
            <ReportGmailerrorredOutlinedIcon />
          </IconButton>
        </>
    }
    else if (path === "sent") {
      render = 
        <>
          <IconButton onClick={() => onSetStatusMails('is_trashed', 1)} >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => onSetStatusMails('is_spam', 1)} >
            <ReportGmailerrorredOutlinedIcon />
          </IconButton>
        </>
    }
    else if (path === "spam") {
      render = 
        <>
          <IconButton onClick={() => onSetStatusMails('is_deleted', 1)} >
            <DeleteForeverOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => onSetStatusMails('is_spam', 0)} >
            <ReportOffOutlinedIcon />
          </IconButton>
        </>
    }
    else if (path === "trash") {
      render = 
        <>
          <IconButton onClick={() => onSetStatusMails('is_deleted', 1)} >
            <DeleteForeverOutlinedIcon />
          </IconButton>
          <IconButton onClick={() => onSetStatusMails('is_spam', 1)} >
            <ReportGmailerrorredOutlinedIcon />
          </IconButton>
        </>
    }
    return <>          
      { render }
      <IconButton onClick={() => onSetStatusMails('is_read', 0)} >
        <MarkunreadOutlinedIcon />
      </IconButton>
      <IconButton onClick={() => onSetStatusMails('is_read', 1)} >
        <DraftsOutlinedIcon />
      </IconButton>
    </>  
  }

  return (
    <Box className="emailList-mobile w-full grid">
      <Box className="emailList-search-mobile">
        <IconButton onClick={() => handleSearch()}>
          <SearchIcon />
        </IconButton>
        <input type="text" placeholder="Search in emails" id="header-search" 
          onChange={handleChange} 
          onKeyDown={(e) => {                        
              if (e.key === 'Enter') {
                handleSearch();
                //  e.preventDefault();
              }
          }} 
          value={searchCtx.input} />
        {/* <ArrowDropDownIcon className="header-inputCaret" /> */}
      </Box>
      <Divider />
      <Box className="emailList-settings-mobile">
        <div className="emailList-settingsLeft">
          <Checkbox checked={selectedAll} onChange={onSelectedAllChange} style={{ padding: '3px' }} />           
          { selectedSome &&  renderSelectedActions() }          
          <IconButton onClick={onRefreshAll}>
            <RefreshIcon />
          </IconButton>
        </div>
        <div className="emailList-settingsRight">
          <div className="flex">
            <p className="mr-[10px] mt-[10px] text-sm" style={{minHeight: '20px'}}>{(emails.length>0)?(((loadingCtx.curpage-1)*perpage+1).toString()+'-'+((loadingCtx.curpage*perpage>total)?total:loadingCtx.curpage*perpage).toString()+' of '+total):''}</p>  
            <IconButton onClick={() => onPrev()} disabled={(loadingCtx.curpage > 1)?false:true}><ChevronLeftIcon /></IconButton>
            <IconButton onClick={() => onNext()} disabled={(total > loadingCtx.curpage * perpage)?false:true}><ChevronRightIcon /></IconButton>  
          </div>                
        </div>        
      </Box>
      <Box className="flex overflow-y-hidden">
        <Box className="emailList-list w-full overflow-y-auto pb-4">
        {(emails.length > 0)?emails.map((item) => (
          <EmailRowM
            key={item._id}
            item={item}
            path={path}
            onSetStatusMail={onSetStatusMail}
            onChangeSelectedRow={onChangeSelectedRow}            
          />
        )):(<div className="flex justify-center"><p>No messages</p></div>)}
        </Box>
      </Box>
      
    </Box>
  );
}

export default EmailListM;
