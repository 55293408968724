
import { useEffect, useState, useRef, useContext } from "react";
import { Button, FormHelperText, InputAdornment,  Link } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingContext from '../../contexts/loadingContext';

import { verifySmsSignIn, sendSms } from '../../apis';
import { hidePhoneInfo, getPublicIp } from '../../utils';

const t_inputError = "Enter a verification code";
const t_invalidError = "Enter valid verification code";
const t_failedError = "Failed to send verify code";

export default function VerifyPhone({onStart, onNext, onBack, contact, goToHome, verifyData}) {
    const loadingCtx = useContext(LoadingContext);

    const verifyRef = useRef(null);

    const [error, setError] = useState("");

    const [verifyCode, setVerifyCode] = useState("");
    const [encryptCode, setEncryptCode] = useState("");

    useEffect(() => {
        setEncryptCode(verifyData.encryptCode);
    }, [verifyData]);

    const handleNext = () => {
        if (verifyCode === "") {
            setError(t_inputError);
            verifyRef.current.focus();
        }
        else {
            loadingCtx.setLoading(true);
            setTimeout(() => {
                getPublicIp()
                .then((publicIp) => {
                    verifySmsSignIn(verifyCode, encryptCode, contact, publicIp)
                    .then((res) => {
                        if (res.result) {
                            setError("");
                            goToHome(res.token);
                        }
                        else {
                            setError(t_invalidError);
                            verifyRef.current.focus();
                        }
                        loadingCtx.setLoading(false);
                    })
                    .catch((e) => {})
                })
                .catch((e) => {})
            }, 1000);
        }
    }

    const handleRequest = () => {
        loadingCtx.setLoading(true);
        setTimeout(() => {
            sendSms("", verifyData.verifyPhone, encryptCode)
                .then((res) => {
                    setEncryptCode(res.message);
                    setError("");
                    verifyRef.current.focus();
    
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {
                    setError(t_failedError);
                    verifyRef.current.focus();
    
                    loadingCtx.setLoading(false);
                })
        }, 1000)
    }
    
    return (
        <>
            <p className="text-2xl text-center pb-2">Verify that it's you</p>
            <p className="text-base text-center pb-2">To help keep your account safe, Uni Mail wants to make sure that it's really you trying to sign in</p>
            <div className="pb-4 text-center">
                {/* <Link href="#" underline="none">Learn more</Link> */}
            </div>
            <p className="text-base text-center pb-4">
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: "none",
                        borderRadius: "99px"
                    }}
                    startIcon={<AccountCircleIcon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={onStart}>
                    {contact.address}
                    {contact.phone}
                </Button>
            </p>
            <p className="text-sm pb-8">A text message with a 6-digit verification code was just sent to {hidePhoneInfo(verifyData.verifyPhone)}</p>
            <div className="pb-16">
                <TextInput
                    label="Enter the code"
                    value={verifyCode}
                    error={error !== ""}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">U-</InputAdornment>,
                    }}
                    inputRef={verifyRef}
                    size="default"
                    onChange={(e) => {
                        const re = /^[0-9]{0,6}$/;
                        // console.log(e.target.value);
                        // if value is not blank, then test the regex
                        if (e.target.value === '' || re.test(e.target.value)) {
                            setVerifyCode(e.target.value)
                        }
                    }}
                    onKeyDown={(e) => {
                        
                        if (e.key === 'Enter') {
                            handleNext();
                            e.preventDefault();
                        }
                    }} />
                {
                    error === "" ? "" :
                    <FormHelperText error>
                        <HelperText error={error} />
                        {
                            error === t_inputError ? "" :
                            <Link
                                component="button"
                                underline="none"
                                onClick={handleRequest}>
                                <span className="pl-2">Request a new code</span>
                            </Link>
                        }
                    </FormHelperText>
                }
            </div>
            <div className="flex">
                <Button variant="text" sx={{textTransform: "none"}} onClick={onBack}>Back</Button>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>
                    Next
                </Button>
            </div>
        </>
    );
}