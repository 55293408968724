import {React, Component, useContext, useState, useRef, useCallback, useEffect} from "react";
import "./Mail.css";
import { Avatar } from "..";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { url } from '../../@axios';
import AuthContext from '../../contexts/authContext';
import DialogContext from "../../contexts/dialogContext";
import LoadingContext from '../../contexts/loadingContext';
import parse from 'html-react-parser';
import { FileIcon, defaultStyles } from 'react-file-icon';
import fileDownload from 'js-file-download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GradeIcon from '@mui/icons-material/Grade';
import ListOfReceivers from "../SendMail/InputReceiver/multiselect";
import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LabelImportantTwoToneIcon from '@mui/icons-material/LabelImportantTwoTone';
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useStateForWM, useDispatchForWM } from '../SendMail/ContextProvider';
import SwitchDropzone from '../SendMail/SwitchDropzone';
import DropZone from '../SendMail/DropZone';
import autosize from 'autosize';
import * as WM_S from '../SendMail/styled';
import validator from '../../utils/validator';
import { sendMail, downLoad, getDraftMail, deleteDraftMail } from '../../apis';
import { AppDispatchContext, AppStateContext } from '../../contexts';
import { handleSnackbarState } from '../../contexts/reducer';
import { getSnackbarState, SNACKBAR_VARIANT } from '../Snackbar';
import { isUndefined } from "lodash";
import MenuContext from "../../contexts/menuContext";
import moment from "moment";
import { getEmailDateTimeFormat, highlightText, highlightText_text } from '../../utils';
import { UPDATE_FILES } from '../SendMail/ContextProvider/reducer/action-type';
// const Viewer = dynamic(import('./Viewer'), { ssr: false });

import { Button, IconButton, Tooltip, Box } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import DeleteIcon from '@material-ui/icons/Delete';
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import FileItem from "../../providers/Modals/FileViewer/preview/FileItem";
import ResponseType from "./ResponseType";

import dayjs from 'dayjs';
import { Popover } from 'react-tiny-popover'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

import DOMPurify from 'isomorphic-dompurify';
import { Letter } from "react-letter";

import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";
import '../QuillEditor/style.css';
import QuillEditor from "../QuillEditor"

import { CKEditor } from 'ckeditor4-react';

const SEND_INFO = {
  MESSAGE_SENT: 'Message sent.',
  MESSAGE_SENDING: 'Message sending...',
  MESSAGE_SENT_FAILED: 'Message sending failed.',
  NO_RECIPIENT: 'Please specify at least one recipient.',
  INVALID_RECIPIENTS: 'Please specify valid recipients.',
  EMPTY_MESSAGE: 'Please input message.',
  INVALID_PICKTIME_NULL: 'Please pick time.',
  INVALID_PICKTIME_PASS: 'Picked passed time. Please pick other time.'
};

var scheduleDate = [null, null, null];

function runAfterFramePaint(callback) {
  requestAnimationFrame(() => {
      const messageChannel = new MessageChannel();

      messageChannel.port1.onmessage = callback;
      messageChannel.port2.postMessage(undefined);
  });
}

function useMarkFramePaint({ markName, enabled }) {
  const loadingCtx = useContext(LoadingContext);

  useEffect(() => {
      /**
       * Only perform the log when the calling component has signaled it is
       * ready to log a meaningful visual update.
       */
      if (!enabled) {
          return;
      }

      // Queues a requestAnimationFrame and onmessage
      runAfterFramePaint(() => {
        loadingCtx.setLoading(false);
          // Set a performance mark shortly after the frame has been produced.
      });
  }, [markName, enabled])
}

function MailItem({item, getDetailMails, query, _subject, last, path, moveScrollBottom}) {
  
  const authCtx = useContext(AuthContext);
  const loadingCtx = useContext(LoadingContext);
  const menuCtx = useContext(MenuContext);
  const dialogCtx = useContext(DialogContext);
  const dispatchDropZone = useDispatchForWM();
  const [reply, setReply] = useState(false);
  const [replyall, setReplyall] = useState(false);
  const [replyallable, setReplyallable] = useState(false);
  const [forward, setForward] = useState(false);
  const [detailed, setDetailed] = useState(last);
  const [content, setContent] = useState('');

  const [editorValue, setEditorValue] = useState('');
  const [subject, setSubject] = useState('');
  const [objectid, setObjectid] = useState(null);
  const [timer, setTimer] = useState(5);
  
  const [to, setTo] = useState([]);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const [showCcs, setShowCcs] = useState(false);
  const [showBccs, setShowBccs] = useState(false);
  const [replyDot3, setReplyDot3] = useState(false);

  const myRef = useRef(null);

  const dispatchs = useDispatch();

  const extractContent = (s) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const { dispatch: appDispatch } = useContext(AppDispatchContext);
  const { receivers, ccs, bccs, files } = useStateForWM();
  
  const from_name = (item.from_name === "" || item.from_name === null || item.from_name === undefined) ?
    item.from.split('@')[0] : item.from_name;

  const receiverName = (item.from === authCtx.getAddress()) ? (item.title) : from_name;

  useEffect(() => {
    if(forward === true){
      //window.scrollTo(0, myRef.current.offsetTop);
//      myRef.current.scrollIntoView();
      moveScrollBottom(myRef.current.offsetTop);
    }
  }, [forward])

  useEffect(() => {
    if(reply === true){
      //window.scrollTo(0, myRef.current.offsetTop);
//      myRef.current.scrollIntoView();
      moveScrollBottom(myRef.current.offsetTop);
    }
  }, [reply])

  useEffect(() => {
    if(replyall === true){
      //window.scrollTo(0, myRef.current.offsetTop);
//      myRef.current.scrollIntoView();
      moveScrollBottom(myRef.current.offsetTop);
    }
  }, [replyall])

  useEffect(() => {
    // console.log('cc', item.cc, item.to);
    
    if (
      (
        item.from !== authCtx.getAddress() 
        && 
        (
          (item.to.startsWith('[') && item.to.includes(authCtx.getAddress()))
          || 
          (item.to !== '' && item.cc !== '')
        )
      ) 
      || 
      (
        item.from === authCtx.getAddress() 
        && 
        (item.cc !== '' || item.bcc != '')
      )
    )
      setReplyallable(true);
  }, [])

  useEffect(() => {
    if(content !== ''){
      loadingCtx.setLoading(false);
    }
  }, [content])

  useEffect(() => {
    if (detailed) {
      loadingCtx.setLoading(true);
      let _content = "";
      if(item !== null) {
        // console.log('pppppppppppppp', item.html, highlightText(item.html, query));
        _content = (item.html === "" || item.html === "false" || item.html === undefined || item.html === null )?highlightText(item.message, query?query:""):DOMPurify.sanitize(item.html).replace('o;?', '');
      }
      // console.log('detailed', _content);
      setContent(_content);

      if(_content === ''){
        loadingCtx.setLoading(false);
      }
    } else {
      // console.log('no detailed');
      setContent('');
    }

  }, [detailed]);

  useEffect(() => {
    if (cc.length > 0)
      setShowCcs(true);
    else 
      setShowCcs(false);
  }, [cc])
 
  function runAfterFramePaint(callback) {
    requestAnimationFrame(() => {
        const messageChannel = new MessageChannel();
  
        messageChannel.port1.onmessage = callback;
        messageChannel.port2.postMessage(undefined);
    });
  }
  
  useMarkFramePaint({
    markName: 'ItemListRendered',
    enabled: last
  });

  let content_brief = item.message.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 120);
  let content_forward = "";
  if(item !== null) {
    content_forward = (item.html === "" || item.html === "false" || item.html === undefined || item.html === null )?item.message:DOMPurify.sanitize(item.html).replace('o;?', '');
  }
  
  const attachments = JSON.parse(isUndefined(item.attach)?"[]":item.attach);    
  if (Array.isArray(attachments) && attachments.length > 0){
    let attach_counts = 0;
    attachments.map(element => {  
      if (element.hasOwnProperty('filename')) {
        attach_counts++;
      }        
    });
  }
  // console.log(content_forward);

  let temp_subject = _subject;
  if (_subject.startsWith('Re: '))
    temp_subject = _subject.slice(4);
  if (_subject.startsWith('Fwd: '))
    temp_subject = _subject.slice(5);

  const handleInitMail = () => {
    setShowCcs(false);
    setShowBccs(false);
    // setSubject('');
    setEditorValue('');
    
    dispatchDropZone({
      type: UPDATE_FILES,
      payload: { files: [] },
    });
  }

  const handleReplyClick = () => {
    dialogCtx.setInitFileList([]);
    setReply(true);
    setReplyall(false);
    setForward(false);
    setReplyDot3(true);
    handleInitMail();

    setSubject('Re: ' + temp_subject);

    let tos = [];
    if (item.from === authCtx.getAddress()) {
      if (item.to.startsWith('[')) {
        tos = JSON.parse(item.to);
      } else {
        tos.push(item.to);
      }
    } else {
      tos.push(item.from);
    }    
    setTo(tos);
  };

  const handleReplyAllClick = () => {
    dialogCtx.setInitFileList([]);
    setReply(false);
    setReplyall(true);
    setForward(false);
    setReplyDot3(true);
    handleInitMail();

    setSubject('Re: ' + temp_subject);

    let tos = [];
    let ccs = [];
    if (item.from === authCtx.getAddress()) {
      if (item.to.startsWith('[')) {
        tos = JSON.parse(item.to);
      } else {
        tos.push(item.to);
      }
    } else {
      tos.push(item.from);
      if (item.to !== authCtx.getAddress()) {
        if (item.to.startsWith('[')) {
          let temp = JSON.parse(item.to);
          temp.forEach(element => {
            if (element !== authCtx.getAddress()) {
              ccs.push(element);
            }
          });
        } else if (item.to !== '') {
          ccs.push(item.to);
        }
      } 
    }   
    console.log('ccs after to', ccs);
    if (item.cc !== authCtx.getAddress()) {
      console.log('item cc', item.cc);
      if (item.cc.startsWith('[')) {
        let temp = JSON.parse(item.cc);
        temp.forEach(element => {
          if (element !== authCtx.getAddress()) {
            ccs.push(element);
          }
        });
      } else if (item.cc !== '') {
        ccs.push(item.cc);
      }
    } 
    console.log('ccs after cc', ccs);
    setTo(tos);
    setCc(ccs);
    
  };

  const handleReplyDot3 = () => {
    setReplyDot3(false);

    const strMessage = getEmailDateTimeFormat(item.date, 2);
    const strCreatedDate = strMessage.split(' (')[0];

    let editCommit = editorValue + "<br><br>" + strCreatedDate + ", " + from_name + " &#60;" + item.from + "&#62;";
    editCommit += "<blockquote>";
    editCommit += item.html;
    editCommit += "</blockquite>";
    setEditorValue(editCommit);
    
  }

  const getUrl = (token, oid, fileid) => {
    let query = {
        token: token,
        oid: oid,
        fileid: fileid
    };
    query = JSON.stringify(query);
    return `${url}/action_download/?param=${encodeURIComponent(query)}`;
  }

  const handleForwardClick = async () => {
    dialogCtx.setInitFileList([]);
    const attachments = JSON.parse(isUndefined(item.attach)?"[]":item.attach);    
    loadingCtx.setLoading(true);
    let attachFiles = [];
    for(let i = 0; i < attachments.length; i++){
      const res = await fetch(getUrl(authCtx.token, item._id, i));
      const buffer = await res.arrayBuffer();
      const file = new File([buffer], attachments[i].filename, {
          type: "text/plain",
      });
      attachFiles = [...attachFiles, file];
    }
    dialogCtx.setInitFileList(attachFiles);
    // console.log(attachFiles);
    loadingCtx.setLoading(false);
    
    setForward(true);
    setReplyall(false);
    setReply(false);
    handleInitMail();
    setReplyDot3(false);

    setSubject('Fwd: ' + temp_subject);

    setTo([]);
    setCc([]);
    setBcc([]);

    const strMessage = getEmailDateTimeFormat(item.date, 2);
    const strCreatedDate = strMessage.split(' (')[0];

    // console.log(content_forward);
    let editCommit = "<br><br>---------- Forwarded message ---------<br>";
    editCommit += "From: &#60;";
    editCommit += item.from;
    editCommit += "&#62; <br>Date: ";
    editCommit += strCreatedDate;
    editCommit += "<br>Subject: ";
    editCommit += temp_subject;
    editCommit += "<br>To: ";
    editCommit += item.title;
    editCommit += "<br><br>";
    editCommit += item.html;
    editCommit += "<br>";
    setEditorValue(editCommit);
  };
  
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  
  const [dropZoneVisible, setDropZoneVisible] = useState(false);

  // const subject = ((item.subject.substr(0, 4) === 'Re: ') || (item.in_reply_to !== ''))?(item.subject):('Re: ' + item.subject);

  const checkSendInfo = () => {
    if (receivers.length === 0) {
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.NO_RECIPIENT),
        ),
      );
      return false;
    }
    if (!receivers.every(receiver => validator.validate('email', receiver))) {     
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_RECIPIENTS),
        ),
      );
      return false;
    }
    if (!ccs.every(receiver => validator.validate('email', receiver))) {   
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_RECIPIENTS),
        ),
      );  
      return false;
    }
    if (!bccs.every(receiver => validator.validate('email', receiver))) {     
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_RECIPIENTS),
        ),
      );
      return false;
    }
    // if (!editorValue.length) {
    //   appDispatch(
    //     handleSnackbarState(
    //       getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.EMPTY_MESSAGE),
    //     ),
    //   );
    //   return false;
    // }

    return true;
  }

  const onSubmit = () => {
    if (checkSendInfo() === false)
      return;
    appDispatch(
      handleSnackbarState(
        getSnackbarState(SNACKBAR_VARIANT.INFO, SEND_INFO.MESSAGE_SENDING),
      ),
    );
    
    if (item.reference === '') {
      item.reference = item.message_id; // when it is first reply
    }
    // console.log(item.message_id, item.reference);

    sendMail(authCtx.token, receivers, ccs, bccs, '0', '', subject, extractContent(editorValue), editorValue, files, '', item.message_id, item.reference, path)
    .then((res) => {
        setReply(false);
        setReplyall(false);
        setForward(false);
        if(res.result) {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.SUCCESS, SEND_INFO.MESSAGE_SENT),
            ),
          );
          getDetailMails();            
        } else {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.MESSAGE_SENT_FAILED),
            ),
          );
        }                        
    })
    .catch((e) => {});
    dialogCtx.setInitFileList([]);
  };

  const handleInsertDrive = (viewMode = 0) => {
    const cb = (link, name) => {
      //setEditorValue(editorValue + '\n' + link + '\n');
      setEditorValue(`${editorValue}<a href="${link}">${name}</a><br/>`);
    }
    dialogCtx.showInsertDrive(cb, viewMode);
  }

  const renderAttachments = () => {
    const attachments = JSON.parse(isUndefined(item.attach)?"[]":item.attach);    
    if (Array.isArray(attachments) && attachments.length > 0){
      let attach_counts = 0;
      attachments.map(element => {  
        if (element.hasOwnProperty('filename'))
          attach_counts++;
      });

      return (
        <div className="mail-attach-body">
          {
            (attach_counts > 0)
            ?
            <div>
              {attachments.length} attachment(s)
            </div>
            :
            ''
          }
          <div className="mail-attachments flex flex-wrap">
          {
            attachments.map((element, index) => (  
              (element.hasOwnProperty('filename'))
              ?            
              <div key={index} className="mail-attachment">
                <FileItem oid={item._id} fileName={element.filename} fileIndex={index} onClick={() => dialogCtx.showFileViewer(item._id, element.filename, index)}/>
                <div className="mail-file-name">{ element.filename }</div>
              </div>
              :
              ''        
            ))
          }
          </div>
        </div>
      );      
    }        
  }

  //////////////////////////////
  // FOR SEND SCHEDULE BUTTON //
  //////////////////////////////

  const options = ['Morning', 'Afternoon', 'Select'];

  const [scheduleMorningDesc, setMorningDesc] = useState('');
  const [scheduleMorningInfo, setMorningInfo] = useState('');
  const [scheduleTomorrowDesc, setTomorrowDesc] = useState('');
  const [scheduleTomorrowInfo, setTomorrowInfo] = useState('');

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(); //<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  
  // const handleCloseAndSave = () => {
  //   if (receivers.length !== 0 || subject !== '' || extractContent(editorValue) !== '') {
  //     if (files.length !== 0) {
  //       appDispatch(
  //         handleSnackbarState(
  //           getSnackbarState(SNACKBAR_VARIANT.INFO, "Attachment files aren't saved"),
  //         ),
  //       );
  //     }
      
  //     sendMail(authCtx.token, receivers, ccs, bccs, '0', '', subject, extractContent(editorValue), editorValue, [], '')
  //     .then((res) => {
  //       console.log(res); 
  //       if(res.result) {
  //       console.log('message saved with close', res.id);                 
  //       } else {
  //         console.log('message saving failed with close', res.id);
  //       }                       
  //     })
  //     .catch((e) => { console.log(e) });
  //   }
    
  //   // if (path === 'drafts') {
  //   //   getMainMails(path, "");
  //   // }
    
  //   dispatch(selectMail(null));
  // }
    
  const handleCloseAndDiscard = () => {
    setReply(false);
    setReplyall(false);
    setForward(false);
    setEditorValue('');
    dialogCtx.setInitFileList([]);
    // deleteDraftMail({token: authCtx.token, oid: selectedMail?.id})
    // .then((res) => {
    //   console.log(res);
      
    //   if(res.result) {
    //     console.log('draft message deleted with close', res.data);               
    //   } else {
    //     console.log('draft message deleting failed with close');
    //   }                       
    // })
    // .catch((e) => { console.log(e) });

    // dispatch(selectMail(null));
  }
    
  const [showPickDate, setShowPickDate] = useState(false);
  const [curPickTime, setCurPickTime] = useState(new dayjs());

  const getTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone + " Time";
    return timeZone.replace("/", " / ");
  }

  function getMonthShortName(monthNo) {
    const date = new Date();
    date.setMonth(monthNo);
  
    return date.toLocaleString('en-US', { month: 'short' });
  }

  const getScheduleTime = (type) => {
    const today = new Date();
    const tomorrow = new Date();

    if (type === 0) { // for morning
      if (today.getHours() >= 8) {
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(8);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(0);
        setMorningDesc("Tomorrow Morning");
        setMorningInfo(getMonthShortName(tomorrow.getMonth()) + " " + tomorrow.getDate() + ", 8:00 AM");
        scheduleDate[0] = tomorrow;
      } else {
        today.setHours(8);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        setMorningDesc("This Morning");
        setMorningInfo(getMonthShortName(today.getMonth()) + " " + today.getDate() + ", 8:00 AM");
        scheduleDate[0] = today;
      }
    } else if (type === 1) { // for afternoon
      if (today.getHours() >= 13) {
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(13);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(0);
        setTomorrowDesc("Tomorrow Afternoon");
        setTomorrowInfo(getMonthShortName(tomorrow.getMonth()) + " " + tomorrow.getDate() + ", 1:00 PM");
        scheduleDate[1] = tomorrow;
      } else {
        today.setHours(13);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        setTomorrowDesc("This Afternoon");
        setTomorrowInfo(getMonthShortName(today.getMonth()) + " " + today.getDate() + ", 1:00 PM");
        scheduleDate[1] = today;
      }
    }
  }

  const handleScheduleClick = (value) => {
    if (value < 2) {
      var scheduleTime = new dayjs(scheduleDate[value]);
      // console.log(scheduleTime.format());
      // sending with time
      
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.INFO, SEND_INFO.MESSAGE_SENDING),
        ),
      );
      
      if (item.reference === '') {
        item.reference = item.message_id; // when it is first reply
      }
      // console.log(item.messageId, item.reference);
      sendMail(authCtx.token, receivers, ccs, bccs, '0', '', subject, extractContent(editorValue), editorValue, files, scheduleTime.format(), item.message_id, item.reference, path)
      .then((res) => {
        // console.log(res);
        setReply(false);
        setReplyall(false);
        setForward(false); 
        if(res.result) {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.SUCCESS, SEND_INFO.MESSAGE_SENT),
            ),
          );
          getDetailMails();            
        } else {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.MESSAGE_SENT_FAILED),
            ),
          );
        }                        
      })
      .catch((e) => {});   
      dialogCtx.setInitFileList([]); 
    } else  {
      setCurPickTime(null);
      setShowPickDate(true);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    if (checkSendInfo() === false)
      return;
    getScheduleTime(0);
    getScheduleTime(1);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // if (
    //   anchorRef.current &&
    //   anchorRef.current.contains(event.target as HTMLElement)
    // ) {
    //   return;
    // }

    setOpen(false);
  };

  const scheduleSendClick = () => {
    if (curPickTime == null) {
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_PICKTIME_NULL),
        ),
      );
      return;
    } else {
      var curTime = new dayjs();
      if (curPickTime <= curTime) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_PICKTIME_PASS),
          ),
        );
        return;
      }
    }

    // console.log(curPickTime.format());
    // sending with time
    appDispatch(
      handleSnackbarState(
        getSnackbarState(SNACKBAR_VARIANT.INFO, SEND_INFO.MESSAGE_SENDING),
      ),
    );
    
    if (item.reference === '') {
      item.reference = item.message_id; // when it is first reply
    }
    // console.log(item.messageId, item.reference);
    sendMail(authCtx.token, receivers, ccs, bccs, '0', '', subject, extractContent(editorValue), editorValue, files, curPickTime.format(), item.message_id, item.reference, path)
    .then((res) => {
        // console.log(res);
        setReply(false);
        setReplyall(false);
        setForward(false); 
        if(res.result) {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.SUCCESS, SEND_INFO.MESSAGE_SENT),
            ),
          );
          getDetailMails();            
        } else {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.MESSAGE_SENT_FAILED),
            ),
          );
        }                        
    })
    .catch((e) => {});  
    dialogCtx.setInitFileList([]);
    setShowPickDate(false);
  }
  
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className="mail-bodyContent" style={last?{borderBottom: 'none'}:null}>
      <div className="mail-message pt-[20px] pb-[10px]">
        <div className="mail-middle-header" style={!last?{cursor: 'pointer'}:null} onClick={!last?() => setDetailed(!detailed):null}>
          <div className="flex pl-[18px] ">
            <Avatar size={36} name={from_name} isMe={authCtx.getAddress() == item.from}/>
            <div className="pl-[18px]">
              <div className="font-thin text-black text-sm"><span className="font-medium text-lg">{item.from_name}</span> &lt;{item.from}&gt; </div>
              <div className="font-thin text-xs pt-[0px]">
              {
                detailed
                ?
                <div className="relative flex flex-row items-center">
                  <span>{(item.to===authCtx.getAddress())?'to me':'to ' + item.title}</span>
                  <div className="flex flex-row" onMouseDown={(e) => menuCtx.showDetail(item, e)}>
                    <Tooltip title="Show details" placement="bottom">
                      <ArrowDropDownIcon style={{ fontSize: '10px' }} className="hover:bg-gray-100 hover:rounded-full"/>
                    </Tooltip>
                  </div>
                </div>
                :
                <div className="relative flex flex-row items-center">
                  <span className="">{highlightText(content_brief, query?query:"")}</span>
                </div>
              }                
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center">
            <p className="font-medium text-xs mr-2" >{getEmailDateTimeFormat(item.date, 2)}</p>
            {
              detailed &&
              <>
                <Tooltip title="Reply" placement="bottom">
                  <IconButton onClick={(e) => {e.stopPropagation(); handleReplyClick()}}>
                    <img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_baseline_nv700_20dp.png"></img>
                  </IconButton>
                </Tooltip>
                {
                  replyallable &&
                  <Tooltip title="Reply to all" placement="bottom">
                    <IconButton onClick={(e) => {e.stopPropagation(); handleReplyAllClick();}}>
                      <img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_all_baseline_nv700_20dp.png"></img>
                    </IconButton>
                  </Tooltip>
                }
                <Tooltip title="Forward" placement="bottom">
                  <IconButton onClick={(e) => {e.stopPropagation(); handleForwardClick()}}>
                    <img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/forward_baseline_nv700_20dp.png"></img>
                  </IconButton>
                </Tooltip>
              </>
            }
          </div>
        </div>        
        <div className="flex flex-col pl-[72px] pt-[0px]">
        {
          detailed &&
          <>
            {/* <div className="container mail-preview" style={{width : window.innerWidth - 400}}>
              <div className="emailPlayground">
                <div className="previewContainer">
                  <Letter html={content} />
                </div>
              </div>
            </div> */}
            <div className="flex-1 w-full my-[10px] mail-preview" dangerouslySetInnerHTML={{__html: highlightText_text(content, query)}} style={{width : window.innerWidth - 400}}>
              {/* <pre className="mail-preview">
                {content}
              </pre> */}
            </div>
            <div>
              { renderAttachments() }
            </div>
            {
              (reply === true || replyall === true || forward === true) &&
              <div className="mail-message py-[20px] ml-[-72px]" ref={myRef}>
                <div className="mail-reply-body">
                  <div className="flex pl-[18px] ">
                    <Avatar size={36} name={authCtx.getAddress()} isMe={true}/>
                  </div>
                  <div className="flex justify-center items-center pl-[18px]">
                    <div className="mail-send-reply">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <WM_S.ContentAreaWrapper>
                          <WM_S.ContentArea>
                            <div className="mail-reply-receiver">
                              <div className="pt-[10px] flex">
                                { reply && <img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_baseline_nv700_20dp.png"/> }
                                { replyall && <img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_all_baseline_nv700_20dp.png"/> }
                                { forward && <img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/forward_baseline_nv700_20dp.png"/> }
                                <Popover
                                  isOpen={isPopoverOpen}
                                  positions={['bottom', 'right']}
                                  disableReposition={false}
                                  onClickOutside={() => setIsPopoverOpen(false)} 
                                  content={({ position, nudgedLeft, nudgedTop }) => ( 
                                    <Box className="flex flex-col bg-white" style={{
                                      borderStyle : 'solid',
                                      borderWidth : '0px',
                                      borderColor : 'black',
                                      borderRadius : '5px',
                                      padding : '2px 5px',
                                      boxShadow: '2px 3px 3px 0px grey',
                                    }}>
                                      <MenuItem onClick={() => {handleReplyClick(); setIsPopoverOpen(false);}} style={{ fontSize: '14px', padding: '5px', justifyContent: 'flex-start' }} ><img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_baseline_nv700_20dp.png" style={{ marginRight: '8px' }} /> Reply</MenuItem>
                                      {
                                        replyallable &&
                                        <MenuItem onClick={() => {handleReplyAllClick(); setIsPopoverOpen(false);}} style={{ fontSize: '14px', padding: '5px', justifyContent: 'flex-start' }} ><img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_all_baseline_nv700_20dp.png" style={{ marginRight: '8px' }} /> Reply to all</MenuItem>                                
                                      }
                                      <MenuItem onClick={() => {handleForwardClick(); setIsPopoverOpen(false);}} style={{ fontSize: '14px', padding: '5px', justifyContent: 'flex-start' }} ><img src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/forward_baseline_nv700_20dp.png" style={{ marginRight: '8px' }} /> Forward</MenuItem>
                                      {/* <MenuItem onClick={() => dispatch(setResponseTypeSubject())}>Edit subject</MenuItem> */}
                                  </Box>
                                  )}
                                >
                                  <div onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
                                    <div className="flex flex-row">
                                      <Tooltip title="Type of response" placement="top">
                                        <ArrowDropDownIcon style={{ fontSize: '10px' }} className="hover:bg-gray-100 hover:rounded-full"/>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </Popover>
                              </div>
                              <div>
                                <div className="sendMail-to">
                                  <ListOfReceivers defaultReceivers={to} type={"receivers"} />
                                  { /*forward*/ true && (<div className="sendMail-container-ccbcc">
                                    { !showCcs ? <Tooltip title="Add Cc recipients" placement="bottom"><div className="sendMail-ccbcc" onClick={() => setShowCcs(true)}>Cc</div></Tooltip> : null }
                                    { !showBccs ? <Tooltip title="Add Bcc recipients" placement="bottom"><div className="sendMail-ccbcc" onClick={() => setShowBccs(true)}>Bcc</div></Tooltip> : null }
                                  </div>) }                          
                                </div> 
                                { showCcs ? <ListOfReceivers defaultReceivers={cc} type={"ccs"} /> : null }
                                { showBccs ? <ListOfReceivers defaultReceivers={bcc} type={"bccs"} /> : null }
                              </div>                                            
                            </div>   
                                  
                            <input
                              name="subject"
                              className="sendMail-input-subject"
                              placeholder="Subject"
                              value={subject}
                              type="hidden"
                              style={{ height:'33px', margin:'0 10px', paddingLeft:'4px',
                                fontSize:'14px', fontWeight:'bold',
                                border:'none', borderBottom:'1px solid #eceff1', outline:'none' }}
                              onChange={e => setSubject(e.target.value)} />

                            <QuillEditor
                              value={editorValue}
                              onChange={setEditorValue}>
                            </QuillEditor>

                            { replyDot3 && 
                              <Button style={{ width: '20px' }} onClick={handleReplyDot3}>
                                { (reply || replyall) && <MoreHorizIcon style={{fontSize:'small'}}/> }
                              </Button>
                            }
                          </WM_S.ContentArea>          
                        </WM_S.ContentAreaWrapper>
                        
                        <div className="mail-sendMail-options flex">                    
                          <ButtonGroup className="sendMail-send" variant="contained" aria-label="split button" style={{alignSelf:'end', height:'30px'}}>
                            <Button 
                              type="submit"
                              ref={anchorRef}
                              style={{backgroundColor: '#3079ed', borderRadius:'20px 0px 0px 20px', borderRight:'none', minWidth:'20px', marginRight:'-10px'}}>
                              Send
                            </Button>
                            <Button
                              size="small"
                              style={{backgroundColor: '#3079ed', borderRadius:'0px 20px 20px 0px', borderRight:'none', minWidth:'20px', padding:'6px 4px'}}
                              aria-controls={open ? 'split-button-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              onClick={handleToggle}>
                              <ArrowDropDownIcon />
                            </Button>
                          </ButtonGroup>
                          <Popper
                            sx={{ zIndex: 1 }}
                            open={open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal>
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === 'bottom' ? 'center top' : 'center bottom',
                                }}>
                                <Paper>
                                  <div style={{backgroundColor:'lavender', paddingLeft:'20px', paddingTop:'5px'}}>
                                    <span style={{fontSize:'18px'}}> Schedule send </span>
                                  </div>
                                  <div style={{backgroundColor:'lavender', paddingLeft:'20px', paddingBottom:'5px'}}>
                                    <span style={{fontSize:'18px', color:'darkgrey'}}> {getTimeZone()} </span>
                                  </div>
                                  <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem>
                                      {options.map((option, index) => (
                                        <MenuItem key={option}>
                                          { index === 0 ? 
                                            <div className="sendMail-schedule-list" onClick={() => handleScheduleClick(0)}>
                                              <div> <AccessAlarmIcon/> </div>
                                              <div style={{paddingLeft:'10px', width:'150px'}}> { scheduleMorningDesc } </div>
                                              <div style={{paddingLeft:'30px'}}> { scheduleMorningInfo } </div>
                                            </div> : 
                                            index === 1 ?
                                            <div className="sendMail-schedule-list" onClick={() => handleScheduleClick(1)}>
                                              <div> <AccessAlarmIcon/> </div>
                                              <div style={{paddingLeft:'10px', width:'150px'}}> { scheduleTomorrowDesc } </div>
                                              <div style={{paddingLeft:'30px'}}> { scheduleTomorrowInfo } </div>
                                            </div> :
                                            index === 2 ?
                                            <div className="sendMail-schedule-list" onClick={() => handleScheduleClick(2)}>
                                              <DateRangeIcon/>
                                              <div style={{paddingLeft:'10px'}}>Pick Time</div>
                                              <div style={{width:'200px'}}> </div>
                                            </div> : { }
                                          }
                                        </MenuItem>
                                      ))}
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                          
                          <DropZone handleInsertDrive={handleInsertDrive}/>
                          
                          {/* <SwitchDropzone {...{ dropZoneVisible, setDropZoneVisible }} /> */}

                          <Tooltip title="Insert files using Drive" placement="top">
                            <IconButton style={{ position:'absolute', alignSelf:'end', marginLeft:'155px', padding: '12px'}} onClick={handleInsertDrive}>
                              <AddToDriveIcon />
                            </IconButton>
                          </Tooltip>
                        
                          <Tooltip title="Delete" placement="top">
                            <IconButton  
                              style={{position:'relative', fontSize: '14px', alignSelf:'end', 
                                marginLeft: 'auto', marginRight: '5px', marginBottom:'4px'}}
                              // size="small"                         
                              edge="end"
                              aria-label="delete"
                              onClick={() => handleCloseAndDiscard()}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </form>
                    </div>        
                  </div>
                </div>

                {
                  showPickDate &&
                  <div className="sendMail-schedule-pick">
                    <Tooltip title="Close" placement="top">
                      <IconButton 
                        onClick={() => { setShowPickDate(false); } }
                        style={{ position:'relative', fontSize:'1.1rem !important', alignSelf:'end', marginLeft: 'auto', padding: '5px', marginRight: '1px' }}>
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>

                    <div style={{ height: '10px' }} />

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDateTimePicker
                        orientation="landscape"
                        // disablePast="false"
                        onChange={(newValue) => setCurPickTime(newValue)} />
                    </LocalizationProvider>
                    
                    <Button
                      variant="contained"
                      color="primary"
                      className="sendMail-send !px-6"
                      style={{ height: '50px' }}
                      onClick={() => { scheduleSendClick(); }}>
                      <ScheduleSendIcon/>
                      <div style={{paddingLeft:'10px'}}> Schedule Send </div>
                    </Button>
                  </div>
                }
              </div>
            }
          </>
        }
        {
          last === true && !reply && !replyall && !forward &&
          <div>
            <Button
              onClick={() => handleReplyClick()}
              style={{
                border: 'solid',
                borderRadius : '20px',
                borderWidth: '1px',
                borderColor: 'gray',
                paddingTop : 5,
                paddingBottom : 5,
                paddingRight : 20,
                paddingLeft : 15,
                marginTop : 20,                
                textTransform: 'none'
              }}
            >
              <img style={{ marginRight: '10px' }} src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_baseline_nv700_20dp.png"></img>
              Reply
            </Button>
            {
              replyallable &&
              <Button
                onClick={() => handleReplyAllClick()}
                style={{
                  border: 'solid',
                  borderRadius : '20px',
                  borderWidth: '1px',
                  borderColor: 'gray',
                  paddingTop : 5,
                  paddingBottom : 5,
                  paddingRight : 20,
                  paddingLeft : 15,
                  marginLeft : 20,
                  marginTop : 20,                
                  textTransform: 'none'
                }}
              >
                <img style={{ marginRight: '10px' }} src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/reply_all_baseline_nv700_20dp.png"></img>
                Reply to all
              </Button> 
            }                     
            <Button
              onClick={() => handleForwardClick()}
              style={{
                border: 'solid',
                borderRadius : '20px',
                borderWidth: '1px',
                borderColor: 'gray',
                paddingTop : 5,
                paddingBottom : 5,
                paddingRight : 20,
                paddingLeft : 15,
                marginLeft : 20,
                marginTop : 20,
                textTransform: 'none'
              }}
            >
              <img style={{ marginRight: '10px' }} src="//ssl.gstatic.com/ui/v1/icons/mail/gm3/1x/forward_baseline_nv700_20dp.png"></img>
              Foward
            </Button>
          </div>
        }
        </div>
      </div>             
    </div>        
  );
}

export default MailItem;
