import { createSlice } from "@reduxjs/toolkit";

export const mailSlice = createSlice({
  name: "mail",
  initialState: {
    selectedMail: null,
    selectedSearch: null,
    sendMessageIsOpen: false,
    sidebarIsOpen: false,
    responseTypeReplyOrForward: 0,
    responseTypeSubject: false,
  },
  reducers: {
    selectMail: (state, action) => {
      state.selectedMail = action.payload;
    },
    selectSearch: (state, action) => {
      state.selectedSearch = action.payload;
    },
    openSendMessage: (state) => {
      state.sendMessageIsOpen = true;
    },
    closeSendMessage: (state) => {
      state.sendMessageIsOpen = false;
    },
    openSidebar: (state) => {
      state.sidebarIsOpen = !state.sidebarIsOpen;
    },
    closeSidebar: (state) => {
      state.sidebarIsOpen = false;
    },
    setResponseTypeZero: (state) => {
      state.responseTypeReplyOrForward = 0;
    },
    setResponseTypeReply: (state) => {
      state.responseTypeReplyOrForward = 1;
    },
    setResponseTypeForward: (state) => {
      state.responseTypeReplyOrForward = 2;
    },
    setResponseTypeSubject: (state) => {
      state.responseTypeSubject = true;
    },
  },
});

export const { selectMail, selectSearch, openSendMessage, closeSendMessage, openSidebar, closeSidebar, setResponseTypeZero, setResponseTypeReply, setResponseTypeForward, setResponseTypeSubject } =
  mailSlice.actions;

export const selectOpenMail = (state) => state.mail.selectedMail;
export const selectOpenSearch = (state) => state.mail.selectedSearch;
export const selectSendMessageIsOpen = (state) => state.mail.sendMessageIsOpen;
export const selectSidebarIsOpen = (state) => state.mail.sidebarIsOpen;
export const selectResponseTypeReplyOrForward = (state) => state.mail.responseTypeReplyOrForward;
export const selectResponseTypeSubject = (state) => state.mail.responseTypeSubject;

export default mailSlice.reducer;
