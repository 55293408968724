
import { useState } from 'react';
import LoadingContext from '../contexts/loadingContext';
import { Loading } from '../Components';

export default function LoadingProvider(props) {
	const [loading, setLoading] = useState(false);
	const [refreshEmail, setRefreshEmail] = useState(0);
	const [path, setPath] = useState('inbox');
	const [category, setCategory] = useState(0);
	const [curpage, setCurpage] = useState(1);
	const [maximizeEmail, setMaximizeEmail] = useState(0);

    const setLoadingHandler = (loading) => {
		if(loading === true){
			const loader = document.querySelector('.loader');
			loader.classList.remove('loader--hide');
		}
		else{
			const loader = document.querySelector('.loader');
			loader.classList.add('loader--hide');
		}
        setLoading(loading);
    }

	let context = {
		loading: loading,
		setLoading: setLoadingHandler,
		refreshEmail,
		setRefreshEmail,
		path,
		setPath,
		category,
		setCategory,
		curpage,
		setCurpage,
		maximizeEmail,
		setMaximizeEmail
	};

	return (
		<LoadingContext.Provider value={context}>
			{props.children}
			{/* {
				loading ? <Loading /> : ""
			} */}
		</LoadingContext.Provider>
	);
};