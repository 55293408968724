
import { useState, useContext, useEffect } from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone'

import AuthContext from '../../../contexts/authContext';
import DialogContext from '../../../contexts/dialogContext';

import { uploadFile } from './apis';

import "./upload.css";

export default function Upload({cb}) {
    const authCtx = useContext(AuthContext);
    const diagCtx = useContext(DialogContext);

    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);

    const onUploadProgress = (p) => {
        setProgress(p);
    }

    const handleUpload = (e) => {
        if (e.length === 0) return;

        const file = e[0];

        setUploading(true);
        uploadFile(file, "/", authCtx.getAddress(), onUploadProgress)
        .then((res) => {
            cb(file.name, res.id);
        })
        .catch((e) => {})
    }

    return <Box className="px-2 py-4 overflow-auto h-[320px]">
        {
            uploading ? 
            <Box className="h-full flex">
                <Box className="w-[50%] m-auto">
                    <Typography variant="h5" className="text-center">
                        Uploading file...
                    </Typography>
                    <Box className="mb-8" />
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
            </Box>
            :
            <DropzoneArea
                initialFiles = {diagCtx.initFileList}
                showAlerts={false}
                onChange={handleUpload}
                maxFileSize={4000000000}
                dropzoneClass="material-ui-dropzone"
            />
        }
    </Box>
}