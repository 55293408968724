import React, { useContext, useEffect, useState } from "react";
import "./Mail.css";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import { Button } from "@material-ui/core";
import { Avatar } from "..";
import { IconButton } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectOpenMail, setResponseTypeZero } from "../../features/mailSlice";
import { useSelector } from "react-redux";
import AuthContext from '../../contexts/authContext';
import LoadingContext from '../../contexts/loadingContext';
import MailItemM from "./MailItemM.js";
import { setStatusMail, getDetails } from '../../apis';
import { isUndefined } from "lodash";

function MailM() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const loadingCtx = useContext(LoadingContext);
  const [remails, setRemails] = useState([]);
  const selectedMail = useSelector(selectOpenMail);
  const path = (selectedMail === null)?"":selectedMail.path;

  useEffect(() => {
    if(selectedMail === null) {
      // console.log("selectedMail-null");
      navigate("/");
    }      
    getDetailMails();

  }, [selectedMail]);

  const getDetailMails = () => {
    loadingCtx.setLoading(true);
    let query = { token: authCtx.token };
    query.message_id = selectedMail?.message_id;
    query.path = path;  

    getDetails(query)
    .then(async (res) => { 
      if (res.result) {
        setRemails(
          res.data.map((doc) => {              
            return {...doc};
          })
        );
      } else {
        console.log(res.message);
      }       
    })
    .catch((e) => {})
    .finally(() => {
      console.log('finally');
      // Set loading to false only when all items have been processed
      loadingCtx.setLoading(false);
    });
  };

  const onSetStatusRow = (field, value) => {
    setStatusMail(authCtx.token, selectedMail?.message_id, field, value)
    .then(function(res) { 
      if (res.result) {
        console.log("Document successfully updated!");
        navigate("/" + ((path !== undefined)?path:""));
        // result.data = { ...result.data, selected: false};
      }
    })
    .catch(function(error) {
        console.error("Error updating document: ", error);
        navigate("/" + ((path !== undefined)?path:""));
    });
  };  

  return (
    <div className="mail-mobile">
      <div className="mail-tools">
        <div className="mail-toolsLeft">
          <IconButton onClick={() => navigate("/" + (path !== undefined?path:""))} >
            <ArrowBackOutlinedIcon />
          </IconButton>
          {
            (path === "spam")
            ?
            <IconButton onClick={() => onSetStatusRow('is_spam', 0)}>
              <ReportOffOutlinedIcon />
            </IconButton>
            :
            <IconButton onClick={() => onSetStatusRow('is_spam', 1)} >
              <ReportGmailerrorredOutlinedIcon />
            </IconButton>
          }
          {
            (path === "trash")
            ?
            <IconButton onClick={() => onSetStatusRow('is_deleted', 1)}>
              <DeleteForeverOutlinedIcon />
            </IconButton>
            :
            <IconButton onClick={() => onSetStatusRow('is_trashed', 1)} >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          }
          <IconButton onClick={() => onSetStatusRow('is_read', 0)}>
            <MarkunreadOutlinedIcon />
          </IconButton>
        </div>
      </div>
      <div className="mail-body">
        <div className="mail-bodyHeader-mobile">
          <div className="mail-subject-mobile">
            <h3>{selectedMail?.subject}</h3>
          </div>   
        </div>
        {
          remails.map((item, i) => (
            <MailItemM key={item._id} item={item} getDetailMails={getDetailMails} _subject={selectedMail?.subject} last={remails.length === (i+1)} path={path} />
          ))
        }
        <div style={{ minHeight: '60px' }}></div>
      </div>
    </div>
  );
}

export default MailM;
