import React, { useContext, useEffect, useLayoutEffect, useState, useRef } from "react";
import "./Mail.css";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LabelImportantTwoToneIcon from '@mui/icons-material/LabelImportantTwoTone';
import { Button } from "@material-ui/core";
import { Avatar } from "..";
import { IconButton } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectOpenMail } from "../../features/mailSlice";
import { useSelector } from "react-redux";
import AuthContext from '../../contexts/authContext';
import LoadingContext from '../../contexts/loadingContext';
import MailItem from "./MailItem.js";
import { setStatusMail, getDetails } from '../../apis';
import { isUndefined } from "lodash";
import Tooltip from '@mui/material/Tooltip';
import { highlightText } from '../../utils';

function Mail() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const loadingCtx = useContext(LoadingContext);
  const [remails, setRemails] = useState([]);
  const selectedMail = useSelector(selectOpenMail);
  const path = (selectedMail === null)?"":selectedMail.path;
  const tooltip_back = "Back to " + path.charAt(0).toUpperCase() + path.slice(1);
  const myDivRef = useRef(null);

  useEffect(() => {
    if(selectedMail === null) {
      // console.log("selectedMail-null");
      navigate("/");
    }      
    getDetailMails();
    
  }, [selectedMail]);
  
  const getDetailMails = () => {
    loadingCtx.setLoading(true);
    let query = { token: authCtx.token };
    query.message_id = selectedMail?.message_id;
    query.path = path;  

    getDetails(query)
    .then(async (res) => { 
      if (res.result) {
        setRemails(
          res.data.map((doc) => {              
            return {...doc};
          })
        );
      } else {
        console.log(res.message);
      }       
    })
    .catch((e) => {})
    .finally(() => {
      console.log('finally');
      loadingCtx.setLoading(false);
      // Set loading to false only when all items have been processed
    });
};

  const onSetStatusRow = (field, value) => {
    
    setStatusMail(authCtx.token, selectedMail?.message_id, field, value)
    .then(function(res) { 
      if (res.result) {
        console.log("Document successfully updated!");
        navigate("/" + ((path !== undefined)?path:""));
        // result.data = { ...result.data, selected: false};
      }
    })
    .catch(function(error) {
        console.error("Error updating document: ", error);
        navigate("/" + ((path !== undefined)?path:""));
    }); 
    
  };

  const moveScrollToBottom = (offset) => {
    if (myDivRef.current) {
      // Scroll to the end of the div
      // alert(offset - myDivRef.current.offsetTop);
      myDivRef.current.scrollTop = offset - myDivRef.current.offsetTop;
    }
  }

  const goBack = () => {
    console.log(path, "/" + (path !== undefined?path:""));
    navigate("/" + (path !== undefined?path:""))
  }

  const renderMailItem = () => {        
    return (<>
      {
        remails.map((item, i) => (
          <MailItem key={i} item={item} getDetailMails={getDetailMails} query={selectedMail?.query} _subject={selectedMail?.subject} last={remails.length === (i+1)} path={path} moveScrollBottom={moveScrollToBottom}/>
        ))
      }
    </>);
  };

  return (
    <div className="mail">
      <div className="mail-tools px-1 pt-1">
        <div className="mail-toolsLeft">
          <Tooltip title={tooltip_back} placement="bottom">
            <IconButton onClick={() => goBack()} >
              <ArrowBackOutlinedIcon />
            </IconButton>
          </Tooltip>
          {
            (path === "spam")
            ?
            <Tooltip title="Not spam" placement="bottom">
              <IconButton onClick={() => onSetStatusRow('is_spam', 0)}>
                <ReportOffOutlinedIcon />
              </IconButton>
            </Tooltip>
            :
            <Tooltip title="Report spam" placement="bottom">
              <IconButton onClick={() => onSetStatusRow('is_spam', 1)} >
                <ReportGmailerrorredOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
          {
            (path === "trash")
            ?
            <Tooltip title="Delete forever" placement="bottom">
              <IconButton onClick={() => onSetStatusRow('is_deleted', 1)}>
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </Tooltip>
            :
            <Tooltip title="Delete" placement="bottom">
              <IconButton onClick={() => onSetStatusRow('is_trashed', 1)} >
                <DeleteOutlineOutlinedIcon />
              </IconButton>
            </Tooltip>
          }
          <Tooltip title="Mark as unread" placement="bottom">
            <IconButton onClick={() => onSetStatusRow('is_read', 0)}>
              <MarkunreadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="mail-body">
        <div className="mail-bodyHeader">
          <div className="mail-subject">
            <h2>{highlightText(selectedMail?.subject?selectedMail?.subject:"", selectedMail?.query?selectedMail?.query:"")}</h2>
          </div>   
        </div>
        <div className="flex flex-col flex-1" style={{overflowY : 'auto', marginBottom : 120}} ref={myDivRef}>
          {
            renderMailItem()
          }
        </div>
      </div>
    </div>
  );
}

export default Mail;
