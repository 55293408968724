import {React, Component, useContext, useState, useRef, useCallback, useEffect} from "react";
import "./SendMail.css";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { closeSendMessage } from "../../features/mailSlice";
import { sendMail, getDraftMail, deleteDraftMail } from '../../apis';
import AuthContext from '../../contexts/authContext';
import DialogContext from "../../contexts/dialogContext";
import LoadingContext from "../../contexts/loadingContext";
import InputReceiver from './InputReceiver';
import ListOfReceivers from "./InputReceiver/multiselect";
import { transformDateToReserve } from '../../utils/transform-date';
import { ERROR_CANNOT_RESERVATION } from '../../utils/error-message';
import { useStateForWM } from './ContextProvider';
import SwitchDropzone from './SwitchDropzone';
import DropZone from './DropZone';
import autosize from 'autosize';
import * as WM_S from './styled';
import validator from '../../utils/validator';
import { errorParser } from '../../utils/error-parser';
import { AppDispatchContext, AppStateContext } from '../../contexts';
import { handleSnackbarState } from '../../contexts/reducer';
import { getSnackbarState, SNACKBAR_VARIANT } from '../Snackbar';

// import QuillEditor from "../QuillEditor"
import { CKEditor } from 'ckeditor4-react';

import { Button, IconButton, Tooltip } from "@mui/material";
import MinimizeIcon from "@material-ui/icons/Minimize";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CloseIcon from "@material-ui/icons/Close";
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import DeleteIcon from '@material-ui/icons/Delete';

import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";

import { selectOpenMail, selectMail } from "../../features/mailSlice";
import { useSelector } from "react-redux";

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';

const SEND_INFO = {
  MESSAGE_SENT: 'Message sent.',
  MESSAGE_SENDING: 'Message sending...',
  MESSAGE_SENT_FAILED: 'Message sending failed.',
  NO_RECIPIENT: 'Please specify at least one recipient.',
  INVALID_RECIPIENTS: 'Please specify valid recipients.',
  EMPTY_MESSAGE: 'Please input message.',
  INVALID_PICKTIME_NULL: 'Please pick time.',
  INVALID_PICKTIME_PASS: 'Picked passed time. Please pick other time.'
};

var scheduleDate = [null, null, null];

function SendMailM({path, getMainMails}) {
  const [to, setTo] = useState(false);
  const [cc, setCc] = useState([]);
  const [bcc, setBcc] = useState([]);
  const authCtx = useContext(AuthContext);
  const loadingCtx = useContext(LoadingContext);
  const dialogCtx = useContext(DialogContext);
  const { dispatch: appDispatch } = useContext(AppDispatchContext);
  const { receivers, ccs, bccs, files } = useStateForWM();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [dropZoneVisible, setDropZoneVisible] = useState(false);

  const [showCcs, setShowCcs] = useState(false);
  const [showBccs, setShowBccs] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [maximize, setMaximize] = useState(false);

  const [editorValue, setEditorValue] = useState('');

  const [subject, setSubject] = useState('');
  const [objectid, setObjectid] = useState('');
  const [timer, setTimer] = useState(5);
  const selectedMail = useSelector(selectOpenMail);

  const [editor, setEditor] = useState(null);
  
  const onEditorChange = evt => {
    setEditorValue(evt.editor.getData());
  }

  const onInstanceReady = evt => {
    setEditor(evt.editor);    
  }

  useEffect(() => {
    if (!minimize) {
      setTo(receivers);
      setCc(ccs);
      setBcc(bccs);
    }
  }, [minimize])

  useEffect(() => {
    if(minimize === true){
      setMinimize(false);
      setMaximize(true);
    }
  }, [loadingCtx.maximizeEmail]);

  useEffect(() => {
    // console.log("selectedMail", selectedMail);
    if(selectedMail !== null && selectedMail?.is_draft === '1' && editor) {      
      getDraftMail({token: authCtx.token, oid: selectedMail?.id})
      .then((res) => { console.log(res);
        if (res.result) { 
          console.log(res.data);
          setObjectid(res.data._id);
          
          let tos = [];
          if (res.data.to !== '') {
            if (res.data.to.startsWith('[')) {
              tos = JSON.parse(res.data.to);
            } else {
              tos.push(res.data.to);
            } 
          }  
          setTo(tos);                  

          let ccs = [];
          if (res.data.cc !== '') {
            if (res.data.cc.startsWith('[')) {
              ccs = JSON.parse(res.data.cc);
            } else {
              ccs.push(res.data.cc);
            }
          }                    
          setCc(ccs);

          let bccs = [];
          if (res.data.bcc !== '') {
            if (res.data.bcc.startsWith('[')) {
              bccs = JSON.parse(res.data.bcc);
            } else {
              bccs.push(res.data.bcc);
            }     
          }     
          setBcc(bccs);

          setSubject(res.data.subject);
          setEditorValue(res.data.html);
          editor.setData(res.data.html, function() {
            // This callback is called once setData is completed
            // Move the cursor to the end of the editor
            let range = editor.createRange();
            range.moveToElementEditEnd(range.root);
            editor.getSelection().selectRanges([range]); // puts cursor at end after setData
            editor.focus();
            editor.window.getScrollPosition().y = editor.window.getViewPaneSize().height;
          });
        }
        else {
          console.log(res.message);
        }       
      })
      .catch((e) => {console.log(e)});
    }      
    
  }, [selectedMail, editor]);

  useEffect(() => {
    if (cc.length > 0)
      setShowCcs(true);
    else 
      setShowCcs(false);
  }, [cc])
 
  useEffect(() => {
    if (bcc.length > 0)
      setShowBccs(true);
    else 
      setShowBccs(false);
  }, [bcc])
  
  const decrementTimer =  useCallback(() => {
    setTimer((oldTimer) => oldTimer-1)
  },[])

  useEffect(() => {
    if(timer <= 0){
      setTimer(5)
      console.log('w', objectid);
      if (receivers.length === 0 && ccs.length === 0 && bccs.length === 0 && subject === '' && editorValue === '' && files.length === 0) {
        return;
      }
      sendMail(authCtx.token, receivers, ccs, bccs, '1', objectid, subject, editorValue, editorValue, files, '')
      .then((res) => {
        console.log(res);
        // loadingCtx.setLoading(false); 
        if(res.result) {
          console.log('message saved automatically', res.id);
          setObjectid(res.id);
          
          if (path === 'drafts') {
            getMainMails(path);
          }
      
        } else {
          console.log('message saving failed automatically', res.id);
        }                       
      })
      .catch((e) => { console.log(e) });
    }

    const timeoutFunction = setInterval(decrementTimer, 1000)
    return () => clearInterval(timeoutFunction);
  },[decrementTimer, timer])

  const extractContent = (s) => {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };

  const checkSendInfo = () => {
    if (receivers.length === 0) {
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.NO_RECIPIENT),
        ),
      );
      return false;
    }
    if (!receivers.every(receiver => validator.validate('email', receiver))) {     
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_RECIPIENTS),
        ),
      );
      return false;
    }
    if (!ccs.every(receiver => validator.validate('email', receiver))) {   
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_RECIPIENTS),
        ),
      );  
      return false;
    }
    if (!bccs.every(receiver => validator.validate('email', receiver))) {     
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_RECIPIENTS),
        ),
      );
      return false;
    }
    // if (!editorValue.length) {
    //   appDispatch(
    //     handleSnackbarState(
    //       getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.EMPTY_MESSAGE),
    //     ),
    //   );
    //   return false;
    // }

    return true;
  }

  const onSubmit = (formData) => {
    if (checkSendInfo() === false)
      return;
    // console.log(receivers, ccs, bccs);
    // loadingCtx.setLoading(true);
    appDispatch(
      handleSnackbarState(
        getSnackbarState(SNACKBAR_VARIANT.INFO, SEND_INFO.MESSAGE_SENDING),
      ),
    );
    sendMail(authCtx.token, receivers, ccs, bccs, '0', objectid, formData.subject, editorValue, editorValue, files, '')
    .then((res) => {
      console.log(res);
      // loadingCtx.setLoading(false); 
      if(res.result) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.SUCCESS, SEND_INFO.MESSAGE_SENT),
          ),
        );
        getMainMails(path);  
      } else {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.MESSAGE_SENT_FAILED),
          ),
        );
      }                       
    })
    .catch((e) => {});

    dispatch(closeSendMessage());
  };

  const handleInsertDrive = () => {
    const cb = (link, name) => {
      //setEditorValue(editorValue + '\n' + link + '\n');
      const newData = `${editorValue}<a href="${link}">${name}</a><br/>`;
      setEditorValue(newData);
      if (editor) {
        editor.setData(newData, function() {
          // This callback is called once setData is completed
          // Move the cursor to the end of the editor
          let range = editor.createRange();
          range.moveToElementEditEnd(range.root);
          editor.getSelection().selectRanges([range]); // puts cursor at end after setData
          editor.focus();
          editor.window.getScrollPosition().y = editor.window.getViewPaneSize().height;
        });
      } else {
        console.log('drive-editor-null');
      }
    }
    dialogCtx.showInsertDrive(cb);
  }

  const autoSizeTextarea = (e) => {
    autosize(e.target);
  }

  //////////////////////////////
  // FOR SEND SCHEDULE BUTTON //
  //////////////////////////////

  const options = ['Morning', 'Afternoon', 'Select'];

  const [scheduleMorningDesc, setMorningDesc] = useState('');
  const [scheduleMorningInfo, setMorningInfo] = useState('');
  const [scheduleTomorrowDesc, setTomorrowDesc] = useState('');
  const [scheduleTomorrowInfo, setTomorrowInfo] = useState('');

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(); //<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleCloseAndSave = () => {
    if (receivers.length !== 0 || ccs.length !== 0 || bccs.length !== 0 || subject !== '' || editorValue !== '') {
      if (files.length !== 0) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.INFO, "Attachment files aren't saved"),
          ),
        );
      }
      
      sendMail(authCtx.token, receivers, ccs, bccs, '1', objectid, subject, editorValue, editorValue, [], '')
      .then((res) => {
        console.log(res);
        // loadingCtx.setLoading(false); 
        if(res.result) {
        console.log('message saved with close', res.id);                 
        } else {
          console.log('message saving failed with close', res.id);
        }                       
      })
      .catch((e) => { console.log(e) });
    }
    
    if (path === 'drafts') {
      getMainMails(path);
    }
    
    // dispatch(selectMail(null));
    dispatch(closeSendMessage());
  }
  
  
  const handleCloseAndDiscard = () => {
    deleteDraftMail({token: authCtx.token, oid: selectedMail?.id})
    .then((res) => {
      console.log(res);
      
      if(res.result) {
        console.log('draft message deleted with close', res.data);               
      } else {
        console.log('draft message deleting failed with close');
      }                       
    })
    .catch((e) => { console.log(e) });

    if (path === 'drafts') {
      getMainMails(path);
    }
     
    // dispatch(selectMail(null));
    dispatch(closeSendMessage());
  }
    
  const [showPickDate, setShowPickDate] = useState(false);
  const [curPickTime, setCurPickTime] = useState(new dayjs());

  const getTimeZone = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone + " Time";
    return timeZone.replace("/", " / ");
  }

  function getMonthShortName(monthNo) {
    const date = new Date();
    date.setMonth(monthNo);
  
    return date.toLocaleString('en-US', { month: 'short' });
  }

  const getScheduleTime = (type) => {
    const today = new Date();
    const tomorrow = new Date();

    if (type === 0) { // for morning
      if (today.getHours() >= 8) {
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(8);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(0);
        setMorningDesc("Tomorrow Morning");
        setMorningInfo(getMonthShortName(tomorrow.getMonth()) + " " + tomorrow.getDate() + ", 8:00 AM");
        scheduleDate[0] = tomorrow;
      } else {
        today.setHours(8);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        setMorningDesc("This Morning");
        setMorningInfo(getMonthShortName(today.getMonth()) + " " + today.getDate() + ", 8:00 AM");
        scheduleDate[0] = today;
      }
    } else if (type === 1) { // for afternoon
      if (today.getHours() >= 13) {
        tomorrow.setDate(today.getDate() + 1);
        tomorrow.setHours(13);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(0);
        setTomorrowDesc("Tomorrow Afternoon");
        setTomorrowInfo(getMonthShortName(tomorrow.getMonth()) + " " + tomorrow.getDate() + ", 1:00 PM");
        scheduleDate[1] = tomorrow;
      } else {
        today.setHours(13);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        setTomorrowDesc("This Afternoon");
        setTomorrowInfo(getMonthShortName(today.getMonth()) + " " + today.getDate() + ", 1:00 PM");
        scheduleDate[1] = today;
      }
    }
  }

  const handleScheduleClick = (value) => {
    if (value < 2) {
      var scheduleTime = new dayjs(scheduleDate[value]);
      console.log(scheduleTime.format());
      // sending with time
      sendMail(authCtx.token, receivers, ccs, bccs, '0', objectid, subject, editorValue, editorValue, files, scheduleTime.format())
      .then((res) => {
        console.log(res);
        // loadingCtx.setLoading(false); 
        if(res.result) {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.SUCCESS, SEND_INFO.MESSAGE_SENT),
            ),
          );
          
          if (path === 'drafts' || path === 'sent' || path === 'scheduled') {
            getMainMails(path);
          }
      
        } else {
          appDispatch(
            handleSnackbarState(
              getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.MESSAGE_SENT_FAILED),
            ),
          );
        }                       
      })
      .catch((e) => {});

      setShowPickDate(false);
      dispatch(closeSendMessage());
    } else  {
      setCurPickTime(null);
      setShowPickDate(true);
    }
    setOpen(false);
  };

  const handleToggle = () => {
    if (checkSendInfo() === false)
      return;
    getScheduleTime(0);
    getScheduleTime(1);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    // if (
    //   anchorRef.current &&
    //   anchorRef.current.contains(event.target as HTMLElement)
    // ) {
    //   return;
    // }

    setOpen(false);
  };

  const scheduleSendClick = () => {
    if (curPickTime == null) {
      appDispatch(
        handleSnackbarState(
          getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_PICKTIME_NULL),
        ),
      );
      return;
    } else {
      var curTime = new dayjs();
      if (curPickTime <= curTime) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.INVALID_PICKTIME_PASS),
          ),
        );
        return;
      }
    }

    console.log(curPickTime.format());
    // sending with time
    sendMail(authCtx.token, receivers, ccs, bccs, '0', objectid, subject, editorValue, editorValue, files, curPickTime.format())
    .then((res) => {
      console.log(res);
      // loadingCtx.setLoading(false); 
      if(res.result) {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.SUCCESS, SEND_INFO.MESSAGE_SENT),
          ),
        );
        
        if (path === 'drafts' || path === 'sent' || path === 'scheduled') {
          getMainMails(path);
        }
      } else {
        appDispatch(
          handleSnackbarState(
            getSnackbarState(SNACKBAR_VARIANT.ERROR, SEND_INFO.MESSAGE_SENT_FAILED),
          ),
        );
      }
      setShowPickDate(false);
    })
    .catch((e) => { setShowPickDate(false); });

    dispatch(closeSendMessage());
    setShowPickDate(false);
  }

  return (
    <div className="sendMail-mobile">
      <div className="sendMail-header-mobile">
        <h3>New Message</h3>
        <IconButton onClick={() => dispatch(closeSendMessage())}>
          <CloseIcon/>
        </IconButton>
        {/* <CloseIcon
          onClick={() => dispatch(closeSendMessage())}
          className="sendMail-close-mobile" style={{ fontSize: '1.1rem'}}
        /> */}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="sendMail-form-div">
          <div className="sendMail-content">

            <div className="sendMail-to">
              <ListOfReceivers defaultReceiver={null} type={"receivers"} />
              <div className="sendMail-container-ccbcc">
                { !showCcs ? <div className="sendMail-ccbcc-mobile" onClick={() => setShowCcs(true)}>Cc</div> : null }
                { !showBccs ? <div className="sendMail-ccbcc-mobile" onClick={() => setShowBccs(true)}>Bcc</div> : null }
              </div>
            </div>

            { showCcs ? <ListOfReceivers defaultReceiver={null} type={"ccs"} /> : null }
            { showBccs ? <ListOfReceivers defaultReceiver={null} type={"bccs"} /> : null }

            <input
              name="subject"
              className="sendMail-input-subject"
              placeholder="Subject"
              value={subject}
              type="text"
              style={{ height:'33px', margin:'0 10px', paddingLeft:'4px',
                fontSize:'14px', fontWeight:'bold',
                border:'none', borderBottom:'1px solid #eceff1', outline:'none' }}
              {...register("subject", { required: true })}
              onChange={e => setSubject(e.target.value)} />

            {/* {errors.subject && (
              <div className="sendMail-error">Subject is required!</div>
            )} */}

          </div>

          <div className="sendMail-quill-editor">
            {/* <QuillEditor
              value={editorValue}
              onChange={setEditorValue}>
            </QuillEditor> */}
            <CKEditor
              data={editorValue}
              onChange={onEditorChange}
              onInstanceReady={onInstanceReady}
              config= {{
                toolbar: [
                  ["Undo", "Redo"],
                  ["NumberedList", "BulletedList", 'Outdent', 'Indent'],
                  ["Font", "FontSize"],
                  ["Bold", "Italic", 'Underline', 'Strike'],
                  ['TextColor', 'BGColor'], 
                  ["Link"],
                  ['Image'],
                  ["EmojiPanel"],                        
                ],
                image_previewText: "",
                extraPlugins: "font,emoji,colorbutton", // Include the names of your plugins here
              }}
              scriptUrl="https://mail.unimail.in/ckeditor/ckeditor.js"
            />
          </div>
        </div>
        
        <div className="sendMail-options flex">
          {/* <Button
            type="submit"
            variant="contained"
            color="primary"
            className="sendMail-send !px-6"
            style={{ alignSelf:'end', height:'35px' }} >
            Send
          </Button> */}

          <ButtonGroup className="sendMail-send" variant="contained" aria-label="split button" style={{alignSelf:'end', height:'35px'}}>
            <Button 
              type="submit"
              ref={anchorRef}
              style={{backgroundColor: '#3079ed', borderRadius:'20px 0px 0px 20px', borderRight:'none', minWidth:'50px', marginRight:'-10px'}}>
              Send
            </Button>
            <Button
              size="small"
              style={{backgroundColor: '#3079ed', borderRadius:'0px 20px 20px 0px', borderRight:'none', minWidth:'20px', padding:'6px 4px'}}
              aria-controls={open ? 'split-button-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-label="select merge strategy"
              aria-haspopup="menu"
              onClick={handleToggle}>
              <ArrowDropDownIcon />
            </Button>
          </ButtonGroup>
          <Popper
            sx={{ zIndex: 1 }}
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper>
                  <div style={{backgroundColor:'lavender', paddingLeft:'20px', paddingTop:'5px'}}>
                    <span style={{fontSize:'18px'}}> Schedule send </span>
                  </div>
                  <div style={{backgroundColor:'lavender', paddingLeft:'20px', paddingBottom:'5px'}}>
                    <span style={{fontSize:'18px', color:'darkgrey'}}> {getTimeZone()} </span>
                  </div>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList id="split-button-menu" autoFocusItem>
                      {options.map((option, index) => (
                        <MenuItem key={option}>
                          { index === 0 ? 
                            <div className="sendMail-schedule-list" onClick={() => handleScheduleClick(0)}>
                              <div> <AccessAlarmIcon/> </div>
                              <div style={{paddingLeft:'10px', width:'150px'}}> { scheduleMorningDesc } </div>
                              <div style={{paddingLeft:'30px'}}> { scheduleMorningInfo } </div>
                            </div> : 
                            index === 1 ?
                            <div className="sendMail-schedule-list" onClick={() => handleScheduleClick(1)}>
                              <div> <AccessAlarmIcon/> </div>
                              <div style={{paddingLeft:'10px', width:'150px'}}> { scheduleTomorrowDesc } </div>
                              <div style={{paddingLeft:'30px'}}> { scheduleTomorrowInfo } </div>
                            </div> :
                            index === 2 ?
                            <div className="sendMail-schedule-list" onClick={() => handleScheduleClick(2)}>
                              <DateRangeIcon/>
                              <div style={{paddingLeft:'10px'}}>Pick Time</div>
                              <div style={{width:'200px'}}> </div>
                            </div> : { }
                          }
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <DropZone visible={true} />

          <IconButton style={{ position:'absolute', alignSelf:'end', marginLeft:'155px', padding: '12px' }} onClick={handleInsertDrive}>
            <AddToDriveIcon />
          </IconButton>

          <IconButton 
            onClick={() => dispatch(handleCloseAndDiscard())} 
            style={{ position:'relative', fontSize:'1.1rem !important', alignSelf:'end', marginLeft: 'auto', padding: '12px', marginRight: '5px' }}>
            <DeleteIcon />
          </IconButton>
        </div>
      </form>
      {
      showPickDate &&
      <div className="sendMail-black">
        <div className="sendMail-schedule-pick">
          <Tooltip title="Close" placement="top">
            <IconButton 
              onClick={() => { setShowPickDate(false); } }
              style={{ position:'relative', fontSize:'1.1rem !important', alignSelf:'end', marginLeft: 'auto', padding: '5px', marginRight: '1px' }}>
              <CloseIcon />
            </IconButton>
          </Tooltip>

          <div style={{ height: '10px' }} />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDateTimePicker
              orientation="landscape"
              // disablePast="false"
              onChange={(newValue) => setCurPickTime(newValue)} />
          </LocalizationProvider>
          
          <Button
            variant="contained"
            color="primary"
            className="sendMail-send !px-6"
            style={{ height: '50px' }}
            onClick={() => { scheduleSendClick(); }}>
            <ScheduleSendIcon/>
            <div style={{paddingLeft:'10px'}}> Schedule Send </div>
          </Button>
        </div>
      </div>
      }
    </div>
  );
}

export default SendMailM;
