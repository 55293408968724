
import { useState } from 'react';
import AuthContext from '../contexts/authContext';
import jwt_decode from "jwt-decode";
import { getStorage } from '../cross-domain-storage/storage';

const TOKEN = "unimailToken";
const storage = getStorage();

export default function AuthProvider(props) {
	const [token, setToken] = useState(storage.getItem(TOKEN));
	const [quota, setQuota] = useState({ relative: 0 });
    const [login, setLogin] = useState(false);
	const [address, setAddress] = useState('');
	const [profile, setProfile] = useState(0);

	const nextYear = () => {
		const now = new Date();
		now.setFullYear(now.getFullYear() + 10);
		return now;
	}

    const setAuthToken = (token) => {
		storage.setItem(TOKEN, token, {expires: nextYear()})
        setToken(token);
    }

	const isLogin = () => {
		if (token === null || token === "" || token === "undefined" || token === "..." || token === undefined)
			return false;
		return true;
	}

	const logout = () => {
		setToken(null);
		storage.removeItem(TOKEN);
	}

	const getAddress = () => {
		if (!isLogin())
			return "";
		return jwt_decode(token).address;
	}

	const getPassword = () => {
		if (!isLogin())
			return "";
		return jwt_decode(token).password;
	}

    const getAccount = () => {
        if (token === null || token === "" || token === "undefined" || token === "..." || token === undefined)
            return "";
        const account = jwt_decode(token);
        return account.first_name + " " + account.last_name;
    }

	let context = {
		token,
		setToken: setAuthToken,
		isLogin,
		logout,
		getAddress,
		getPassword,
		getAccount,
        quota,
        setQuota,
		address,
		setAddress,
		profile,
		setProfile
	};

	return (
		<AuthContext.Provider value={context}>
			{props.children}
		</AuthContext.Provider>
	);
};