
import { Card, Select, MenuItem } from '@mui/material';
import { Link } from '@material-ui/core';

const languages = [
  {value: "en", label: "English"},
  {value: "fr", label: "French"},
  {value: "ar", label: "Arabic"},
]

export default function Container(props) {
  return (
    <div className="sm:m-auto sm:p-4">
        <Card variant="outlined" className="min-h-[500px] px-10 pt-12 pb-9">
            {props.children}
        </Card>
        <div className="pt-6 pb-6 sm:pb-0 w-full px-4 sm:px-0 grid grid-cols-2">
          <div>
            {/* <Select
              variant="standard"
              disableUnderline
              value="en"
              sx={{fontSize: "small"}}
            >
              {
                languages.map((item, i) => {
                  return <MenuItem key={i} value={item.value} sx={{fontSize: "small"}}>
                    {item.label}
                  </MenuItem>
                })
              }
            </Select> */}
          </div>
          <div className="text-right">
            <Link href="/help" target="_blank" color="inherit" underline="none" style={{fontSize: "small"}} className="pr-2">Help</Link>
            <Link href="/privacy" target="_blank" color="inherit" underline="none" style={{fontSize: "small"}} className="pr-2">Privacy</Link>
            <Link href="/terms" target="_blank" color="inherit" underline="none" style={{fontSize: "small"}} >Terms</Link>
          </div>
      </div>
    </div>
  );
}