
import { useState, useEffect } from 'react';
import MenuContext from '../contexts/menuContext';

import Detail from './Menus/Detail';
// import ResponseType from './Menus/ResponseType';
import Apps from './Menus/Apps';
import Filter from './Menus/Filter';
import RightContextMenu from './Menus/RightContextMenu';
import Profile from './Menus/Profile';
import Help from './Menus/Help/Help';
import { WriteMailContextProvider } from '../Components/SendMail/ContextProvider';

export default function MenuProvider(props) {
    
	const [rightContextPos, setRightContextPos] = useState({ top: 0, left: 0 });
	const [rightContextItem, setRightContextItem] = useState({});
	const [rightContext, setRightContext] = useState(false);

	//---for each mail arrowdown button hover---
	const [detail, setDetail] = useState(false);
	const [detailPos, setDetailPos] = useState({top : 0, left : 0});
	const [detailItem, setDetailItem] = useState(null);

	// const [responseType, setResponseType] = useState(false);
	// const [responseTypePos, setResponseTypePos] = useState({top : 0, left : 0});
	
	const [profilePos, setProfilePos] = useState({ top: 0, left: 0 });
	const [profile, setProfile] = useState(false);
	const [appsPos, setAppsPos] = useState({ top: 0, left: 0 });
	const [apps, setApps] = useState(false);
	const [filterPos, setFilterPos] = useState({ top: 0, left: 0 });
	const [filter, setFilter] = useState(false);
	const [helpPos, setHelpPos] = useState({ top: 0, left: 0 });
	const [help, setHelp] = useState(false);
	const [widthFilter, setWidthFilter] = useState(0);
	const [path, setPath] = useState('');

	const showRightContextMenu = (item, e) => {
		setRightContextPos({top: e.pageY, left: e.pageX});
		setRightContextItem(item);
		setRightContext(true);

        e.preventDefault();
		e.stopPropagation();
	}

	const showApps = (e) => {
		const rect = e.currentTarget.getBoundingClientRect();
		setAppsPos({top: rect.top + rect.height, left: rect.left});
		setApps(true);

        e.preventDefault();
	}

	const showFilter = (e, width, left, path) => {
		const rect = e.currentTarget.getBoundingClientRect();
		setFilterPos({top: rect.top + rect.height, left: left});
		setWidthFilter(width);
		setPath(path);
		setFilter(true);

        e.preventDefault();
	}

	const showDetail = (item, e) => {
		const rect = e.currentTarget.getBoundingClientRect();
		setDetailPos({top: rect.top + rect.height, left: rect.left});
		setDetail(true);
		setDetailItem(item);
		e.preventDefault();
	}

	// const showResponseType = (e) => {
	// 	const rect = e.currentTarget.getBoundingClientRect();
	// 	setResponseTypePos({top: rect.top + rect.height, left: rect.left - rect.width});
	// 	setResponseType(true);
	// 	e.preventDefault();
	// }

	const showProfile = (e) => {
		const rect = e.currentTarget.getBoundingClientRect();
		setProfilePos({top: rect.top + rect.height, left: rect.left});
		setProfile(true);

        e.preventDefault();
	}

	const showHelp = (e) => {
		const rect = e.currentTarget.getBoundingClientRect();
		setHelpPos({top: rect.top + rect.height, left: rect.left});
		setHelp(true);

        e.preventDefault();
	}

	let context = {
		// showNewMenu: showNewMenu,
		showApps: showApps,
		showFilter: showFilter,
		showRightContextMenu: showRightContextMenu,
		showProfile: showProfile,
		showDetail: showDetail,
		// showResponseType: showResponseType,
		showHelp: showHelp
	};

	return (
		<WriteMailContextProvider>
			<MenuContext.Provider value={context}>
				{props.children}
				{/* <NewMenu pos={newMenuPos} show={newMenu} setShow={setNewMenu} /> */}
				<Apps pos={appsPos} show={apps} setShow={setApps} />
				<Filter pos={filterPos} width={widthFilter} path={path} show={filter} setShow={setFilter} />
				<Help pos={helpPos} show={help} setShow={setHelp} />
				<Detail item={detailItem} pos={detailPos} show={detail} setShow={setDetail} />
				{/* <ResponseType pos={responseTypePos} show={responseType} setShow={setResponseType} /> */}
				<RightContextMenu pos={rightContextPos} show={rightContext} item={rightContextItem} setShow={setRightContext} />
				<Profile pos={profilePos} show={profile} setShow={setProfile} />
			</MenuContext.Provider>
		</WriteMailContextProvider>
	);
};