export const UPDATE_RECEIVERS = 'updateReceivers';
export const UPDATE_CCS = 'updateCcs';
export const UPDATE_BCCS = 'updateBccs';
export const UPDATE_FROMS = 'updateFroms';
export const UPDATE_TOS = 'updateTos';
export const UPDATE_SUBJECT = 'updateSubject';
export const UPDATE_TEXT = 'updateText';
export const UPDATE_FILES = 'updateFiles';
export const UPDATE_INIT = 'init';
export const RESERVATION_MODAL_ON = 'modalOn';
export const RESERVATION_MODAL_OFF = 'modalOff';
export const UPDATE_DATE = 'updateDate';
