import { Checkbox, IconButton } from "@material-ui/core";
import React, {useState, useContext, useRef} from "react";
import "./EmailRow.css";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import LabelImportantOutlinedIcon from "@material-ui/icons/LabelImportantOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
////////// for test of spam /////////
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
/////////////////////////////
import { useNavigate } from "react-router-dom";
import { selectMail } from "../../features/mailSlice";
import { useDispatch } from "react-redux";
import LoadingContext from '../../contexts/loadingContext';
import moment from "moment";
import { setStatusMail } from '../../apis';
import AuthContext from '../../contexts/authContext';
import SearchContext from '../../contexts/searchContext';
import { FileIcon, defaultStyles } from 'react-file-icon';

function EmailRowM({ item, path, onSetStatusMail, onChangeSelectedRow }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingCtx = useContext(LoadingContext);
  const searchCtx = useContext(SearchContext);
  const authCtx = useContext(AuthContext);
  
  const handleChangeStateClick = (e) => {
    onChangeSelectedRow(item.message_id, e.target.checked);
    setIsHovering(e.target.checked);
  };

  const openMail = () => {
    dispatch(
      selectMail({
        id: item._id,
        from: item.from,
        to: item.to,
        cc: item.cc,
        bcc: item.bcc,
        subject: item.subject,
        text: item.message,
        html: item.html,
        category: item.category,
        message_id: item.message_id,
        in_reply_to: item.in_reply_to,
        reference: item.reference,
        attach: item.attach,
        alias_main: item.alias_main,
        multi_sent: item.multi_sent,
        time: moment(item.date).format("lll"),
        path: path
      })
    );
    onSetStatusRow('is_read', 1, true);
    navigate("/mail");
  };

  const onSetStatusRow = (field, value, onlyunreadcount=0) => {
    
    setStatusMail(authCtx.token, item.message_id, field, value)
    .then(function(res) { 
      if (res.result) {
        console.log("Document successfully updated!");
        onSetStatusMail(onlyunreadcount);
        // result.data = { ...result.data, selected: false};
      }
    })
    .catch(function(error) {
        console.error("Error updating document: ", error);
        onSetStatusMail(onlyunreadcount);
    }); 
    
  };

  const renderRowActions = () => {
    let render = <></>
    if (path === "inbox" || path === "sent" || path === "spam") {
      render = 
        <>
          <IconButton style={{ padding: '0px', paddingBottom: '0px' }} onClick={() => onSetStatusRow('is_trashed', 1)} >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </>
    } else if (path === "trash") {
      render = 
        <>
          <IconButton style={{ padding: '0px', paddingBottom: '0px' }} onClick={() => onSetStatusRow('is_trashed', 0)} >
            <RestoreFromTrashOutlinedIcon />
          </IconButton>
        </>
    }
    return <div>          
      { render }
      {
        item.read?(
          <IconButton style={{ paddingTop: '0px', paddingBottom: '0px' }} onClick={() => onSetStatusRow('is_read', 0)} >
            <MarkunreadOutlinedIcon />
          </IconButton>
        ):(
          <IconButton style={{ paddingTop: '0px', paddingBottom: '0px' }} onClick={() => onSetStatusRow('is_read', 1)} >
            <DraftsOutlinedIcon />
          </IconButton>
        )
      }           
    </div>  
  }

  function classNames(...args) {
    return args.filter(Boolean).join(' ')
  }

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const renderAttachments = () => {
    const attachments = JSON.parse(item.attach);    
    if (Array.isArray(attachments) && attachments.length > 0){
      let attach_counts = 0;
      attachments.map(element => {  
        if (element.hasOwnProperty('filename'))
          attach_counts++;
      });
      return (attach_counts > 0)?<div className="emailRow-attach-mobile"><AttachmentIcon /></div>:''
    }        
  }

  var div = document.createElement("div");
  div.innerHTML = item.message.trim();
  var message_text = div.textContent || div.innerText || "";

  return (
    <div className={classNames('emailRow-mobile', item.selected && "emailRow-selected")} >
      <div className="emailRow-options">
        <Checkbox 
          style={{ paddingTop: '0px', paddingBottom: '0px', alignItems: 'flex-start' }}
          checked={item.selected}
          onChange={handleChangeStateClick}
        />        
      </div>
      <div onClick={openMail} className="emailRow-title-mobile">
        <div className={classNames('emailRow-subject-mobile', "emailRow-message-mobile", item.read && "emailRow-main-read")}>
          {item.title}
        </div>
        <div className="emailRow-message-mobile">
          {item.subject}
        </div>
        <div className="emailRow-message-mobile">
          {message_text}
        </div>
      </div>
      { 
        isHovering?
        renderRowActions():
        <div className="emailRow-right-mobile">
          <div>{ renderAttachments() }</div>
          <div></div>
          <div onClick={openMail} className={classNames("emailRow-time-mobile", item.read && "emailRow-time-read-mobile")}>
            {moment(item.date).startOf('seconds').fromNow()}  {/* fromNow(true) */}
          </div>
        </div>
      }      
    </div>
  );
}

export default EmailRowM;
