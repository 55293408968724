
import { useContext, useState } from 'react';

import { Dialog, DialogTitle, Button, Tabs, Tab, Box, Divider } from '@mui/material';

import MyDrive from "./MyDrive";
import SharedWithMe from './SharedWithMe';
import Recent from './Recent';
import DialogContext from '../../../contexts/dialogContext';
import Upload from './Upload';
import Folder from './Folder';
import { url } from './apis';

export default function Rename({show, setShow, cb, viewVal}) {
    const dialogCtx = useContext(DialogContext);
    const [tab, setTab] = useState(viewVal);

    const handleCreate = () => {
        setShow(false);
        cb(`${url}/#/file/${dialogCtx.driveDetails.id}`, dialogCtx.driveDetails.name);
    }
    const handleClose = () => {
        setShow(false);
    };

    const handleSwitchTab = (e, val) => {
        dialogCtx.setDriveDetails(null);
        dialogCtx.setDriveFolder("");
        setTab(val)
    }

    const uploadDone = (name, id) => {
        setShow(false);
        cb(`${url}/#/file/${id}`, name);
    }
    
    return <Dialog
        open={show}
        onClose={handleClose}
        sx={{
            "& .MuiDialog-paper": {
                maxWidth: "720px"
            }
        }}
        >
        <DialogTitle>
            Insert files using Uni Drive
        </DialogTitle>
        <Box className="px-6 w-[720px]">
            <Tabs
                value={tab}
                onChange={handleSwitchTab}
                sx={{
                    "& .MuiTab-root": {
                        textTransform: "none"
                    },
                    "& .Mui-selected": {
                        fontWeight: "bold"
                    }
                }}
            >
                <Tab className="!mr-4" value={0} label="My Drive" />
                <Tab className="!mr-4" value={1} label="Shared with Me" />
                <Tab className="!mr-4" value={2} label="Recent" />
                <Tab value={3} label="Upload" />
            </Tabs>
            <Divider />
            {dialogCtx.driveFolder !== "" ?
                <Folder setTab={handleSwitchTab} /> :
                <>
                    {tab === 0 && <MyDrive />}
                    {tab === 1 && <SharedWithMe />}
                    {tab === 2 && <Recent />}
                    {tab === 3 && <Upload cb={uploadDone} />}
                </>
            }
        </Box>
        <Box className="flex px-6 py-4">
            <Button variant="contained" onClick={handleCreate} sx={{textTransform: "none"}} disabled={dialogCtx.driveDetails === null || dialogCtx.driveDetails.isFolder === true}>
                Insert
            </Button>
            <Button variant="filled" onClick={handleClose} sx={{textTransform: "none"}}>
                Cancel
            </Button>
            <Box className="ml-auto" />
        </Box>
    </Dialog>
}
