
import React, { useState } from 'react';
import { Button, Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
}));
const AddDomainTest = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const classes = useStyles();
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('domain', 'test.com');
    formData.append('description', 'test');
    formData.append('file', selectedFile);
    formData.append('fileName', fileName); // You can add additional file metadata here as needed
    axios.post('http://localhost:8080/api/v1/rest/add_domain', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={handleFileSelect}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Select File
            </Button>
          </label>
          <TextField
            variant="outlined"
            fullWidth
            margin="normal"
            label="Selected File"
            value={fileName}
            InputProps={{
              readOnly: true,
            }}
          />
          <Button variant="contained" color="primary" type="submit">
            Upload File
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};
export default AddDomainTest;