

import { Popover, Box, Typography, Link, Button } from '@mui/material';
import { Link as Link1 } from '@material-ui/core';
import { Avatar, OutlinedButton } from "../../Components";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useContext } from "react";
import AuthContext from '../../contexts/authContext';

export default function Apps({pos, show, setShow}) {
    const authCtx = useContext(AuthContext);

    const handleClose = (e) => {
        setShow(false);
        e.preventDefault();
    };

    return <Popover
        sx={{
            "& .MuiPaper-root": {
                width: 400,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 2,
                padding: 1
            }
        }}
        anchorReference="anchorPosition"
        anchorPosition={pos}
        open={show}
        disableEnforceFocus={true}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        onContextMenu={handleClose}        
    >
        <Link href="https://drive.unimail.in" target="_blank" underline="none" className="flex flex-col justify-center items-center p-2">
            <img className="p-3 h-[64px]" src="/drive-logo.png" alt="drive" onClick={()=>{setShow(false)}} />
            <Typography className="!text-sm !text-neutral-800">Drive</Typography>
        </Link>    
        <Link href="https://docs.unimail.in/writer" target="_blank" underline="none" className="flex flex-col justify-center items-center p-2">
            <img className="p-3 h-[64px]" src="/doc-logo.svg" alt="docs" onClick={()=>{setShow(false)}} />
            <Typography className="!text-sm !text-neutral-800">Docs</Typography>
        </Link>    
        <Link href="https://docs.unimail.in/spreadsheet" target="_blank" underline="none" className="flex flex-col justify-center items-center p-2">
            <img className="p-3 h-[64px]" src="/sheet-logo.svg" alt="sheets" onClick={()=>{setShow(false)}} />
            <Typography className="!text-sm !text-neutral-800">Sheets</Typography>
        </Link>    
        <Link href="https://docs.unimail.in/ppt" target="_blank" underline="none" className="flex flex-col justify-center items-center p-2">
            <img className="p-3 h-[64px]" src="/slide-logo.svg" alt="slides" onClick={()=>{setShow(false)}} />
            <Typography className="!text-sm !text-neutral-800">Slides</Typography>
        </Link>
        <Link1 href="/migration" underline="none" color="inherit"  className="flex flex-col justify-center items-center p-2" target="_blank">
            <ImportExportIcon className="p-3 !h-[64px] !w-[64px]" onClick={()=>{setShow(false)}} />
            <Typography className="!text-sm !text-neutral-800">Migrate</Typography>
        </Link1>  
    </Popover>
}