
import { useState } from 'react';
import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import DialogContext from '../contexts/dialogContext';

import CloseIcon from '@mui/icons-material/Close';

import InsertDrive from './Modals/InsertDrive';
import FileViewer from './Modals/FileViewer';
export default function MenuProvider(props) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const action = snackbarId => (
		<IconButton onClick={() => closeSnackbar(snackbarId)} sx={{color: 'inherit'}}>
			<CloseIcon />
		</IconButton>
	);

	const [isShowDetail, showDetail] = useState(false);

	const [insertDrive, setInsertDrive] = useState(false);
	const [insertDriveCb, setInsertDriveCb] = useState(() => {});
	const [driveDetails, setDriveDetails] = useState(null);
	const [driveFolder, setDriveFolder] = useState("");

	/*------------For File Viewer----------*/
	const [isFileViewer, setFileViewer] = useState(false);
	const [fileName, setFileName] = useState({});
	const [fileId, setFileId] = useState(null);
	const [oid, setOid] = useState(null);

	/*-----*/
	const [initFileList, setInitFileList] = useState([]);
	const [viewVal, setViewVal] = useState(0);

	const showToast = (message) => {
		enqueueSnackbar(message, {
			action,
			autoHideDuration: 3000,
		});
	}

	const showInsertDrive = (cb, viewMode = 0) => {
		setInsertDriveCb(() => cb);
		setInsertDrive(true);
		setDriveDetails(null);
		setDriveFolder("");
		setViewVal(viewMode);
	}

	const showFileViewer = (oid, filename, fileid) => {
		setOid(oid);
		setFileName(filename);
		setFileId(fileid);
		setFileViewer(true);
	}

	let context = {
		showDetail: showDetail,
		isShowDetail: isShowDetail,
		showToast: showToast,
		showInsertDrive,
		driveDetails,
		setDriveDetails,
		driveFolder,
		setDriveFolder,
		showFileViewer,
		initFileList,
		setInitFileList,
		viewVal
	};

	return (
		<DialogContext.Provider value={context}>
			{props.children}
			{insertDrive&&
			<InsertDrive show={insertDrive} setShow={setInsertDrive} cb={insertDriveCb} viewVal={viewVal}/>
			}
			{isFileViewer&&
			<FileViewer show={isFileViewer} setShow={setFileViewer} oid={oid} filename={fileName} fileid={fileId}/>
			}
		</DialogContext.Provider>
	);
};