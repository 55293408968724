
import { useState } from 'react';
import SearchContext from '../contexts/searchContext';

export default function SearchProvider(props) {
	const [search, setSearch] = useState({
		from: '',
        to: '',
        subject: '',
        hasWords: '',
        doesntHave: '',
        // size: '',
        // dateWithin: '',
        in: "all",
        hasAttachment: false,
	});
	const [input, setInput] = useState('');

	let context = {
		search,
		setSearch,
		input,
		setInput
	};

	return (
		<SearchContext.Provider value={context}>
			{props.children}			
		</SearchContext.Provider>
	);
};