
import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material"
import DownloadIcon from "@mui/icons-material/Download";
import { url } from "../../../../@axios";
import { downLoad, actionDownload } from "../../../../apis";
import LoadingContext from "../../../../contexts/loadingContext";
import AuthContext from "../../../../contexts/authContext";
import fileDownload from "js-file-download";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

export const getFileExt = (str) => {
    return str.split('.').pop();
}

const images = ["png", "jpg", "jpeg", "bmp", "tiff", "svg"];
const google = ["doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "pdf"];
const videos = ["mp4", "avi", "wmv", "mpg"];
const audios = ["mp3", "ogg", "wav", "3gp"];

export default function PreviewItem({oid, filename, fileid}) {
    const loadingCtx = useContext(LoadingContext);
    const authCtx = useContext(AuthContext);

    const [fileBuffer, setFileBuffer] = useState(null);
    const [docs, setDocs] = useState([]);
    const [fileUrl, setFileUrl] = useState(null);

    function getFileTypeFromFileName(fileName) {
        const extension = fileName.split('.').pop();
        
        switch (extension.toLowerCase()) {
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'pdf':
                return 'application/pdf';
            case 'png':
                return 'image/png';
            case 'jpg':
                return 'image/jpg';
            case 'jpeg':
                return 'image/jpeg';
            case 'gif':
                return 'image/gif';
            case 'txt':
                return 'text/plain';
            case 'doc':
                return 'application/msword';
            case 'docx':
                return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'pptx':
                return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
            default:
                return 'application/octet-stream';
        }
    }

    useEffect(() => {
        fetch(getUrl())
        .then(response => response.arrayBuffer())
        .then(buffer => setFileBuffer(buffer))
        .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        if (fileBuffer) {
          const fileBlob = new Blob([fileBuffer], { type: getFileTypeFromFileName(filename) });
          const _fileUrl = URL.createObjectURL(fileBlob);
          setFileUrl(_fileUrl);
          setDocs([{uri : _fileUrl, fileName : filename}]);
        }
    }, [fileBuffer]);

    const ext = filename === null ? "" : getFileExt(filename);

    const getUrl = () => {
        let query = {
            token: authCtx.token,
            oid: oid,
            fileid: fileid
        };
        query = JSON.stringify(query);
        return `${url}/action_download/?param=${encodeURIComponent(query)}`;
    }

    const inArray = (arr, val) => {
        for (let i = 0; i < arr.length; i ++) {
            if (arr[i] === val)
                return true;
        }
        return false;
    }

    if (filename === null)
        return "";
/*     if (inArray(images, ext))
        return <Box className="bg-center bg-contain bg-no-repeat w-full h-full" style={{backgroundImage: `url(${getUrl()})`}} /> */
    if (inArray(google, ext))
        return (
        //<embed className="w-full h-full" src={`http:///viewerng/viewer?embedded=true&url=${getUrl()}`}/>
          <iframe className="w-full h-full" src={`https://docs.google.com/viewer?url=${encodeURIComponent(getUrl())}&embedded=true`} frameBorder="0"/>
        )
    if (inArray(videos, ext))
        return <video className="w-full h-full" controls autoPlay>
            <source src={getUrl()} type={`video/${ext}`} />
            Your browser does not support HTML5 video.
        </video>
    if (inArray(audios, ext))
        return <audio className="w-full my-auto" controls autoPlay>
            <source src={getUrl()} type={`audio/${ext}`} />
        </audio>
    return <DocViewer documents={docs} prefetchMethod="GET" pluginRenderers={DocViewerRenderers} config={{ header: { disableHeader: true } }}/>;
    /* return <Box className="m-auto text-center w-[300px] rounded-[12px] p-5 bg-[#202124d0]" style={{boxShadow: "0px 10px 12px 5px rgb(0 0 0 / 20%)"}}>
        <Typography className="pb-4" color="white">
            No preview available
        </Typography>
        <Button variant="contained" sx={{textTransform: "none"}} onClick={handleDownload} startIcon={<DownloadIcon />}>
            Download
        </Button>
    </Box> */
}