import { Box, Checkbox, IconButton, Button } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./EmailList.css";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import RefreshIcon from '@mui/icons-material/Refresh';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
////////// for test of spam /////////
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
/////////////////////////////
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import SettingsIcon from "@material-ui/icons/Settings";
import InboxIcon from "@material-ui/icons/Inbox";
import PeopleIcon from "@material-ui/icons/People";
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Section from "../Section/Section";
import EmailRow from "../EmailRow/EmailRow";
import LoadingContext from '../../contexts/loadingContext';
import { setStatusMail, deleteDraftMail } from '../../apis';
import AuthContext from '../../contexts/authContext';
import SearchContext from "../../contexts/searchContext";
import Tooltip from '@mui/material/Tooltip';

function EmailList({ _emails, _mailpath, path, cat, getMainMails, total, perpage }) {
  const navigate = useNavigate(); 
  const loadingCtx = useContext(LoadingContext);
  const searchCtx = useContext(SearchContext);
  const authCtx = useContext(AuthContext);
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedSome, setSelectedSome] = useState(false);
  const [emails, setEmails] = useState(_emails);
  const [mailpath, setMailpath] = useState(path);

  useEffect(() => {
    setEmails(_emails);
    setMailpath(_mailpath);
  }, [_emails])

  useEffect(() => {
   
    let isSelectedAll = true;
    let isSelectedSome = false;
    if (emails.length > 0) {
      for(let item of emails) {
        if (!item.selected)
          isSelectedAll = false;
        if (item.selected)
          isSelectedSome = true;
      } 
    } else {
      isSelectedAll = false;
      isSelectedSome = false;
    }       
    setSelectedAll(isSelectedAll);
    setSelectedSome(isSelectedSome);
    
  }, [emails])

  const onChangeSelectedRow = (id, checked) => {
    setEmails(emails.map((item) => {
      if (path === 'drafts') {
        if(item._id === id)
          item.selected = checked;
      } else {
        if(item.message_id === id)
          item.selected = checked;
      }
      
      return item;
    }));   
    
  };

  const onSelectedAllChange = (event) => {    
    setEmails(emails.map((item) => {
      item.selected = event.target.checked;
      return item;
    }));
    
  };

  const onSetStatusMail = (onlyunreadcount=0) => {
    
    getMainMails(path, onlyunreadcount);
  }

  const onSetStatusMails = (field, value) => {
    
    emails.map((item) => {      
      if(item.selected) {            
        setStatusMail(authCtx.token, item.message_id, field, value)
        .then(function(res) { 
          if (res.result) {
            console.log("Document successfully updated!");    
            getMainMails(path);        
          }
        })
        .catch(function(error) {
          console.error("Error updating document: ", error);
          getMainMails(path);
        });      
      }
    });
    
  };

  const onRefreshAll = () => {
    
    getMainMails(path);
  };

  const onPrev = () => {
    
    loadingCtx.setCurpage(loadingCtx.curpage - 1);
  };

  const onNext = () => {
    
    loadingCtx.setCurpage(loadingCtx.curpage + 1);
  };

  const onSetStatusTrashed = () => {
    emails.map((item) => {      
      if(item.selected) { 
        deleteDraftMail({token: authCtx.token, oid: item._id})
        .then((res) => {
          // console.log(res);
          
          if(res.result) {
            console.log('draft message deleted with close', res.data);               
          } else {
            console.log('draft message deleting failed with close');
          }        
          getMainMails(path);               
        })
        .catch((e) => { console.log(e) });
      }
    });
  }

  const renderSelectedActions = () => {
    let render = <></>
    if (path === "inbox" || path === "sent" || path === "starred" || path === "important") {
      render = 
        <>
          <Tooltip title="Delete" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_trashed', 1)} >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Report spam" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_spam', 1)} >
              <ReportGmailerrorredOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
    }
    else if (path === "spam") {
      render = 
        <>
          <Tooltip title="Delete forever" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_deleted', 1)} >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Not spam" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_spam', 0)} >
              <ReportOffOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
    }
    else if (path === "trash") {
      render = 
        <>
          <Tooltip title="Delete forever" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_deleted', 1)} >
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Report spam" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_spam', 1)} >
              <ReportGmailerrorredOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
    }
    else if (path === "drafts") {
      render = 
        <Tooltip title="Delete" placement="bottom">
          <IconButton onClick={() => onSetStatusTrashed()} >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Tooltip>
    }
    return <>          
      { render }
      {
        (path !== 'drafts' && path !== "scheduled") &&   
        <>   
          <Tooltip title="Mark as unread" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_read', 0)} >
              <MarkunreadOutlinedIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Mark as read" placement="bottom">
            <IconButton onClick={() => onSetStatusMails('is_read', 1)} >
              <DraftsOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>  
      }
    </>
  }

  return (
    <Box className="emailList w-full grid">
      <Box className="emailList-settings pt-1">
        <div className="emailList-settingsLeft">
          <Tooltip title="Select" placement="bottom">
            <Checkbox checked={selectedAll} onChange={onSelectedAllChange} style={{padding: '9px !important'}} />  
          </Tooltip>         
          { selectedSome &&  renderSelectedActions() }          
          <Tooltip title="Refresh" placement="bottom">
            <IconButton onClick={onRefreshAll}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
        <div className="emailList-settingsRight">
          <div className="flex">
            <p className="mr-[10px] mt-[10px] text-sm" style={{minHeight: '20px', minWidth: '42px'}}>{(emails.length>0)?(((loadingCtx.curpage-1)*perpage+1).toString()+'-'+((loadingCtx.curpage*perpage>total)?total:loadingCtx.curpage*perpage).toString()+' of '+total):''}</p>  
            <Tooltip title="Newer" placement="bottom">
              <span>
                <IconButton onClick={() => onPrev()} disabled={(loadingCtx.curpage > 1)?false:true}>
                  <ChevronLeftIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title="Older" placement="bottom">            
              <span>
                <IconButton onClick={() => onNext()} disabled={(total > loadingCtx.curpage * perpage)?false:true}>
                  <ChevronRightIcon />
                </IconButton>
              </span>  
            </Tooltip>
          </div>                
        </div>        
      </Box>
      <Box className="emailList-sections">
        {(mailpath === "inbox")?
        (<>
          <Button className="emailList-section-button" onClick={() => {loadingCtx.setCategory(0); loadingCtx.setCurpage(1); navigate("/primary")}} 
          disableRipple
          >
            <Section Icon={InboxIcon} title="Primary" className="margin" color="#1A73E8" selected={loadingCtx.category===0?true:false} />
          </Button>
          <Button className="emailList-section-button" onClick={() => {loadingCtx.setCategory(1); loadingCtx.setCurpage(1); navigate("/promotions")}} 
          disableRipple
          >
            <Section Icon={LocalOfferIcon} title="Promotions" color="#1A73E8" selected={loadingCtx.category===1?true:false} />
          </Button>
          <Button className="emailList-section-button" onClick={() => {loadingCtx.setCategory(2); loadingCtx.setCurpage(1); navigate("/social")}} 
          disableRipple
          >
            <Section Icon={PeopleIcon} title="Social" color="#1A73E8" selected={loadingCtx.category===2?true:false} />
          </Button>
          <Button className="emailList-section-button" onClick={() => {loadingCtx.setCategory(3); loadingCtx.setCurpage(1); navigate("/updates")}} 
          disableRipple
          >
            <Section Icon={InfoIcon} title="Updates" color="#1A73E8" selected={loadingCtx.category===3?true:false} />
          </Button>
        </>):""}
      </Box>
      <Box className="flex overflow-y-hidden">
        <Box className="emailList-list w-full overflow-y-auto pb-4">
        {(emails.length > 0)?emails.map((item) => (
          <EmailRow
            key={item._id}
            item={item}
            path={path}
            onSetStatusMail={onSetStatusMail}
            onChangeSelectedRow={onChangeSelectedRow}            
          />
        )):(<div className="flex justify-center"><p>No messages</p></div>)}
        </Box>
      </Box>
      
    </Box>
  );
}

export default EmailList;
