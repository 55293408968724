
import { useState, useRef, useContext, useEffect } from "react";
import { Link, Button, Checkbox, FormGroup, FormControlLabel, InputAdornment, FormControl, Select, MenuItem, InputLabel, FormHelperText } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import LoadingContext from '../../contexts/loadingContext';

import { findUser, getDomains } from '../../apis';

const t_firstNameError = "Enter first name";
const t_lastNameError = "Enter last name";
const t_nameError = "Enter first and last names";
const t_addrError = "Choose an unimail address";
const t_addrLengthError = "Sorry, your username must be between 6 and 30 characters long."
const t_addrSymbolError = "Sorry, only letters (a-z), numbers (0-9), and periods(.) are allowd.";
const t_addrFirstCharacterError = "Sorry, the first character of your username must be an ascii letter (a-z) or number (0-9).";
const t_addrDuplicateError = "That username is taken. Try another."
const t_domainError = "Please select your domain";
const t_pwdError = "Enter a password";
const t_pwdLengthError = "Use 8 characters or more for your password";
const t_pwdConfirmError = "Password and confirm password not matching";

export default function Register({onNext, user, setUser}) {
    const loadingCtx = useContext(LoadingContext);

    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const addrRef = useRef(null);
    const domainRef = useRef(null);
    const pwdRef = useRef(null);
    const pwdConfirmRef = useRef(null);

    const [nameError, setNameError] = useState("");
    const [addrError, setAddrError] = useState("");
    const [domainError, setDomainError] = useState("");
    const [pwdError, setPwdError] = useState("");

    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [addr, setAddr] = useState(user.address);
    const [suggestNames, setSuggestNames] = useState([]);
    const [pwd, setPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");

    const [showPwd, setShowPwd] = useState(false);

    const [domains, setDomains] = useState([
        {
            index: 0,
            value: '@unimail.in',
            label: '@unimail.in'
        }
    ]);
    const [domain, setDomain] = useState("@unimail.in");

    useEffect(() => {
        // getDomains()
        // .then((res) => {
        //     if (res.result) {
        //         setDomains(res.domains);
        //         setDomain(res.domains[0].value);
        //     }
        //     else {
        //         setDomains([]);
        //     }
        // })
        // .catch((e) => {});
        
    }, [])

    const handleFindUser = async (address) => {
        const res = await findUser(firstName, lastName, address + domain, "");
        if (res.result) {
            setAddrError(t_addrDuplicateError);
            setSuggestNames(res.suggest);
        }
        else {
            setAddrError("");
            setSuggestNames([]);
        }

        return res.result;
    }

    const onBlurUsername = (e) => {
        const addr = e.target.value;
        handleFindUser(addr);
    }

    const handleSuggestName = (e) => {
        setAddrError("");
        setSuggestNames([]);
        setAddr(e);
    }

    const handleNext = async () => {
        const duplicate = await handleFindUser(addr);
        
        let focusObj = null;
        if (pwd === "") {
            setPwdError(t_pwdError);
            focusObj = pwdRef.current;
        }
        else if (pwd.length < 8) {
            setPwdError(t_pwdLengthError);
            focusObj = pwdRef.current;
        }
        else if (pwd !== confirmPwd) {
            setPwdError(t_pwdConfirmError);
            focusObj = pwdConfirmRef.current;
        }
        else {
            setPwdError("");
        }

        if (addr === "") {
            setAddrError(t_addrError);
            focusObj = addrRef.current;
        }
        else if (addr.length < 1) {
            setAddrError(t_addrLengthError);
            focusObj = addrRef.current;
        }
        else if ((/^[a-zA-Z0-9]+$/.test(addr[0])) === false) {
            setAddrError(t_addrFirstCharacterError);
            focusObj = addrRef.current;
        }
        else if ((/^[a-zA-Z0-9._]+$/.test(addr)) === false) {
            setAddrError(t_addrSymbolError);
            focusObj = addrRef.current;
        }
        else if (duplicate) {
            setAddrError(t_addrDuplicateError);
            focusObj = addrRef.current;
        }
        else {
            setAddrError("");
        }

        if (domain === "") {
            setDomainError(t_domainError);
            focusObj = domainRef.current;
        }
        else {
            setDomainError("");
        }

        if (firstName === "" && lastName === "") {
            setNameError(t_nameError);
            focusObj = firstNameRef.current;
        }
        else if (firstName === "") {
            setNameError(t_firstNameError);
            focusObj = firstNameRef.current;
        }
        else if (lastName === "") {
            setNameError(t_lastNameError);
            focusObj = lastNameRef.current;
        }
        else {
            setNameError("");
        }

        if (focusObj !== null)
            focusObj.focus();
        else {
            loadingCtx.setLoading(true);
            setTimeout(() => {
                loadingCtx.setLoading(false);
                setUser({
                    address: addr + domain,
                    firstName,
                    lastName,
                    password: pwd
                })
                onNext();
            }, 1000);
        }
    }
    
    return (
        <>
            <p className="text-2xl pb-2">Create your Mail Account</p>
            {/* <p className="text-base pb-4">to continue to UniMail</p> */}
            <div className="grid grid-cols-2 gap-4">
                <TextInput
                    label="First name"
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                    error={nameError !== "" && firstName === ""}
                    inputRef={firstNameRef} />
                <TextInput
                    label="Last name"
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    error={nameError !== "" && lastName === ""}
                    inputRef={lastNameRef} />
            </div>
            <FormHelperText error={nameError !== ""} className="pb-2">
                <HelperText error={nameError} />
            </FormHelperText>
            <div className="grid grid-cols-2 gap-4 pb-8">
                <div>
                    <TextInput
                        label="Username"
                        value={addr}
                        onChange={(e) => {
                            setAddr(e.target.value);
                        }}
                        onBlur={onBlurUsername}
                        // InputProps={{
                        //     endAdornment: <InputAdornment position="end">@unimail.in</InputAdornment>,
                        // }}
                        error={addrError !== ""}
                        inputRef={addrRef} />
                    <FormHelperText error={addrError !== ""}>
                        {addrError === "" ?
                            "You can set letters, numbers & periods" :
                            <HelperText error={addrError} />}
                    </FormHelperText>
                    {
                        suggestNames.length === 0 ? "" :
                        <FormHelperText>
                            Available:
                            {
                                suggestNames.map((item, i) => {
                                    return <Link key={i}
                                            component="button"
                                            underline="none"
                                            onClick={() => handleSuggestName(item)}>
                                        <span className="pl-1">{item}</span>
                                    </Link>
                                })
                            }
                        </FormHelperText>
                    }
                </div>
                <div>
                    <FormControl fullWidth>
                        <InputLabel size="small">Domain</InputLabel>
                        <Select
                            value={domain}
                            label="Domain"
                            size="small"
                            inputRef={domainRef}
                            error={domainError !== ""}
                            onChange={(e) => {setDomain(e.target.value)}}
                            SelectProps={{
                                renderValue: (value) => value
                            }}
                        >
                            {
                                domains.map((ele) => {
                                    return <MenuItem key={ele.index} selected={(ele.index === 0)?true:false} value={ele.value}>{ele.label}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                    <FormHelperText error={domainError !== ""}>
                        <HelperText error={domainError} />
                    </FormHelperText>
                </div>
            </div>
            <form className="grid grid-cols-2 gap-4">
                <TextInput
                    label="Password"
                    autoComplete="off"
                    value={pwd}
                    type={showPwd ? "default" : "password"}
                    onChange={(e) => {
                        setPwd(e.target.value);
                    }}
                    error={pwdError !== "" && pwd.length < 8}
                    inputRef={pwdRef} />
                <TextInput
                    label="Confirm"
                    autoComplete="off"
                    value={confirmPwd}
                    type={showPwd ? "default" : "password"}
                    onChange={(e) => {
                        setConfirmPwd(e.target.value);
                    }}
                    error={pwdError !== "" && pwd !== confirmPwd}
                    inputRef={pwdConfirmRef} />
            </form>
            <FormHelperText error={pwdError !== ""}>
                {pwdError === "" ? 
                    "Use 8 or more characters with a mix of letters, numbers & symbols" :
                    <HelperText error={pwdError} />}
            </FormHelperText>
            <div className="pb-16">
                <FormGroup>
                    <FormControlLabel control={<Checkbox value={showPwd} onChange={(e) => {setShowPwd(e.target.checked)}} />} label="Show password" style={{width: 'fit-content'}}/>
                </FormGroup>
            </div>
            <div className="flex">
                <Link href="/signin" underline="none">Sign in instead</Link>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Next</Button>
            </div>
        </>
    );
}