
import { createContext } from 'react';

const MenuContext = createContext({
    showNewMenu: (e) => {},
    showBinMenu: (e) => {},
    showRightContextMenu: (e) => {},
    showProfile: (e) => {},
    showHelp: (e) => {},
    showApps: (e) => {},
    showDetail: (item, e) => {},
});

export default MenuContext;