import moment from 'moment';

const regexsOfType = {
  id: [
    {
      regex: /^.{5,20}$/,
      msg: 'invalid',
    },
    {
      regex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*$/,
      msg: 'invalid',
    },
  ],
  email: [
    {
      regex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}$/,
      msg: 'invalid',
    },
  ],
  password: [{ regex: /^.{8,20}$/, msg: 'invalid' }],
  name: [
    { regex: /^.{1,10}$/, msg: 'invalid' },
    { regex: /^[a-zA-Z]{1,10}$/, msg: 'invalid' },
  ],
};

const validate = (type, value) => {
  const regexs = regexsOfType[type];

  for (const { regex } of regexs) {
    if (!regex.test(value)) {
      return false;
    }
  }
  return true;
};

const validateAndGetMsg = (type, value) => {
  const regexs = regexsOfType[type];

  for (const { regex, msg } of regexs) {
    if (!regex.test(value)) {
      return msg;
    }
  }
  return '';
};

// userInfo: {id, name, password, email}
const checkUser = ({ id, name, password, email }) => {
  const user = {
    id,
    name,
    password,
    email,
  };

  return Object.keys(user).every(key => validate(key, user[key]));
};

const isAfterDate = date => {
  const curDate = moment();

  return curDate < date;
};

const areChackPasswordOrErrorNotEmpty = (checkPassword, checkPasswordError) =>
  checkPassword !== '' || checkPasswordError !== '';

const isEqual = (a, b) => a === b;

const validateCheckPassword = (password, checkPassword, checkPasswordError) =>
  areChackPasswordOrErrorNotEmpty(checkPassword, checkPasswordError) &&
  !isEqual(password, checkPassword);

export default {
  validate,
  validateAndGetMsg,
  validateCheckPassword,
  checkUser,
  isAfterDate,
};
