import { Button, IconButton } from "@material-ui/core";
import React from "react";
import "./Sidebar.css";
import AddIcon from "@material-ui/icons/Add";
import InboxIcon from "@material-ui/icons/Inbox";
import StarIcon from "@material-ui/icons/Star";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import NearMeIcon from "@material-ui/icons/NearMe";
import NoteIcon from "@material-ui/icons/Note";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import DuoIcon from "@material-ui/icons/Duo";
import PhoneIcon from "@material-ui/icons/Phone";

import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import Tooltip from '@mui/material/Tooltip';
import SidebarOption from "./SidebarOption";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openSendMessage, selectOpenMail, selectMail } from "../../features/mailSlice";
import { useSelector } from "react-redux";

import LoadingContext from "../../contexts/loadingContext";

function Sidebar({ counts, active }) {
  const dispatch = useDispatch();
  const selectedMail = useSelector(selectOpenMail);
  const loadingCtx = React.useContext(LoadingContext);

  if(active === 'mail')
    active = selectedMail?.path;
  
  return (
    <div className="sidebar">
      <Button
        className="sidebar-compose"
        onClick={
          () => {
            dispatch(openSendMessage());
            if (selectedMail?.is_draft && selectedMail?.is_draft === '1') {
              dispatch(selectMail(null));
            }
          }
        }
        startIcon={<ModeEditOutlinedIcon fontSize="large" />}
      >
        Compose
      </Button>
      <Tooltip title="Inbox" placement="right">
        <Link to="/" className="sidebar-link" onClick={() => {loadingCtx.setCategory(0);loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
          <SidebarOption
            Icon={InboxOutlinedIcon}
            title="Inbox"
            number={(counts.inbox > 0)?counts.inbox:''}
            selected={(active === "inbox")?true:false}
          />
        </Link>
      </Tooltip>
      <Tooltip title="Starred" placement="right">
        <Link to="/starred" className="sidebar-link" onClick={() => {loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
          <SidebarOption
            Icon={StarBorderIcon}
            title="Starred"
            number={(counts.starred > 0)?counts.starred:''}
            selected={(active === "starred")?true:false}
          />
        </Link>
      </Tooltip>
      <Tooltip title="Important" placement="right">
        <Link to="/important" className="sidebar-link" onClick={() => {loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
          <SidebarOption
            Icon={LabelImportantIcon}
            title="Important"
            number={''}
            selected={(active === "important")?true:false}
          />
        </Link>
      </Tooltip>
      <Tooltip title="Sent" placement="right">
        <Link to="/sent" className="sidebar-link" onClick={() => {loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
          <SidebarOption Icon={SendOutlinedIcon} title="Sent" number={''} selected={(active === "sent")?true:false} />
        </Link>
      </Tooltip>
      <Tooltip title="Scheduled" placement="right" onClick={() => {loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
        <Link to="/scheduled" className="sidebar-link">
          <SidebarOption Icon={ScheduleSendIcon} title="Scheduled" number={(counts.scheduled > 0)?counts.scheduled:''} selected={(active === "scheduled")?true:false} />
        </Link>
      </Tooltip>{/*  */}
      <Tooltip title="Drafts" placement="right" onClick={() => {loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
        <Link to="/drafts" className="sidebar-link">
          <SidebarOption Icon={InsertDriveFileOutlinedIcon} title="Drafts" number={(counts.drafts > 0)?counts.drafts:''} selected={(active === "drafts")?true:false} />
        </Link>
      </Tooltip>
      <Tooltip title="Spam" placement="right" onClick={() => {loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
        <Link to="/spam" className="sidebar-link">
          <SidebarOption Icon={ReportGmailerrorredOutlinedIcon} title="Spam" number={(counts.spam > 0)?counts.spam:''} selected={(active === "spam")?true:false} />
        </Link>
      </Tooltip>
      <Tooltip title="Trash" placement="right" onClick={() => {loadingCtx.setCurpage(1);loadingCtx.setRefreshEmail(loadingCtx.refreshEmail + 1)}}>
        <Link to="/trash" className="sidebar-link">
          <SidebarOption Icon={DeleteOutlineOutlinedIcon} title="Trash" number={''} selected={(active === "trash")?true:false} />
        </Link>  
      </Tooltip>    
    </div>
  );
}

export default Sidebar;
