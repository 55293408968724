import { Typography, Box, Tooltip, useMediaQuery, IconButton } from '@mui/material';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { OutlinedButton } from '../../../../Components';
import { useState, useContext } from 'react';
import "./preview.css";
import { url } from "../../../../@axios";
import AuthContext from "../../../../contexts/authContext";
import LoadingContext from "../../../../contexts/loadingContext";
import fileDownload from "js-file-download";
import { downLoad, openWithLibre, addToAttachInfo } from "../../../../apis";
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import EditIcon from '@mui/icons-material/Edit';
import Grid from '@mui/material/Grid';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DialogContext from '../../../../contexts/dialogContext';
import { Buffer } from 'buffer';

export const getFileExt = (str) => {
    return str.split('.').pop();
}

export const reduceString = (str, len) => {
    if (len === undefined) len = 12;
      if (str.length < len)
          return str;
    return str.substring(0, len - 1) + "...";
}

const google = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];

export default function FileItem(props) {
    const isDesktop = useMediaQuery('(min-width:1024px)');
    const filename = props.fileName;
    const extension = getFileExt(filename);
    const [isHovered, setIsHovered] = useState(false);
    const authCtx = useContext(AuthContext);
    const loadingCtx = useContext(LoadingContext);
    const diagCtx = useContext(DialogContext);

    const handleDownloadClick = async (e) => {
        e.stopPropagation(); 
        // console.log(oid, filename, fileid);
        loadingCtx.setLoading(true); 
        downLoad(authCtx.token, props.oid, props.fileIndex)
        .then((res) => { 
            loadingCtx.setLoading(false);
            fileDownload(res.data, filename);
        })
        .catch((e) => {
            loadingCtx.setLoading(false);
        });
        loadingCtx.setLoading(false);
    };

    const handleUploadToDriveAndDatabase = (e) => {
        e.stopPropagation();
        fetch(getUrl())
        .then(res => res.arrayBuffer())
        .then(buffer => {
            const file = new File([buffer], filename, {
                type: "text/plain",
            });
            diagCtx.setInitFileList([file]);
            diagCtx.showInsertDrive((link, name) => { 
                const parts = link.split("/");
                const lastPart = parts[parts.length - 1];
                addToAttachInfo(authCtx.token, props.oid, props.fileIndex, filename, lastPart).then((res) => {
                    window.open(`https://docs.unimail.in/spreadsheet/${lastPart}`, '_blank', 'noopener,noreferrer');
                })
            }, 3);
        });
    }

    const getUrl = () => {
        let query = {
            token: authCtx.token,
            oid: props.oid,
            fileid: props.fileIndex
        };
        query = JSON.stringify(query);
        return `${url}/action_download/?param=${encodeURIComponent(query)}`;
    }

    const changeViewMode = (e) => {
        e.stopPropagation();
        openWithLibre(authCtx.token, props.oid, props.fileIndex).then((res) => { 
            if(res.success) // if is registered to unidrive
                window.open(`https://docs.unimail.in/spreadsheet/${res.url}`, '_blank', 'noopener,noreferrer');
            else{
                handleUploadToDriveAndDatabase(e);
            }
        })
    }

    const handleUploadToDrive = (e) => {
        e.stopPropagation();
        fetch(getUrl())
        .then(res => res.arrayBuffer())
        .then(buffer => {
            const file = new File([buffer], filename, {
                type: "text/plain",
            });
            diagCtx.setInitFileList([file]);
            diagCtx.showInsertDrive((link, name) => { alert(link); window.open(`https://drive.unimail.in/#/drive/my-drive`, '_blank', 'noopener,noreferrer');}, 3);
        });
    }

    const inArray = (arr, val) => {
        for (let i = 0; i < arr.length; i ++) {
            if (arr[i] === val)
                return true;
        }
        return false;
    }

    return <OutlinedButton
        size="large"
        grey={+true}
        sx={{
            padding: 0,
            display: 'block',
            overflowWrap: 'anywhere',
        }}
        
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        { ...props }>
        <Box
            style={{position : 'relative'}}
            sx={{
                "& svg": {
                    height: isDesktop ? 160 : 96,
                    padding: 0
                }
            }}>
            <FileIcon extension={extension} {...defaultStyles[extension]} width="auto"/>
            <div className={`${isHovered ? "file-download__cover" : "hidden"}`}>
                <div className="flex items-center justify-center gap-[10px] w-[90%] h-full">
                    <div class="flex items-center justify-center">
                        <img className="w-[30px] h-[30px]" src="/light/uni_download.png" alt="Download" onClick={(e) => handleDownloadClick(e)}/>
                    </div>
                    <div class="flex items-center justify-center">
                        <img className="w-[30px] h-[30px]" src="/light/uni_upload.png" alt="Add to Drive" onClick={(e) => handleUploadToDrive(e)}/>
                    </div>
                    {
                        inArray(google, extension) &&
                        <div class="flex items-center justify-center">
                            <img className="w-[30px] h-[30px]" src="/light/uni_edit.png" alt="Edit with Uni Office" onClick={(e) => changeViewMode(e)}/>
                        </div>
                    }
                </div>
            </div>
        </Box>
    </OutlinedButton>
}