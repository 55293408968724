
import { useState } from "react";
import { useNavigate } from 'react-router-dom';

import { Container, UniMail } from '../../Components';
import InputContact from './InputContact';
import InputName from './InputName';
import VerifyCode from './VerifyCode';
import ChooseAccount from './ChooseAccount';
import NoAccount from './NoAccount';

export default function MailRecovery() {
    const navigate = useNavigate();

    const [step, setStep] = useState(0);
    const [contact, setContact] = useState({
        address: "",
        phone: "",
    });
    const [accounts, setAccounts] = useState([]);
    const [verifyData, setVerifyData] = useState({});

    const onNext = () => {
        setStep(step + 1);
    }

    const onBack = () => {
        setStep(step - 1);
    }

    const onStart = () => {
        setStep(0);
    }

    const onError = () => {
        setStep(-1);
    }

    const goToSignIn = (address) => {
        navigate('/signin', {state: {address}});
    }

    const render = () => {
        if (step === 0)
            return <InputContact onNext={onNext} setContact={setContact} />
        else if (step === 1)
            return <InputName onBack={onBack} onNext={onNext} contact={contact} setVerifyData={setVerifyData} onError={onError} />
        else if (step === 2)
            return <VerifyCode onBack={onBack} onNext={onNext} verifyData={verifyData} setAccounts={setAccounts} contact={contact} />
        else if (step === 3)
            return <ChooseAccount goToSignIn={goToSignIn} accounts={accounts} />
        else if (step === -1)
            return <NoAccount onStart={onStart} />
    }

    return (
        <Container>
            <UniMail />
            <div className="sm:w-[370px]">
                {render()}
            </div>
        </Container>
    );
}