
import { useState } from 'react';
import ThemeContext from '../contexts/themeContext';
import { ThemeProvider as BasicThemeProvider, createTheme } from '@mui/material/styles';

const lightTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

const getCurrentTheme = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
export default function ThemeProvider(props) {
	const [theme, setTheme] = useState(getCurrentTheme() ? 'light' : 'light');

    const switchTheme = () => {
        if (isDark())
            setTheme('light');
        else
            setTheme('dark');
    }

    const isDark = () => {
      return theme === 'dark';
    }

    const currentTheme = () => {
      if (isDark())
            return darkTheme;
        else
            return lightTheme;
    }

	let context = {
		theme: theme,
		switchTheme: switchTheme,
    isDark: isDark
	};

	return (
		<ThemeContext.Provider value={context}>
      <BasicThemeProvider theme={currentTheme()}>
        {props.children}
      </BasicThemeProvider>
		</ThemeContext.Provider>
	);
};