
import { useEffect, useState, useRef, useContext } from "react";
import { Link, Box, Button, FormHelperText } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import LoadingContext from '../../contexts/loadingContext';

import { sendSms, verifySmsMailRecovery } from '../../apis';
import { formatPhoneNumber } from '../../utils';

const t_inputError = "Enter a verification code";
const t_invalidError = "Enter valid verification code";
const t_failedError = "Failed to send verify code";

export default function VerifyCode({onBack, onNext, verifyData, setAccounts, contact}) {
    const loadingCtx = useContext(LoadingContext);

    const verifyRef = useRef(null);

    const [error, setError] = useState("");

    const [verifyCode, setVerifyCode] = useState("");
    const [encryptCode, setEncryptCode] = useState("");

    useEffect(() => {
        setEncryptCode(verifyData);
    }, [verifyData]);

    const handleNext = () => {
        if (verifyCode === "") {
            setError(t_inputError);
            verifyRef.current.focus();
        }
        else {
            loadingCtx.setLoading(true);
            setTimeout(() => {
                verifySmsMailRecovery(verifyCode, encryptCode)
                .then((res) => {
                    if (res.result) {
                        setError("");
                        setAccounts(res.data);
                        onNext();
                    }
                    else {
                        setError(t_invalidError);
                        verifyRef.current.focus();
                    }
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {})
            }, 1000);
        }
    }

    const handleRequest = () => {
        loadingCtx.setLoading(true);
        setTimeout(() => {
            sendSms(contact.address, contact.phone, encryptCode)
                .then((res) => {
                    setEncryptCode(res.message);
                    setError("");
                    verifyRef.current.focus();
    
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {
                    setError(t_failedError);
                    verifyRef.current.focus();
    
                    loadingCtx.setLoading(false);
                })
        }, 1000)
    }
    
    return (
        <>
            <p className="text-2xl text-center pb-2">Enter the code</p>
            <p className="text-base text-center pb-8">To help keep your account safe, Unimail wants to make sure it’s really you trying to sign in</p>
            {
                contact.address !== "" ?
                <p className="text-sm pb-8">An email with a verification code was just sent to {contact.address}</p> :
                <p className="text-sm pb-8">A text message with a 6-digit verification code was just sent to {formatPhoneNumber(contact.phone)}</p>
            }
            <Box className="pb-16">
                <TextInput
                    label="Enter the code"
                    value={verifyCode}
                    error={error !== ""}
                    inputRef={verifyRef}
                    size="default"
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleNext();
                        }
                    }}
                    onChange={(e) => {
                        const re = /^[0-9]{0,6}$/;
                        // console.log(e.target.value);
                        // if value is not blank, then test the regex
                        if (e.target.value === '' || re.test(e.target.value)) {
                            setVerifyCode(e.target.value)
                        }
                    }} />
                {
                    error === "" ? "" :
                    <FormHelperText error>
                        <HelperText error={error} />
                        {
                            error === t_inputError ? "" :
                            <Link
                                component="button"
                                underline="none"
                                onClick={handleRequest}>
                                <span className="pl-2">Request a new code</span>
                            </Link>
                        }
                    </FormHelperText>
                }
            </Box>
            <Box className="flex">
                <Button variant="text" sx={{textTransform: "none"}} onClick={onBack}>Back</Button>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Next</Button>
            </Box>
        </>
    );
}