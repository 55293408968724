
import axios, { url as hostUrl } from '../@axios';

export const downLoad = (token, oid, fileid) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/download`, {
				token, oid, fileid 
			}, {
				responseType: 'arraybuffer',
			})
			.then((response) => {
				resolve(response);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const addToAttachInfo = (token, oid, fileid, filename, driveurl) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/addToAttachInfo`, {
				token, oid, fileid, filename, driveurl
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const openWithLibre = (token, oid, fileid) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/openWithLibre`, {
				token, oid, fileid 
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const sendMail = (token, receivers=[], ccs=[], bccs=[], is_draft=0, object_id='', subject, message, html, files=[], schedule='', messageId='', reference='', path='') => {
	const formData = new FormData();

    formData.append('token', token); 
    receivers.forEach(receiver => {
      formData.append('to', receiver);
    }); 
    ccs.forEach(cc => {
		formData.append('cc', cc);
	}); 
	bccs.forEach(bcc => {
		formData.append('bcc', bcc);
	}); 
	formData.append('is_draft', is_draft);
	formData.append('object_id', object_id);
	formData.append('subject', subject); 
    formData.append('text', message); 
    formData.append('html', html); 
	formData.append('scheduled', schedule); 
	formData.append('messageId', messageId);
    formData.append('reference', reference);
    formData.append('path', path);

    files.forEach(file => {
      formData.append('attachments', file);
    });
    
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/send_mail`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getMails = ({ token, filter="", path, cat=0, curpage=1 }) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_mails`, {
				token, filter, path, cat, curpage
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getUnreadCounts = ({ token }) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_unread_counts`, {
				token
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const setStatusMail = (token, id, field, value) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/set_status_mail`, {
				token, mid: id, field, value
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const syncImap = (token) => {
	
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/sync_imap`, {
				token
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getUsersFromMails = (query, limit, token) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_users_from_mails?query=${query}&limit=${limit}&token=${token}`)
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getDetails = ({ token, message_id }) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_details`, {
				token, message_id
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getDraftMail = ({ token, oid }) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_draft_mail`, {
				token, oid
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const deleteDraftMail = ({ token, oid }) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/delete_draft_mail`, {
				token, oid
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

