import React, { useRef, useEffect, useState, useContext } from 'react';
import { Box } from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import * as WM_S from '../styled';
import validator from '../../../utils/validator';
import { useDispatchForWM, useStateForWM } from '../ContextProvider';
import { UPDATE_RECEIVERS, UPDATE_CCS, UPDATE_BCCS, UPDATE_FROMS, UPDATE_TOS } from '../ContextProvider/reducer/action-type';
import AuthContext from '../../../contexts/authContext';
import LoadingContext from '../../../contexts/loadingContext';
import MultiSelect from 'react-select';
import { useTheme } from "@mui/material/styles";

import { getUsersFromMails } from '../../../apis';

const ListOfReceivers = ({ defaultReceivers, type }) => {
  const theme = useTheme();
  const customStyles = {
      control: (provided, state) => ({
          ...provided,
          backgroundColor: 'transparent',
          borderColor: state.isFocused ? `${theme.palette.primary.main} !important` : 'inherit',
          borderWidth: 0, // state.isFocused ? 2 : 1,
          boxShadow: state.isFocused ? null : null,
          fontSize: '14px',
          display: 'grid',
          gridTemplateColumns: "1fr auto"
      }),
      input: base => ({
        ...base,
        overflow: "hidden",
        maxWidth: "100%"
      }),
      menuPortal: base => ({
          ...base,
          zIndex: 9999,
      }),
      multiValue: (styles, { data }) => ({
        ...styles,
        borderRadius: '20px',
        border: 'solid 1px #cccccc',
        backgroundColor: 'white',
      }),
      multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: '#5f6368',   
        fontSize: '14px',    
        fontWeight: '600', 
        borderRadius: '20px',
        padding: '2px',
        margin: '1px',
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
        ':hover': {
          backgroundColor: 'white',
          color: '#333333',
        },
      }),
      multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: '#5f6368',
        borderRadius: '20px',  
        fontSize: '14px',
        margin: '1px',
        ':hover': {
          backgroundColor: 'white',
          color: 'black',
        },
      }),
  };
  
  const { receivers, ccs, bccs } = useStateForWM();
  const dispatch = useDispatchForWM();

  const loadingCtx = useContext(LoadingContext);
  const authCtx = useContext(AuthContext);
  const [people, setPeople] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [draftInput, setDraftInput] = useState('');

  useEffect(() => {
    let init_people = [];
    // console.log('default-receiver', defaultReceivers, type);
    defaultReceivers?.map((ele) => {
      init_people.push({value: ele, label: ele})
    })
    // console.log('init_people', init_people, type);
    setPeople(init_people);
  }, [defaultReceivers])

  useEffect(() => {
    if (defaultReceivers) {
      switch (type) {
        case "receivers": {
          dispatch({
            type: UPDATE_RECEIVERS,
            payload: { receivers: defaultReceivers },
          });
          break;
        }
        case "ccs": {
          dispatch({
            type: UPDATE_CCS,
            payload: { ccs: defaultReceivers },
          });
          break;
        }
        case "bccs": {
          dispatch({
            type: UPDATE_BCCS,
            payload: { bccs: defaultReceivers },
          });
          break;
        }
        case "froms": {
          dispatch({
            type: UPDATE_FROMS,
            payload: { froms: defaultReceivers },
          });
          break;
        }
        case "tos": {
          dispatch({
            type: UPDATE_TOS,
            payload: { tos: defaultReceivers },
          });
          break;
        }
      }
    }
  }, [defaultReceivers]);

  const handleInputChange = (value) => {
    setInputValue(value);
  };

  const handleChange = (p) => {
    setPeople(p);
    // console.log('people', p);
    p = p.map((ele) => {
      return ele.value;
    });
    // console.log('receivers', p);
    switch (type) {
      case "receivers": {
        dispatch({
          type: UPDATE_RECEIVERS,
          payload: { receivers: p },
        });
        break;
      }
      case "ccs": {
        dispatch({
          type: UPDATE_CCS,
          payload: { ccs: p },
        });
        break;
      }
      case "bccs": {
        dispatch({
          type: UPDATE_BCCS,
          payload: { bccs: p },
        });
        break;
      }
      case "froms": {
        dispatch({
          type: UPDATE_FROMS,
          payload: { froms: p },
        });
        break;
      }
      case "tos": {
        dispatch({
          type: UPDATE_TOS,
          payload: { tos: p },
        });
        break;
      }
    }
    
  }

  const handleBlur = () => {
    setDraftInput(inputValue);
  };

  const handleFocus = () => {
    setInputValue(draftInput);
  };

  useEffect(() => {
    // console.log(e);
    if (inputValue === '') {
      setCandidates([]);
      return;
    }

    if(validator.validate('email', inputValue)) {
      console.log("validated", inputValue);
      const listValue = [{value: inputValue, label: inputValue}];
      setCandidates(listValue);
      return;
    }

    getUsersFromMails(inputValue, 5, authCtx.token)
    .then((res) => {
      // loadingCtx.setLoading(false);
      if(res.length > 0) {
        // console.log('from backend', res);
        // res = res.map((ele) => {
        //   return {value: ele, label: ele}
        // })
        // console.log('refined-from-backend', res);
        setCandidates(res);
      } else {
        setCandidates([]);
      }        
    })
    .catch((inputValue) => {setCandidates([])})
  }, [inputValue])

  // const refreshCandidate = (e) => {
  //   // console.log(e);
  //   if (e === '') {
  //     setCandidates([]);
  //     return;
  //   }
    
  //   if(validator.validate('email', e)) {
  //     console.log("validated", e);
  //     e = [{value: e, label: e}];
  //     setCandidates(e);
  //     return;
  //   }

  //   getUsersFromMails(e, 5, authCtx.token)
  //   .then((res) => {
  //     // loadingCtx.setLoading(false);
  //     if(res.length > 0) {
  //       // console.log('from backend', res);
  //       // res = res.map((ele) => {
  //       //   return {value: ele, label: ele}
  //       // })
  //       // console.log('refined-from-backend', res);
  //       setCandidates(res);
  //     } else {
  //       setCandidates([]);
  //     }        
  //   })
  //   .catch((e) => {setCandidates([])})
  // }

  const renderPeopleSearch = () => {
    return <Box className="pb-0">
        <MultiSelect
            isMulti
            placeholder={(type === "receivers" || type === "ccs" || type === "bccs")?"Recipients":""}
            components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
            menuPortalTarget={document.body}
            styles={customStyles}
            options={candidates}            
            value={people}
            onChange={handleChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            onBlur={handleBlur}
            onFocus={handleFocus}
        />
    </Box>
  }

  return (
    <WM_S.InputRowWrapper style={defaultReceivers ? { display: '' } : {}}>
      {
        (type === "receivers" || type === "ccs" || type === "bccs") &&
        <WM_S.Label style={{marginTop:'8px'}}>{(type === "receivers")?"To":((type === "ccs")?"Cc":((type === "bccs")?"Bcc":""))}</WM_S.Label>
      }    
      { renderPeopleSearch() }
    </WM_S.InputRowWrapper>
  );
};

export default ListOfReceivers;
