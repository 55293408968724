
import { useContext } from 'react';

import { Box, Table, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import { FileIcon, defaultStyles } from 'react-file-icon';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderIcon from '@mui/icons-material/Folder';
import StarIcon from '@mui/icons-material/Star';
import PeopleIcon from '@mui/icons-material/People';

import { getFileExt, getFileSizeString, isPublic } from '../../../utils';

import ThemeContext from '../../../contexts/themeContext';
import AuthContext from '../../../contexts/authContext';
import DialogContext from '../../../contexts/dialogContext';

export default function DataTable({folders, files, fields, openMenu}) {
    const themeCtx = useContext(ThemeContext);
    const authCtx = useContext(AuthContext);
    const dialogCtx = useContext(DialogContext);

    const handleSelect = (item) => {
        dialogCtx.setDriveDetails(item);
    }

    const handleOpen = (item) => {
        dialogCtx.setDriveFolder(item.id);
        dialogCtx.setDriveDetails(null);
    }

    const renderName = (item) => {
        const name = item["name"];
        const ext = getFileExt(name)
        return <Box className="flex items-center"
            sx={{
                color: themeCtx.isDark() ? 'white' : 'dimgrey',
                "& svg": {
                    fontSize: 22,
                    height: '1em',
                    minWidth: '1em',
                    width: '1em'
                },
                "& .MuiSvgIcon-fontSizeSmall": {
                    fontSize: 16,
                }
            }}>
            {
                item.isFolder === true ? (isPublic(item.public) ? <FolderSharedIcon /> : <FolderIcon />)
                : <FileIcon extension={ext} {...defaultStyles[ext]} width="auto" />    
            }
            <Typography
                variant="body2"
                sx={{
                    marginLeft: "16px !important",
                    paddingRight: 1
                }}>
                {name}
            </Typography>
            {
                isPublic(item.public) && item.isFolder !== true ? 
                <Box className="pl-2">
                    <PeopleIcon fontSize="small" />
                </Box> : ""
            }
            {
                item.starred === true ?
                <Box className="pl-2">
                    <StarIcon fontSize="small" />
                </Box> : ""
            }
        </Box>
    }

    const renderItem = (item, key) => {
        if (key === "name")
            return renderName(item);
        if (key === "size")
            return getFileSizeString(item[key]);
        if (key === "owner" && item[key] === authCtx.getAddress())
            return "me";
        return item[key];
    }

    const render = () => {
        return <Box className="px-2 py-4 overflow-auto h-[320px]">
            <Table className="select-none">
                <TableHead>
                    <TableRow>
                        {
                            fields.map((field, i) => {
                                return <TableCell key={i}>
                                    {field.label}
                                </TableCell>
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        folders?.map((item, i) => {
                            return <TableRow key={i} onClick={() => handleSelect(item)} onDoubleClick={() => handleOpen(item)}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#80808020"
                                },
                                backgroundColor: dialogCtx.driveDetails?.id === item.id ? "#00f0f020 !important" : ""
                            }}>
                                {
                                    fields.map((field, i) => {
                                        return <TableCell key={i} className="cursor-pointer">
                                            {
                                                renderItem(item, field.key)
                                            }
                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        })
                    }
                    {
                        files?.map((item, i) => {
                            return <TableRow key={i} onClick={() => handleSelect(item)}
                            sx={{
                                "&:hover": {
                                    backgroundColor: "#80808020"
                                },
                                backgroundColor: dialogCtx.driveDetails?.id === item.id ? "#00f0f020 !important" : ""
                            }}>
                                {
                                    fields.map((field, i) => {
                                        return <TableCell key={i} className="cursor-pointer">
                                            {
                                                renderItem(item, field.key)
                                            }
                                        </TableCell>
                                    })
                                }
                            </TableRow>
                        })
                    }
                </TableBody>
            </Table>
        </Box>
    }

    return render()
}