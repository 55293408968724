
import { createContext } from 'react';

const AuthContext = createContext({
	token: null,
    setToken: () => {},
    isLogin: () => {},
    logout: () => {},
    getAddress: () => {},
    getPassword: () => {},
    getAccount: () => {},
    quota: null,
    setQuota: (quota) => {},
});

export default AuthContext;