
import { useState } from "react";

import { Container, UniMail, ThemeImage } from '../../Components';
import Register from './Register';
import VerifyPhone from './VerifyPhone';
import PersonalInfo from './PersonalInfo';

export default function Signup() {
    const [step, setStep] = useState(0);
    const [user, setUser] = useState({
        address: "",
        firstName: "",
        lastName: "",
        password: "",
        verifyPhone: ""
    });

    const onNext = () => {
        setStep(step + 1);
    }

    const onBack = () => {
        setStep(step - 1);
    }

    const renderLeft = () => {
        if (step === 0)
            return <Register onNext={onNext} user={user} setUser={setUser} />
        else if (step === 1)
            return <VerifyPhone onNext={onNext} onBack={onBack} user={user} setUser={setUser} />
        else if (step === 2)
            return <PersonalInfo onNext={onNext} onBack={onBack} user={user} />
    }

    const renderRight = () => {
        if (step === 0) {
            return <ThemeImage style={{width: '100%'}} source="sign_up_1.png" alt="Signup" />
        }
        else {
            return <ThemeImage style={{width: '100%'}} source="sign_up_2.png" alt="Signup" />
        }
    }
 
    return (
        <Container>
            <UniMail />
            <div className="grid grid-cols-1 sm:grid-cols-2">
                <div className="max-w-[450px]">
                    { renderLeft() }
                </div>
                <div className="max-w-[450px] hidden sm:flex pl-16 my-auto">
                    { renderRight() }
                </div>
            </div>
        </Container>
    );
}