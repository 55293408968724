import {React} from "react";
import "./style.css";

import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import quillEmoji from 'quill-emoji';
import "quill-emoji/dist/quill-emoji.css";

const quillTable = require('quill-table');

Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register('modules/table', quillTable.TableModule);
///////////////
// ADD FONTS //
///////////////

// specify the fonts you would 
var fonts = [ 
  'Arial',
  // 'Comic Sans MS',
  'Courier',
  'Fixed Width',
  'Garamond',
  'Georgia',
  'monospace',
  'Narrow',
  'Sans Serif',
  'Serif',
  'Tahoma',
  'Verdana',
  'Wide'];
  
// generate code friendly names
function getFontName(font) {
    return font.toLowerCase().replace(/\s/g, "-");
}
var fontNames = fonts.map(font => getFontName(font));
// add fonts to style
var fontStyles = "";

fonts.forEach(function(font) {
    var fontName = getFontName(font);
    fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
        "content: '" + font + "';" +
        "font-family: '" + font + "', sans-serif;" +
        "}" +
        ".ql-font-" + fontName + "{" +
        " font-family: '" + font + "', sans-serif;" +
        "}";
});
var node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

// Add fonts to whitelist
var Font = Quill.import('formats/font');
Font.whitelist = fontNames;
Quill.register(Font, true);

///////////////
// ADD EMOJI //
///////////////

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

/////////////////
// ADD TOOLBAR //
/////////////////

const quillModules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}],
    [{ font: fontNames }, {size: []}],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}, { 'align': [] }],
    [{ 'color': [] }, { 'background': [] }],    
    [{ 'script': 'sub'}, { 'script': 'super' }],
    ['emoji', 'link', 'image'],
    ['clean']
  ],
  
  'emoji-toolbar': true,
  "emoji-textarea": false,
  "emoji-shortname": true,
}

/* 
* Quill editor formats
* See https://quilljs.com/docs/formats/
*/
const quillFormats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'color', 'background',
  'script',
  'list', 'bullet', 'indent', 'align',
  'link', 'image',
  'emoji'
]

const QuillEditor = ({ value, quillRef, onChange }) => (
  <ReactQuill
    theme="snow"
    modules={quillModules}
    formats={quillFormats}
    style={{position:'relative', width:'100%', height:'100%', padding:'5px', display: 'grid', gridTemplateRows: 'auto 1fr'}}
    value={value}
    ref={quillRef}
    onChange={onChange}>
  </ReactQuill>
);

export default QuillEditor;