

import { Popover, Box, Typography, Divider, Button, Icon } from '@mui/material';
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from '@mui/material';
import { useContext } from "react";
import AuthContext from '../../../contexts/authContext';
import ArticleIcon from '@mui/icons-material/Article';

export default function Help({pos, show, setShow}) {
    const authCtx = useContext(AuthContext);

    const handleClose = (e) => {
        setShow(false);
        e.preventDefault();
    };

    const setContent = (no) => {

    }

    return <Popover
        sx={{
            "& .MuiPaper-root": {
                width: 360,
                height: 500,
                borderRadius: 5,
                display: 'grid',
                gridTemplateRows: 'auto auto 1fr'
            }
        }}
        // anchorReference="anchorPosition"
        // anchorPosition={pos}
        open={show}
        disableEnforceFocus={true}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        onContextMenu={handleClose}
    >
        <Box className="pl-6" style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
            <div className='p-4 text-center'><Typography variant="h5">Help</Typography></div>
            <IconButton onClick={() => handleClose()}><CloseIcon /></IconButton>
        </Box>
        <Divider />
        <Box className='help-content'
        sx={{
            height: '100%',
            overflowY: 'hidden', 
            display: 'flex'
        }}>
            <ul sx={{
                overflowY: 'auto', 
            }} className="p-4" >
                <li onClick={() => setContent(1)}><ArticleIcon/>Change or reset your password</li>
                <li onClick={() => setContent(2)}><ArticleIcon/>Troubleshoot problems receiving emails in Unimail</li>
                <li onClick={() => setContent(3)}><ArticleIcon/>Change or reset your password</li>
                <li onClick={() => setContent(4)}><ArticleIcon/>Troubleshoot problems receiving emails in Unimail</li>
            </ul>
            <Box 
            sx={{
                overflowY: 'auto', 
                display: 'none',
            }} className="p-4">           
                Dear All,
                We hope this email finds you well. We are excited to announce the launch of our new mail service, designed to help you stay connected with your loved ones, colleagues, and clients.
                Our new mail service offers a range of features that will simplify your mailing experience. You can easily send and receive letters, postcards, and packages from anywhere in the world. Our user-friendly interface will allow you to track your mail and receive notifications on the status of your delivery.
                With our new mail service, you can enjoy:
                Convenient online mail management
                Secure delivery with tracking and notifications
                Competitive pricing and flexible options
                Personalized service and support
                To celebrate the launch of our new mail service, we are offering a special promotion to our valued customers. Simply sign up for our mail service and receive [offer details].
                We are committed to providing you with the best possible mailing experience, and we believe our new mail service will exceed your expectations. To learn more about our new service, please visit our website or contact our customer service team.
                Thank you for choosing us as your trusted mail service provider. We look forward to serving you with our new mail service.
                Best regards,
                Venu Esampally
                UniSpace
            </Box>
        </Box>
        
    </Popover>
}