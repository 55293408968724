import Axios from 'axios';

export const url = "https://drive.unimail.in";

const axios = Axios.create({
	responseType: 'json',
	baseURL: url,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});

axios.interceptors.response.use(function (res) {
	return res;
});

export const getMyDrive = (path, account) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/my_drive`, {
				path, owner: account
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getRecent = (account) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/recent`, {
				owner: account
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getSharedWithMe = (account) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/shared_with_me`, {
				owner: account
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getFolder = (id, account) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/view_folder`, {
				id, owner: account
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const uploadFile = (file, path, account, onUploadProgress) => {
	const config = {
		onUploadProgress: function(progressEvent) {
			const percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
			onUploadProgress(percentCompleted);
		}
	};
	const name = file.webkitRelativePath === "" ? file.name : file.webkitRelativePath;
    return new Promise((resolve, reject) => {
		Axios
			.post(
				`${url}/api/v1/user/upload_file?name=${name}&path=${path}&owner=${account}&size=${file.size}`, 
				file, 
				config
			)
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}