

import { 
    Popover, 
    Box, 
    Typography, 
    Link, 
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel, } from '@mui/material';
import { Link as Link1 } from '@material-ui/core';
import { Avatar, OutlinedButton } from "../../Components";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useContext, useState, useEffect } from "react";
import AuthContext from '../../contexts/authContext';
import LoadingContext from '../../contexts/loadingContext';
import SearchContext from '../../contexts/searchContext';
import { useNavigate } from "react-router-dom";
import ListOfReceivers from "../../Components/SendMail/InputReceiver/multiselect";
import { useStateForWM, useDispatchForWM } from '../../Components/SendMail/ContextProvider';
import "./Filter.css";
import { filter } from 'lodash';

export default function Filter({pos, width, path, show, setShow}) {
    const authCtx = useContext(AuthContext);
    const searchCtx = useContext(SearchContext);
    const loadingCtx = useContext(LoadingContext);
    const { froms, tos } = useStateForWM();
    const navigate = useNavigate();

    // const [filters, setFilters] = useState({
    //     from: '',
    //     to: '',
    //     subject: '',
    //     hasWords: '',
    //     doesntHave: '',
    //     // size: '',
    //     // dateWithin: '',
    //     in: "all",
    //     hasAttachment: false,
    // });

    const [filters, setFilters] = useState(searchCtx.search);
    
    useEffect(() => {
        setFilters(searchCtx.search);
    }, [searchCtx.search])

    // useEffect(() => {
    //     // console.log(froms, tos)
    //     setFilters((prev) => ({
    //         ...prev,
    //         from: froms.join(' '),
    //         to: tos.join(' ')
    //       }));
    // }, [froms, tos])

    const handleChange = (event) => {
        const { name, value, checked } = event.target;
        setFilters((prev) => ({
          ...prev,
          [name]: name === 'hasAttachment' ? checked : value,
        }));
        
    };
    
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(filters);

        let change = "";

        if (filters.in !== "all") //  && filters.in !== path
            change += filters.in.split(/\s*,\s*/).map(word => `in:${word}`).join(' ') + ' ';         
        if (filters.from !== "")
            change += ((filters.from.split(/\s*,\s*/).length > 1)?('from:(' + filters.from + ')'):('from:' + filters.from)) + ' ';
        if (filters.to !== "")
            change += ((filters.to.split(/\s*,\s*/).length > 1)?('to:(' + filters.to + ')'):('to:' + filters.to)) + ' ';
        if (filters.subject !== "")
            change += ((filters.subject.split(/\s*,\s*/).length > 1)?('subject:(' + filters.subject + ')'):('subject:' + filters.subject)) + ' ';
        if (filters.hasWords !== "")
            change += filters.hasWords + ' ';        
        if (filters.doesntHave !== "")
            change += filters.doesntHave.split(/\s*,\s*/).map(word => `-${word}`).join(' ') + ' '; 
        if (filters.hasAttachment) {
            change += 'has:attachment' + ' ';
        }
        
        searchCtx.setInput(change.trim());
        console.log('search', searchCtx.input);
        if(change.trim() === "")
            return;
        
        // const search = {
        //     from: "",
        //     to: "",
        //     subject: "",
        //     hasWords: "",
        //     doesntHave: "",
        //     hasAttachment: false,
        //     in: "all",
        // };
        // const queryComponents = change.trim().split(' ');
    
        // queryComponents.forEach((component, index) => {
        //     if (component.startsWith('from:')) search.from = (search.from + ' ' + component.slice(5)).trim();
        //     else if (component.startsWith('to:')) search.to = (search.to + ' ' + component.slice(3)).trim();
        //     else if (component.startsWith('subject:')) search.subject = (search.subject + ' ' + component.slice(8)).trim();
        //     else if (component.startsWith('-')) search.doesntHave = (search.doesntHave + ' ' + component.slice(1)).trim();
        //     else if (!component.includes(':')) search.hasWords = (search.hasWords + ' ' + component).trim();
        //     else if (component.startsWith('in:')) search.in = component.slice(3);
        //     else if (component === 'has:attachment') search.hasAttachment = true;
        // });
    
        searchCtx.setSearch(filters);

        loadingCtx.setCurpage(1);
        navigate("/search");
    };
    
    const handleClose = (e) => {
        setShow(false);
        e.preventDefault();
    };

    return <Popover
        sx={{
            "& .MuiPaper-root": {
                width: {width},
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1,
                padding: 1
            }
        }}
        anchorReference="anchorPosition"
        anchorPosition={pos}
        open={show}
        disableEnforceFocus={true}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        onContextMenu={handleClose}        
    >
        <form onSubmit={handleSubmit} className='w-[100%] p-4'>
            {/* <TextField label="From" name="from" value={filters.from} onChange={handleChange} style={{marginBottom: '10px'}} fullWidth /> */}
            <div className='flex items-center mb-3'>
                <span className='w-40'>From</span>
                <TextField
                    id="standard-search"
                    // label="From"
                    name="from"
                    value={filters.from} 
                    onChange={handleChange}
                    type="search"
                    variant="standard"
                    fullWidth
                />
                {/* <ListOfReceivers className='w-[100%]' style={{width:'100%'}} defaultReceivers={[]} type={"froms"} /> */}
            </div>
            <div className='flex items-center mb-3'>
                <span className='w-40'>To</span>
                <TextField
                    id="standard-search"
                    // label="To"
                    name="to"
                    value={filters.to} 
                    onChange={handleChange}
                    type="search"
                    variant="standard"
                    fullWidth
                />
                {/* <ListOfReceivers defaultReceivers={[]} type={"tos"} /> */}
            </div>
            <div className='flex items-center mb-3'>
                <span className='w-40'>Subject</span>
                <TextField
                    id="standard-search"
                    // label="Subject"
                    name="subject"
                    value={filters.subject} 
                    onChange={handleChange}
                    type="search"
                    variant="standard"
                    fullWidth
                />
            </div>
            <div className='flex items-center mb-3'>
                <span className='w-40'>Has the words</span>
                <TextField
                    id="standard-search"
                    // label="Has the words"
                    name="hasWords"
                    value={filters.hasWords} 
                    onChange={handleChange}
                    type="search"
                    variant="standard"
                    fullWidth
                />
            </div>
            <div className='flex items-center mb-3'>
                <span className='w-40'>Doesn't have</span>
                <TextField
                    id="standard-search"
                    // label="Doesn't have"
                    name="doesntHave"
                    value={filters.doesntHave} 
                    onChange={handleChange}
                    type="search"
                    variant="standard"
                    fullWidth
                />
            </div>
            <div className='flex items-center mb-3'>
                <span className='w-40'>Search in</span>
                <FormControl  
                    type="search"
                    variant="standard"
                    fullWidth
                >
                    {/* <InputLabel>Search in category</InputLabel> */}
                    <Select name="in" value={filters.in} onChange={handleChange}>
                    <MenuItem value="all">All Mails</MenuItem>
                    <MenuItem value="inbox">Inbox</MenuItem>
                    <MenuItem value="starred">Starred</MenuItem>
                    <MenuItem value="important">Important</MenuItem>
                    <MenuItem value="sent">Sent</MenuItem>
                    {/* <MenuItem value="darft">Draft</MenuItem> */}
                    <MenuItem value="spam">Spam</MenuItem>
                    <MenuItem value="trash">Trash</MenuItem>
                    <MenuItem value="promotions">Promotions</MenuItem>
                    <MenuItem value="social">Social</MenuItem>
                    <MenuItem value="updates">Updates</MenuItem>
                    {/* Add more categories as needed */}
                    </Select>
                </FormControl>
            </div>
                    
            {/* You can expand on Size and Date Within with more specific UI components */}
            {/* <TextField label="Size" name="size" value={filters.size} onChange={handleChange} style={{marginBottom: '10px'}} fullWidth />
            <TextField label="Date within" name="dateWithin" value={filters.dateWithin} onChange={handleChange} style={{marginBottom: '10px'}} fullWidth /> */}
            
            <div className='filter-bottom'>
                <FormControlLabel
                    control={
                    <Checkbox
                        checked={filters.hasAttachment}
                        onChange={handleChange}
                        name="hasAttachment"
                    />
                    }
                    label="Has attachment"
                />
                <Button type="submit" variant="contained" style={{marginTop: '10px'}} color="primary" onClick={() => setShow(false)}>
                    Search
                </Button>
            </div>
            
        </form>
    </Popover>
}