
import axios from 'axios';
import parsePhoneNumber from 'libphonenumber-js';
import * as emailValidator from 'email-validator';

export const getPublicIp = () => {
    return new Promise((resolve, reject) => {
		axios.get(encodeURI('https://ipinfo.io/?token=9a50bf62c4b1b0'))
			.then((response) => {
				resolve(response.data.ip)
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getPhoneNumber = (phone) => {
	if (phone === undefined)
		return "";
	if (phone[0] !== '+') phone = '+' + phone;
	const phoneNumber = parsePhoneNumber(phone);
	if (phoneNumber === undefined || phoneNumber.number === "")
		return "";
	if (phoneNumber.isValid() === false)
		return "";
	return phoneNumber.countryCallingCode + phoneNumber.nationalNumber;
}

export const formatPhoneNumber = (phone, national = false) => {
	if (phone === undefined)
		return "";
	if (phone[0] !== '+') phone = '+' + phone;
	const phoneNumber = parsePhoneNumber(phone);
	if (phoneNumber === undefined || phoneNumber.number === "")
		return "";
	if (phoneNumber.isValid() === false)
		return "";
	return national ? phoneNumber.formatNational() : phoneNumber.formatInternational();
}

export const getMailAddress = (address) => {
	if (emailValidator.validate(address))
		return address;
	return "";
}

export const getMailOrPhone = (contact) => {
	const phone = getPhoneNumber(contact);
	const address = getMailAddress(contact);
	if (phone === "" && address === "")
		return null;
	return { phone, address };
}

export const hidePhoneInfo = (phone) => {
    const length = phone.length;
    return "".padStart(length - 2, "\u00B7") + "" + phone.substring(length - 2);
}

export const hideMailInfo = (address) => {
	const str = address.split('@');
    return str[0].substring(0, 2) + ''.padStart(str[0].length - 2, "\u00B7") + str[1];
}

export const highlightText = (text, search) => {
	const words = search.split(' ').filter(word => word.length).map(word => word.toLowerCase());
	const tokens = text.split(new RegExp(`(${words.join('|')})`, 'gi'));
	
	return tokens.map((token, index) => 
		words.includes(token.toLowerCase()) 
			? <span key={index} style={{backgroundColor: '#fde293', padding: '2px 0px', margin: '0px 0px'}}>{token}</span> 
			: token
	);
}

export const highlightText_text = (text, search) => {
    const words = search.split(' ').filter(word => word.length).map(word => word.toLowerCase());
    
    // Extract and temporarily replace contents of href and src
    const replacements = [];
    text = text.replace(/(<a[^>]+href=")([^"]*)("|[^>]*>.*?<\/a>)|(<img[^>]+src=")([^"]*)("[^>]*\/?>)/gi, (full, aPrefix, hrefContent, aPostfix, imgPrefix, srcContent, imgPostfix) => {
        if (hrefContent) {
            const replacement = `REPLACEMENT${replacements.length}`;
            replacements.push(hrefContent);
            return `${aPrefix}${replacement}${aPostfix}`;
        } else if (srcContent) {
            const replacement = `REPLACEMENT${replacements.length}`;
            replacements.push(srcContent);
            return `${imgPrefix}${replacement}${imgPostfix}`;
        }
        return full;
    });

    const tokens = text.split(new RegExp(`(${words.join('|')})`, 'gi'));

    let highlightedText = tokens.map((token, index) => {
        if (words.includes(token.toLowerCase())) {
            return `<span style="background-color: #fde293; padding: 2px 0px; margin: 0px 0px;">${token}</span>`;
        }
        return token;
    }).join('');

    // Restore the original href and src contents
    highlightedText = highlightedText.replace(/REPLACEMENT(\d+)/g, (_, index) => replacements[parseInt(index, 10)]);

    return highlightedText;
}


export const getEmailDateTimeFormat = (datetimeString, type) => {
	const moment = require('moment'); 
	// Example datetime string 
	// const datetimeString = '2023-03-18T12:23:00Z'; 
	
	// Parse the datetime string using moment 
	const datetime = moment(datetimeString); 
	const now = moment();
	
	// Get the difference between the datetime and current time in milliseconds 
	const diffInDays = now.diff(datetime, 'days');
	const diffInHours = now.diff(datetime, 'hours');
	const diffInYears = now.year() - moment(datetime).year();

	const timeDifference = moment(datetime).fromNow();
	const diffMs = now.diff(datetime); 	
	const duration = moment.duration(diffMs);

	let outputString = '';
	// alert(duration._data.days);
	// console.log(datetime, now, 'duration--------', timeDifference, diffInYears, diffInDays, diffInHours);

	if (type === 1) {
		if (diffInYears > 0) {
			outputString = moment(datetime).format('D MMM YYYY');
		} else if (diffInDays > 0) {
			outputString = moment(datetime).format('D MMM');
		} else if (diffInHours < 24) {
			outputString = moment(datetime).format('h:mm A');
		} else {
			outputString = moment(datetime).format('YYYY-MM-DD HH:mm:ss');
		}
	} else if (type === 2) {
		if (diffInYears > 0) {
			outputString = moment(datetime).format('ddd, D MMM YYYY, HH:mm');
		} else if (diffInDays > 14) {
			outputString = moment(datetime).format('ddd, D MMM, HH:mm');
		} else {
			outputString = `${datetime.format('ddd, D MMM, HH:mm')} (${timeDifference})`;
		} 
	} else if (type === 3)
		outputString = `${datetime.format('ddd, D MMM YYYY, HH:mm')} (${duration.humanize(true)})`;
	else if (type === 4)
		outputString = `${datetime.format('ddd, D MMM YYYY, HH:mm')}`;

	return outputString;
}



export const isPublic = (access) => {
	return access === "editor" || access === "viewer";
}

export const getFileExt = (str) => {
	return str.split('.').pop();
}

export const getFileSizeString = (bytes, dp=1) => {
	if (bytes === undefined) return "-";
	
	const thresh = 1024;

	if (Math.abs(bytes) < thresh) {
	return bytes + ' B';
	}

	const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	let u = -1;
	const r = 10**dp;

	do {
	bytes /= thresh;
	++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


	return bytes.toFixed(dp) + ' ' + units[u];
}