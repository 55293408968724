
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import LoadingContext from '../../contexts/loadingContext';
import SearchContext from '../../contexts/searchContext';
import { Box, Divider } from '@mui/material';
import MessageSnackbar from '../../Components/Snackbar';
import { AppDispatchContext, AppStateContext } from '../../contexts';
import { handleSnackbarState } from '../../contexts/reducer';
import Header from "./Header";
import Layout from "./Layout/Layout";
import Mail from "../../Components/Mail/Mail";
import EmailList from "../../Components/EmailList/EmailList";
import SendMail from "../../Components/SendMail/SendMail";
import { WriteMailContextProvider } from '../../Components/SendMail/ContextProvider';
import { selectSendMessageIsOpen } from "../../features/mailSlice";
import { useSelector } from "react-redux";
// import { Navigate } from "react-router-dom";

export default function Helps({ path }) {
    
    const navigate = useNavigate();
    const loadingCtx = useContext(LoadingContext);
    const searchCtx = useContext(SearchContext);
    const authCtx = useContext(AuthContext);
    const sendMessageIsOpen = useSelector(selectSendMessageIsOpen); 
    const [emails, setEmails] = useState([]);
    const [total, setTotal] = useState(0);
    const [perpage, setPerpage] = useState(0);
    const [unreadCounts, setUnreadCounts] = useState({});

    const { state } = useContext(AppStateContext);
    const { dispatch } = useContext(AppDispatchContext);
    const [user, setUser] = useState(null);
    const { snackbarOpen, snackbarVariant, snackbarContent } = state;
    const snackbarState = { snackbarOpen, snackbarVariant, snackbarContent };

    const messageSnackbarProps = {
        ...snackbarState,
        snackbarClose: () => dispatch(handleSnackbarState({ snackbarOpen: false })),
    };

    // useEffect(() => {
    //     // console.log(authCtx.isLogin());
    //     if (!authCtx.isLogin()) {
    //         console.log("sign");
    //         navigate('/signin');
    //     }
        
    // }, [authCtx])  // 
   
    return <Box className="app h-screen grid overflow-y-hidden" gridTemplateRows="auto auto 1fr">
        <Header path={path} />
        <Layout path={path} />
    </Box>;
}