
import { useState, useRef, useContext } from "react";
import { Box, Button, FormHelperText, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingContext from '../../contexts/loadingContext';

import { formatPhoneNumber } from '../../utils';
import { resetPassword } from '../../apis';

const t_pwdError = "Enter a password";
const t_pwdLengthError = "Use 8 characters or more for your password";
const t_pwdConfirmError = "Passwords Didn’t Match";

export default function CreatePassword({goToHome, goToSignIn, contact}) {
    const loadingCtx = useContext(LoadingContext);

    const pwdRef = useRef(null);
    const pwdConfirmRef = useRef(null);

    const [pwdError, setPwdError] = useState("");
    const [pwd, setPwd] = useState("");
    const [confirmPwd, setConfirmPwd] = useState("");

    const [showPwd, setShowPwd] = useState(false);

    const handleNext = () => {
        let focusObj = null;
        if (pwd === "") {
            setPwdError(t_pwdError);
            focusObj = pwdRef.current;
        }
        else if (pwd.length < 8) {
            setPwdError(t_pwdLengthError);
            focusObj = pwdRef.current;
        }
        else if (pwd !== confirmPwd) {
            setPwdError(t_pwdConfirmError);
            focusObj = pwdConfirmRef.current;
        }
        else {
            setPwdError("");
        }

        if (focusObj !== null)
            focusObj.focus();
        else {
            loadingCtx.setLoading(true);
            setTimeout(() => {
                resetPassword(contact, pwd)
                .then((res) => {
                    loadingCtx.setLoading(false);
                    if (res.result)
                        goToHome(res.token);
                })
                .catch((e) => {})
            }, 1000);
        }
    }

    // const handleSkip = () => {
    //     loadingCtx.setLoading(true);
    //     setTimeout(() => {
    //         resetPassword(contact, "")
    //         .then((res) => {
    //             loadingCtx.setLoading(false);
    //             if (res.result)
    //                 goToHome(res.token);
    //         })
    //         .catch((e) => {})
    //     }, 1000);
    // }
    
    return (
        <>
            <p className="text-2xl text-center pb-2">Change password</p>
            <p className="text-base text-center pb-8">
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: "none",
                        borderRadius: "99px"
                    }}
                    startIcon={<AccountCircleIcon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={goToSignIn}>
                    {contact.address}
                    {formatPhoneNumber(contact.phone, true)}
                </Button>
            </p>
            <p className="text-base font-bold pb-4">Create a strong password</p>
            <p className="text-sm pb-8">Create a new, strong password that you don't use for other websites</p>
            <Box className="pb-4">
                <TextInput
                    label="Create Password"
                    autoComplete="off"
                    value={pwd}
                    type={showPwd ? "default" : "password"}
                    size="default"
                    onChange={(e) => {
                        setPwd(e.target.value);
                    }}
                    error={pwdError !== "" && pwd.length < 8}
                    inputRef={pwdRef} />
                <Box className="pb-4" />
                <TextInput
                    label="Confirm Password"
                    autoComplete="off"
                    value={confirmPwd}
                    type={showPwd ? "default" : "password"}
                    size="default"
                    onChange={(e) => {
                        setConfirmPwd(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleNext();
                        }
                    }}
                    error={pwdError !== "" && pwd !== confirmPwd}
                    inputRef={pwdConfirmRef} />
                <FormHelperText error={pwdError !== ""}>
                    {pwdError === "" ? 
                        "At least 8 characters" :
                        <HelperText error={pwdError} />}
                </FormHelperText>
            </Box>
            <div className="pb-12">
                <FormGroup>
                    <FormControlLabel control={<Checkbox value={showPwd} onChange={(e) => {setShowPwd(e.target.checked)}} />} label="Show password" />
                </FormGroup>
            </div>
            <Box className="flex">
                {/* <Button variant="text" sx={{textTransform: "none"}} onClick={handleSkip}>Skip</Button> */}
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Ok</Button>
            </Box>
        </>
    );
}