
import { useContext, useState, useEffect } from 'react';

import { Avatar as MuiAvatar } from '@mui/material';
import AuthContext from '../contexts/authContext';
import { _getProfile } from '../apis';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export default function Avatar({size, name, clickEnabled,  isMe, header}) {
    const authCtx = useContext(AuthContext);
    const [state, setState] = useState(0);
    const [image, setImage] = useState('');

    if (name === undefined){
        name = authCtx.getAccount();
    }
    if(clickEnabled === undefined){
        clickEnabled = false;
    }
    if(isMe === undefined){
        isMe = false;
    }
    if(header === undefined){
        header = false;
    }
    
    useEffect(() => {
        if(isMe){
            _getProfile(authCtx.token)
            .then((res) => {
                if(res.success === true){
                    setState(2);
                    setImage(res.photo);
                }
                else{
                    setState(1);
                }
            })
            .catch((e) => {
                setState(1);
            });
        }
        else{
            setState(1);
        }
    }, [authCtx.profile, authCtx.token, isMe])

    useEffect(() => {
        // console.log(authCtx.token, isMe, header);
        if(isMe){
            _getProfile(authCtx.token)
            .then((res) => {
                // console.log(res);
                if(res.success === true){
                    setState(2);
                    setImage(res.photo);
                }
                else{
                    setState(1);
                }
            })
            .catch((e) => {
                setState(1);
            });
        }
        else{
            setState(1);
        }
    }, [])
    
    return state === 1 ? <MuiAvatar
        sx={{
            width: size,
            height: size,
            backgroundColor: stringToColor(name),
            textTransform: 'uppercase',
            fontSize: size / 2
        }}
        className="cursor-pointer"
        >
        {name === "" ? "" : name[0]}
    </MuiAvatar> : state === 2 ? <img src={`/profile/${image}`} style={{width : size, height : size, borderRadius : '50%'}}/> : "";
}