import React, { useEffect, useState } from "react";
import "./Header.css";
import MenuIcon from "@material-ui/icons/Menu";
import { Box, Button, Divider, IconButton, Link, Typography } from '@mui/material';
import { Avatar } from "..";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AppsIcon from "@material-ui/icons/Apps";
import NotificationsIcon from "@material-ui/icons/Notifications";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { useContext } from "react";
import AuthContext from '../../contexts/authContext';
import SearchContext from '../../contexts/searchContext';
import MenuContext from "../../contexts/menuContext";
import { useNavigate } from "react-router-dom";

// for mobile
import { useDispatch } from "react-redux";
import { openSendMessage, selectOpenMail, openSidebar, selectSidebarIsOpen } from "../../features/mailSlice";
import { useSelector } from "react-redux";


function HeaderM({path, cat, counts}) {
  
  const authCtx = useContext(AuthContext);
  const searchCtx = useContext(SearchContext);
  const menuCtx = useContext(MenuContext);
  const navigate = useNavigate();

  // for mobile
  const dispatch = useDispatch();
  const selectedMail = useSelector(selectOpenMail);
  const sidebarIsOpen = useSelector(selectSidebarIsOpen); 
  ///////////////////////////////////////////
  // const original_path = selectedMail?.path;

  const getHeaderTitle = ({path, cat, counts}) => {
    let title = '';
    if(path === 'inbox') {
      if(cat === 0) {
        title = 'Primary';
        if(counts !== undefined && counts.primary > 0)
          title = title + ' ' + counts.primary;
      } else if(cat === 1) {
        title = 'Promotions';
        if(counts !== undefined && counts.promotions > 0)
          title = title + ' ' + counts.promotions;
      } else if(cat === 2) {
        title = 'Social';
        if(counts !== undefined && counts.social > 0)
          title = title + ' ' + counts.social;
      } 
    } else if(path === 'sent') {
      title = "Sent Mail";
      if(counts !== undefined && counts.sent > 0)
        title = title + ' ' + counts.sent;    
    } else if(path === 'spam') {
      title = "Spam";
      if(counts !== undefined && counts.spam > 0)
        title = title + ' ' + counts.spam;
    } else if(path === 'trash') {
      title = 'Trash';
      if(counts !== undefined && counts.trash > 0)
        title = title + ' ' + counts.trash;
    } else if(path === 'search') {
      title = 'Search';
    } else if(path === 'mail') {
      title = ''
    }
    return title;
  }

  let title = getHeaderTitle({path, cat, counts});  
  if (sidebarIsOpen) {
    title = 'Navigation';
  }
  const origin_title = getHeaderTitle({path: selectedMail?.path, cat: selectedMail?.category});
  // console.log('counts', counts);

  const handleChange = (event) => {
    searchCtx.setInput(event.target.value);
  };

  const handleSearch = () => {
    
    if(searchCtx.input === "")
      return;
    searchCtx.setSearch(searchCtx.input);
    navigate("/search");
  };
  
  const signOut = () => {
    authCtx.logout();
  };

  return (
    <div>
        <div className="header-mobile">
          <div className="header-left">
            {
              (path === 'mail')
              ?
              <Typography style={{ borderWidth: '1px' }}>{origin_title}</Typography> 
              :
              <IconButton onClick={() => dispatch(openSidebar())}>
                <MenuIcon />
              </IconButton>  
            }                    
          </div>
          <Typography>{title}</Typography>          
          {
            sidebarIsOpen
            ?
            <IconButton size="small" style={{ margin: '5px' }} onClick={(e) => menuCtx.showProfile(e)}>
              <Avatar size={32} isMe={true}/>
            </IconButton>
            :
            <Button
              className="header-compose"
              onClick={() => dispatch(openSendMessage())}
            >
              <ModeEditOutlinedIcon fontSize="medium" />
            </Button>            
          }
        </div>
        <Divider />
    </div>
  );
}

export default HeaderM;
