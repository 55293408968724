
import React, { useContext, useEffect, useState, useRef } from "react";
import { useNavigate, Navigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import LoadingContext from '../../contexts/loadingContext';
import SearchContext from '../../contexts/searchContext';
import { Typography, Box, Tooltip, useMediaQuery } from '@mui/material';
import "./Home.css";
import MessageSnackbar from '../../Components/Snackbar';
import { AppDispatchContext, AppStateContext } from '../../contexts';
import { handleSnackbarState } from '../../contexts/reducer';
import Header from "../../Components/Header/Header";
import Sidebar from "../../Components/Sidebar/Sidebar";
import Mail from "../../Components/Mail/Mail";
import EmailList from "../../Components/EmailList/EmailList";
import SendMail from "../../Components/SendMail/SendMail";
import HeaderM from "../../Components/Header/HeaderM";
import SidebarM from "../../Components/Sidebar/SidebarM";
import MailM from "../../Components/Mail/MailM";
import EmailListM from "../../Components/EmailList/EmailListM";
import SendMailM from "../../Components/SendMail/SendMailM";
import { WriteMailContextProvider } from '../../Components/SendMail/ContextProvider';
import { selectSendMessageIsOpen, selectSidebarIsOpen } from "../../features/mailSlice";
import { useSelector } from "react-redux";
import { getMails, getUnreadCounts, syncImap } from '../../apis';
import { io } from 'socket.io-client';

import LoadingProvider from '../../providers/loadingProvider';

// import { Navigate } from "react-router-dom";

const socket = io();

export default function Home({ path, cat }) {
    if (cat === undefined)
        cat = 0;
    const navigate = useNavigate();
    const loadingCtx = useContext(LoadingContext);
    const searchCtx = useContext(SearchContext);
    const authCtx = useContext(AuthContext);
    const sendMessageIsOpen = useSelector(selectSendMessageIsOpen);
    const sidebarIsOpen = useSelector(selectSidebarIsOpen); 
    const [emails, setEmails] = useState([]);
    const [mailpath, setMailpath] = useState('');
    const [total, setTotal] = useState(0);
    const [perpage, setPerpage] = useState(0);
    const [unreadCounts, setUnreadCounts] = useState({});
    const [initied, setInitied] = useState(false);
    const { state } = useContext(AppStateContext);
    const { dispatch } = useContext(AppDispatchContext);
    const [user, setUser] = useState(null);
    const { snackbarOpen, snackbarVariant, snackbarContent } = state;
    const snackbarState = { snackbarOpen, snackbarVariant, snackbarContent };

    const pathRef = useRef(null); // Create a ref to store the current value of path
    const catRef = useRef(null);


    const messageSnackbarProps = {
        ...snackbarState,
        snackbarClose: () => dispatch(handleSnackbarState({ snackbarOpen: false })),
    };

    // console.log('main ', path);

    useEffect(() => {
        console.log('useEffect-pathref', pathRef.current, catRef.current);
        pathRef.current = path; // Update the ref whenever path changes  
        catRef.current = cat;      
    }, [path, cat]);

    

    ///////////////start for mobile////////////////////

    const isDesktop = useMediaQuery('(min-width:1024px)');
   
    ///////////////////end for mobile///////////////////////////

    // loadingCtx.setPath(path);
    // loadingCtx.setCategory(0);
    // loadingCtx.setCurpage(1);

    useEffect(() => {
        console.log('useEffect-authCtx', authCtx.isLogin(), path);
        if (!authCtx.isLogin()) {
            console.log("sign");
            navigate('/signin');
        }
        else {            
            socket.on('new_mail', (param) => {
                console.log('new mail arrived!', param);
                if (param === authCtx.getAddress()) {
                    searchCtx.setSearch("");
                    console.log('new ', pathRef.current, cat, catRef.current, loadingCtx.category);
                    // loadingCtx.setCategory(cat);
                    if (pathRef.current === "inbox") {
                        getMainMails(pathRef.current, 0, catRef.current);
                    } else
                        getMainMails(pathRef.current, 1);
                    // navigate('/');
                }
            });
            syncImap(authCtx.token);
            // getMainMails(path);
        }
    }, [])  // 

    useEffect(() => {    
        console.log('useEffect-cat', cat)
        if (cat !== 0)
            loadingCtx.setCategory(cat)

        console.log('useEffect-path, curpage, refreshEmail')
        if (path === "drafts" || path === "scheduled") { //  || 
        } else if (path === "inbox" || path === "sent" || path === "spam" || path === "trash" || path === "starred" || path === "important") {
        } else if (path === "search") {
        } else if (path === "primary") {
            loadingCtx.setCategory(0);
        } else if (path === "promotions") {
            loadingCtx.setCategory(1);
        } else if (path === "social") {
            loadingCtx.setCategory(2);
        } else if (path === "updates") {
            loadingCtx.setCategory(3);
        }
        setInitied(true);

    }, [path, cat, loadingCtx.curpage, loadingCtx.refreshEmail]);

    useEffect(() => {
        console.log('useEffect-initied', initied)
        if(initied == true){
            if (path === "drafts" || path === "scheduled") { //  || 
                searchCtx.setInput("");
                searchCtx.setSearch(""); 
                getMainMails(path);
            } else if (path === "inbox" || path === "sent" || path === "spam" || path === "trash" || path === "starred" || path === "important") {
                getMainMails(path);
            } else if (path === "search") {
                getMainMails(path, 2);
            }
            setInitied(false);
        }
    }, [loadingCtx.category, initied])

    useEffect(() => { 
        console.log('useEffect-search')
        console.log('seachr', searchCtx.search)
        if (path === "search") {
            if (searchCtx.search.from === '' && searchCtx.search.to === '' && searchCtx.search.subject === '' && searchCtx.search.hasWords === '' && searchCtx.search.doesntHave === '' && searchCtx.search.in === 'all' && searchCtx.search.hasAttachment === false) {
                // navigate("/");
            }
            else {
                loadingCtx.setCurpage(1);
                getMainMails(path);
            }
        }
    }, [searchCtx.search]);

    const getMainMails = (_path, onlyunreadcount=0, _cat=-1) => {
        // if (!sendMessageIsOpen) {
        //     loadingCtx.setLoading(true);
        // }

        const query = { token: authCtx.token };
        // console.log(path, loadingCtx.category, onlyunreadcount);
        if (onlyunreadcount === 0 || onlyunreadcount === 1) {
            // getting unread counts
            getUnreadCounts(query)
            .then((res) => { 
                if (res.result) {                 
                    let {inbox, promotions, social, updates, spam, drafts, scheduled} = res.data;        
                                
                    setUnreadCounts({inbox: inbox, scheduled: scheduled, drafts: drafts, spam: spam, primary: inbox, promotions: promotions, social: social, updates: updates});                
                }
                else {
                    // console.log(res.message);
                }       
                // loadingCtx.setLoading(false);
            })
            .catch((e) => {
                console.log('getUnreadCounts error', e.message);
                // loadingCtx.setLoading(false);
            });
        }
       
        if (onlyunreadcount === 0 || onlyunreadcount === 2) {
            
            if (!sendMessageIsOpen) {
                loadingCtx.setLoading(true);
            }

            // getting mails with path
            console.log('getMails ', cat, _cat, _path, loadingCtx.category);
            query.path = _path;
            query.cat = (_cat !== -1)?_cat:loadingCtx.category;
            query.curpage = loadingCtx.curpage;
            query.filter = searchCtx.search; 
            // console.log(query);
            if(_path === "search" && query.filter === null) {
                query.filter = null;
            }
            // console.log('q', query);
            getMails(query)
            .then((res) => { 
                console.log("received mail from", query.path, query.cat);
                if (res.result) {  //  console.log(res.data); 
                    setEmails(
                        res.data.mails.map((doc) => {
                            let title = '';
                        
                            if(doc.from === authCtx.getAddress()) {   
                                if (doc.to !== '') {
                                    if (doc.to.substring(0,1) !== '[') {
                                        title = "To: " + doc.to.split('@')[0];
                                    } else {
                                        let to = JSON.parse(doc.to)
                                        to = to.map((ele) => {
                                            return ele.split('@')[0];
                                        });
                                        title = "To: " + to.join(", ");
                                    }
                                } else if (doc.cc !== '') {
                                    if (doc.cc.substring(0,1) !== '[') {
                                        title = "To: " + doc.cc.split('@')[0];
                                    } else {
                                        let cc = JSON.parse(doc.cc)
                                        cc = cc.map((ele) => {
                                            return ele.split('@')[0];
                                        });
                                        title = "To: " + cc.join(", ");
                                    }
                                } else if (doc.bcc !== '') {
                                    if (doc.bcc.substring(0,1) !== '[') {
                                        title = "To: " + doc.bcc.split('@')[0];
                                    } else {
                                        let bcc = JSON.parse(doc.bcc)
                                        bcc = bcc.map((ele) => {
                                            return ele.split('@')[0];
                                        });
                                        title = "To: " + bcc.join(", ");
                                    }
                                }                                            
                                
                                if (doc.reply_count > 1) {
                                    title = title + " " + doc.reply_count;        
                                }   

                                if (_path !== 'sent' && _path !== 'scheduled') {
                                    if (doc.reply_count > 1) {                                    
                                        if (doc.to.substring(0,1) !== '[') {
                                            title = "me, " + doc.to.split('@')[0] + " " + doc.reply_count;
                                        } else {
                                            let to = JSON.parse(doc.to)
                                            to = to.map((ele) => {
                                                return ele.split('@')[0];
                                            });
                                            title = "me, " + to.join(", ") + " " + doc.reply_count;
                                        }
                                    } else {
                                        title = "me"
                                    }
                                }

                                if (_path === 'drafts') {
                                    title = 'Draft';
                                }
                                
                            } else {
                                let _title = (doc.from_name === undefined || doc.from_name === "")?doc.from.split('@')[0]:doc.from_name;  
                                
                                if (doc.reply_count > 1) {
                                    title = _title + ", me";
                                    title = title + " " + doc.reply_count;       
                                } else {
                                    title = _title;
                                }

                                if (_path === 'sent') {
                                    // if (doc.from === authCtx.getAddress()) {
                                        if (doc.to.substring(0,1) !== '[') {
                                            _title = "To: " + doc.to.split('@')[0];
                                        } else {
                                            let to = JSON.parse(doc.to)
                                            to = to.map((ele) => {
                                                return ele.split('@')[0];
                                            });
                                            _title = "To: " + to.join(", ");
                                        }
                                        
                                    // } else {
                                    //     _title = "To: " + doc.from.split('@')[0];
                                    // }

                                    if (doc.reply_count > 1) {
                                        title = _title + " " + doc.reply_count;       
                                    } else {
                                        title = _title;
                                    }
                                }     
                            }

                            if (doc.from === authCtx.getAddress() && doc.to === authCtx.getAddress()) {
                                let _title = 'me';
                                if (doc.reply_count > 1) {
                                    title = _title + " " + doc.reply_count;       
                                } else {
                                    title = _title;
                                }
                            }
                            
                            let read = false;
                            read = (doc.is_read === 1)?true:false;
                            if (_path === 'drafts' || _path === 'scheduled') {
                                read = true;
                            }
                            let starred = false;
                            starred = (doc.is_starred === 1)?true:false;
                            let important = false;
                            important = (doc.is_important === 1)?true:false;
                            
                            return {
                                ...doc,                                
                                title: title,
                                read: read,
                                starred: starred,
                                important: important,
                                selected: false,
                                query: query.filter?.hasWords + " " + query.filter?.subject + " " + query.filter?.to + " " + query.filter?.from 
                            }
                        })
                    );
                    setMailpath(_path);
                    setTotal(res.data.total);
                    setPerpage(res.data.perpage);

                    if (!sendMessageIsOpen) {
                        loadingCtx.setLoading(false);
                    }
                }
                else {
                    console.log(res.message);
                }       
            })
            .catch((e) => {
                console.log('geMails error', e.message);
                loadingCtx.setLoading(false);
            });
        }
    }

    return !authCtx.isLogin() ? <Navigate replace to="/signin" /> :
    <>
        <Box className="app h-screen grid overflow-y-hidden" gridTemplateRows="auto 1fr">
            {isDesktop?<Header path={path} cat={cat} />:<HeaderM path={path} cat={cat} counts={unreadCounts} />}
            <Box className="app-body flex h-full overflow-y-hidden" style={{display : 'grid', gridTemplateColumns : 'auto 1fr'}}>  
                <MessageSnackbar {...messageSnackbarProps} />              
                { 
                    isDesktop
                    ?
                    <Sidebar counts={unreadCounts} active={path} />
                    :
                    sidebarIsOpen && <SidebarM className='transition-[width]' counts={unreadCounts} active={path} cat={cat} /> 
                }
                {
                    sidebarIsOpen
                    ?
                    ""
                    :
                    <>
                        {
                            (path === "mail")
                            ?
                            <WriteMailContextProvider>
                            {
                                isDesktop
                                ?
                                <Mail />
                                :
                                <MailM />
                            }
                            </WriteMailContextProvider>
                            :
                            <>
                            {
                                isDesktop
                                ?
                                <EmailList _emails={emails} _mailpath={mailpath} path={path} cat={cat} total={total} perpage={perpage} getMainMails={getMainMails}/>
                                :
                                <EmailListM _emails={emails} path={path} total={total} perpage={perpage} getMainMails={getMainMails}/>
                            }
                            </>
                        }   
                    </>
                }
            </Box>
            {
                sendMessageIsOpen 
                && 
                <WriteMailContextProvider>
                    {
                        isDesktop
                        ?
                        <SendMail path={path} getMainMails={getMainMails} />
                        :
                        <SendMailM path={path} getMainMails={getMainMails} />
                    }
                </WriteMailContextProvider>
            }
        </Box>
    </>;
}