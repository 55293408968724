
import { getStorage } from "./storage";
const storage = getStorage();

var connectId = 'sessionAccessId-connected';

export function get(event, data) {
    event.source.postMessage({
        id: data.id,
        data: storage.getItem(data.key)
    }, event.origin);
}

export function set(event, data) {
    storage.setItem(data.key, data.value);

    event.source.postMessage({
        id: data.id
    }, event.origin);
}

export function remove(event, data) {
    storage.removeItem(data.key);

    event.source.postMessage({
        id: data.id
    }, event.origin);
}

export function connect(event) {
    event.source.postMessage({
        id: connectId
    }, event.origin);
}