
import { useEffect, useState, useRef, useContext } from "react";
import { Button, FormHelperText, InputAdornment, Typography, Link } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import LoadingContext from '../../contexts/loadingContext';
import MuiPhoneNumber from 'material-ui-phone-number';
import parsePhoneNumber from 'libphonenumber-js';

import { sendSms, verifySms } from '../../apis';
import { getPhoneNumber } from '../../utils';

const t_inputError = "Please enter a phone number";
const t_invalidError = "This phone number format is not recognized. Please check the country and number.";
const t_failedError = "Failed to send verify code";
const t_expiredError = "Verification code is expired"
const t_verifyInputError = "Enter a verification code";
const t_verifyInvalidError = "Enter valid verification code";

export default function VerifyPhone({onNext, onBack, user, setUser}) {
    const loadingCtx = useContext(LoadingContext);

    const verifyRef = useRef(null);

    const [error, setError] = useState("");
    const [verifyError, setVerifyError] = useState("");

    const [phone, setPhone] = useState("");
    const [verifyCode, setVerifyCode] = useState("");
    const [encryptCode, setEncryptCode] = useState("");
    const [verifySent, setVerifySent] = useState(false);

    const [expireTime, setExpireTime] = useState(0);

    useEffect(() => {
        if (verifySent)
            verifyRef.current.focus();
    }, [verifySent]);

    const handleNext = () => {
        if (verifySent) {
            const sec = new Date().getTime() / 1000;
            if (verifyCode === "") {
                setVerifyError(t_verifyInputError);
                verifyRef.current.focus();
            }
            else if (sec >= expireTime) {
                setVerifyError(t_expiredError);
                verifyRef.current.focus();
            }
            else {
                loadingCtx.setLoading(true);
                setTimeout(() => {
                    verifySms(verifyCode, encryptCode)
                    .then((res) => {
                        if (res.result) {
                            setVerifyError("");
                            setUser({
                                ...user,
                                verifyPhone: getPhoneNumber(phone)
                            })
                            onNext();
                        }
                        else {
                            setVerifyError(t_verifyInvalidError);
                            verifyRef.current.focus();
                        }
                        loadingCtx.setLoading(false);
                    })
                    .catch((e) => {})
                }, 1000);
            }
        }
        else {
            const phoneNumber = parsePhoneNumber(phone);
            if (phoneNumber === undefined || phoneNumber.number === "") {
                setError(t_inputError);
                document.getElementById("signup-verify-phone").focus();
            }
            else if (phoneNumber.isValid() === false) {
                setError(t_invalidError);
                document.getElementById("signup-verify-phone").focus();
            }
            else {
                setError("");
                handleRequest();
            }
        }
    }

    const handleRequest = () => {
        loadingCtx.setLoading(true);
        setTimeout(() => {
            sendSms("", getPhoneNumber(phone), encryptCode)
                .then((res) => {
                    setEncryptCode(res.message);
                    setExpireTime(new Date().getTime() / 1000 + 120);
                    setError("");

                    setVerifySent(true);
                    setVerifyCode("");
                    setVerifyError("");
    
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {
                    setVerifySent(false);
                    setError(t_failedError);
                    document.getElementById("signup-verify-phone").focus();
    
                    loadingCtx.setLoading(false);
                })
        }, 1000)
    }
    
    return (
        <>
            <p className="text-2xl pb-2">Verify your phone number</p>
            <p className="text-base pb-4">For your security, Unimail wants to make sure it’s really you. Unimail will send a text message with a 6-digit verification code.</p>
            <div>
                <MuiPhoneNumber
                    id="signup-verify-phone"
                    defaultCountry={'in'}
                    variant="outlined"
                    fullWidth
                    disableDropdown={verifySent}
                    disabled={verifySent}
                    label="Phone number"
                    sx={{
                        "& .MuiInput-root::before": {borderBottom: 0},
                        "& .Mui-error": { marginLeft: 0, marginRight: 0 }
                    }}
                    error={error !== ""}
                    helperText={<HelperText error={error} />}
                    onChange={(e) => {
                        setPhone(e);
                    }}/>
            </div>
            {
                verifySent ?
                <div className="pt-4 pb-16">
                    <TextInput
                        label="Enter verification code"
                        value={verifyCode}
                        error={verifyError !== ""}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">U-</InputAdornment>,
                        }}
                        inputRef={verifyRef}
                        onChange={(e) => {
                            const re = /^[0-9]{0,6}$/;
                            // console.log(e.target.value);
                            // if value is not blank, then test the regex
                            if (e.target.value === '' || re.test(e.target.value)) {
                                setVerifyCode(e.target.value)
                            }
                        }} />
                    {
                        verifyError === "" ? "" :
                        <FormHelperText error>
                            <HelperText error={verifyError} />
                            {
                                verifyError === t_verifyInputError ? "" :
                                <Link
                                    component="button"
                                    underline="none"
                                    onClick={handleRequest}>
                                    <span className="pl-2">Request a new code</span>
                                </Link>
                            }
                        </FormHelperText>
                    }
                </div>
                :
                <Typography variant="body2" color="text.disabled" className="pt-2 pb-16">Standard rates apply</Typography>
            }
            <div className="flex">
                <Button variant="text" sx={{textTransform: "none"}} onClick={onBack}>Back</Button>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>
                    {verifySent ? "Verify" : "Next"}
                </Button>
            </div>
        </>
    );
}