import Axios from 'axios';

export const url = 'https://mail.unimail.in'; // window.location.hostname; // 'https://mail.unimail.in:3000'
// export const url = 'http://localhost:8080';

const axios = Axios.create({
	responseType: 'json',
	baseURL: url,
	headers: {
		'Content-Type': 'application/json',
		accept: 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});

axios.interceptors.response.use(function (res) {
	return res;
});

export default axios;
