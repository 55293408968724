
import { Box, Button } from '@mui/material';

export default function NoAccount({onStart}) {
    return (
        <>
            <p className="text-2xl text-center pb-2">No Account Found</p>
            <p className="text-base text-center pb-16">There's no Unimail Account with the info you provided.</p>
            <Box className="flex">
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={onStart}>Try again</Button>
            </Box>
        </>
    );
}