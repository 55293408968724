import React from "react";
import "./Section.css";
import { tabsClasses, Tooltip } from "@mui/material";

function Section({ Icon, title, color, selected }) {
  let tooltip = '';
  if (title === 'Primary')
    tooltip = "Person-to-person conversations and messages that don't appear in other tabs.";
  else if (title === 'Promotions')
    tooltip = "Marketing, interests, social and political causes, and other promotional emails.";
  else if (title === 'Social')
    tooltip = "Messages from social networks, media-sharing sites, online dating services and other social websites.";
  
    return (
    <div
      className={`section ${selected && "section--selected"}`}
      style={{
        borderBottom: `3px solid ${color}`,
        color: `${selected?color:'gray'}`,
      }}
    >
      <Icon />
      <Tooltip title={tooltip} placement="top-start">
        <h4>{title}</h4>
      </Tooltip>
    </div>
  );
}

export default Section;
