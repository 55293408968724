
import { useContext } from "react";
import { Typography } from '@mui/material';
import { ListItem } from '../../Components';
import LoadingContext from '../../contexts/loadingContext';

export default function ChooseAccount({accounts, goToSignIn}) {
    const loadingCtx = useContext(LoadingContext);

    const handleSignIn = (address) => {
        loadingCtx.setLoading(true);
        setTimeout(() => {
            goToSignIn(address);
            loadingCtx.setLoading(false);
        }, 1000);
    }

    return (
        <>
            <p className="text-2xl text-center pb-2">Choose an account</p>
            {
                accounts.map((item, i) => {
                    return <ListItem key={i} onClick={() => handleSignIn(item.address)}>
                        <Typography className="pb-1" variant="body1">
                            {`${item.firstName} ${item.lastName}`}
                        </Typography>
                        <Typography variant="body2" color="text.disabled">
                            {item.address}
                        </Typography>
                    </ListItem>
                })
            }
            <ListItem onClick={() => handleSignIn('')}>
                <Typography variant="body1" className="py-2">
                    Use another account
                </Typography>
            </ListItem>
        </>
    );
}