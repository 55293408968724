

import { Popover, Box, Typography, Divider, Button } from '@mui/material';
import { Avatar, OutlinedButton } from "../../Components";
import { selectOpenMail, selectSendMessageIsOpen, closeSendMessage } from "../../features/mailSlice";

import { _uploadProfile } from '../../apis';
import { useDispatch, useSelector } from "react-redux";
import { useContext, useRef } from "react";
import AuthContext from '../../contexts/authContext';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

export default function Profile({pos, show, setShow}) {
    const authCtx = useContext(AuthContext);
    const sendMessageIsOpen = useSelector(selectSendMessageIsOpen);
    const selectedMail = useSelector(selectOpenMail);
    const dispatch = useDispatch();

    const handleClose = (e) => {
        setShow(false);
        e.preventDefault();
    };

    const handleSignout = (e) => {
        if (sendMessageIsOpen) {
            dispatch(closeSendMessage());
        }
        setShow(false);
        authCtx.logout();
    }

    const coverRef = useRef(null);

    const handleCoverClick = (e) => {
        e.stopPropagation();
        coverRef.current.value = null;
        coverRef.current.click();
    }

    const handleUploadCover = (e) => {
        if (e.target.files.length === 0) return;
        _uploadProfile(authCtx.token, e.target.files[0])
        .then((res) => {
            if (res.success === 1){
                authCtx.setProfile(authCtx.profile + 1);
            }
        })
    }


    return <Popover
        sx={{
            "& .MuiPaper-root": {
                width: 360,
                borderRadius: 5,
                border: 'solid',
                borderWidth: '10px',
                borderColor: '#f2f6fc'
            }
        }}
        anchorReference="anchorPosition"
        anchorPosition={pos}
        open={show}
        disableEnforceFocus={true}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        onContextMenu={handleClose}
    >
        <Box className="pt-4 mx-auto table relative" onClick={handleCoverClick}>
            <Avatar size={64} isMe={true}/>
            <input
                type="file"
                accept="image/*"
                ref={coverRef}
                className="hidden"
                onChange={handleUploadCover}d
            />
            <CameraAltIcon sx={{fontSize: "2rem"}} className="absolute border border-slate-300 rounded-full text-slate-300 right-[0px] bottom-[0px] z-50 cursor-pointer"/>
        </Box>
        <Box className="p-4 text-center">
            <Typography variant="h6">{authCtx.getAccount()}</Typography>
            <Typography>{authCtx.getAddress()}</Typography>
        </Box>
        <Divider />
        <Box className="p-4 text-center">
            <OutlinedButton 
            grey={+true} 
            onClick={handleSignout}
            style={{
                textTransform: "none"
            }}>
                Sign out
            </OutlinedButton>
        </Box>
        <Divider />
        <Box className="p-4 flex">
            <Box className="ml-auto" />
            <Button
                variant="filled"
                style={{
                    fontSize: 13,
                    textTransform: "none"
                }}>
                <a href="/privacy" target="_blank">Private policy</a>
            </Button>
            <Typography>.</Typography>
            <Button
                variant="filled"
                style={{
                    fontSize: 13,
                    textTransform: "none"
                }}>
                <a href="/terms" target="_blank">Terms of service</a>
            </Button>
            <Box className="ml-auto" />
        </Box>
    </Popover>
}