
import { useState, useContext, useEffect } from 'react';

import DataTable from './DataTable';

import { getSharedWithMe } from './apis';

import AuthContext from '../../../contexts/authContext';

const fields = [
    {key: "name", label: "Name"},
    {key: "owner", label: "Shared By"},
    {key: "sharedDate", label: "Shared Date"},
]

export default function SharedWithMe({gridView}) {
    const authCtx = useContext(AuthContext);

    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);

    const refresh = () => {
        getSharedWithMe(authCtx.getAddress())
        .then((res) => {
            setFiles(res.files);
            setFolders(res.folders);
        })
        .catch((e) => {})
    }

    useEffect(() => {
        refresh();
    }, [])

    return <DataTable folders={folders} files={files} fields={fields} />
}