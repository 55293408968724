import { Button, IconButton, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import "./Sidebar.css";
import AddIcon from "@material-ui/icons/Add";
import StarIcon from "@material-ui/icons/Star";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import NearMeIcon from "@material-ui/icons/NearMe";
import NoteIcon from "@material-ui/icons/Note";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PersonIcon from "@material-ui/icons/Person";
import DuoIcon from "@material-ui/icons/Duo";
import PhoneIcon from "@material-ui/icons/Phone";

import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import InboxOutlinedIcon from '@mui/icons-material/InboxOutlined';
import InboxIcon from "@material-ui/icons/Inbox";
import PeopleIcon from "@material-ui/icons/People";
import InfoIcon from '@mui/icons-material/Info';
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import SidebarOptionM from "./SidebarOptionM";
import { Link as Link1 } from "react-router-dom";
import { Link as Link2 } from '@mui/material';
import { useDispatch } from "react-redux";
import { openSidebar, selectOpenMail } from "../../features/mailSlice";
import { useSelector } from "react-redux";
import { Divider } from "@mui/material";
import AuthContext from "../../contexts/authContext";

import LoadingContext from "../../contexts/loadingContext";

function SidebarM({ counts, active, cat }) {
  const dispatch = useDispatch();
  const selectedMail = useSelector(selectOpenMail);
  const authCtx = useContext(AuthContext);
  const loadingCtx = useContext(LoadingContext);

  if(active === 'mail')
    active = selectedMail?.path;
  
  return (
    <div className="sidebar-container-mobile">
      <div>
        <Typography style={{ paddingLeft: '20px', paddingTop: '5px', paddingBottom: '5px' }}>Labels</Typography>
        <div className="sidebar-mobile">
          <Divider />
          <Link1 to="/primary" className="sidebar-link" onClick={() => {loadingCtx.setCategory(0);loadingCtx.setCurpage(1);dispatch(openSidebar())}} >
            <SidebarOptionM
              Icon={InboxIcon}
              title="Primary"
              number={(counts.primary > 0)?counts.primary:''}
              selected={(active === "inbox" && cat === 0)?true:false}
            />
          </Link1>
          <Divider />
          <Link1 to="/promotions" className="sidebar-link" onClick={() => {loadingCtx.setCategory(1);loadingCtx.setCurpage(1);dispatch(openSidebar())}}>
            <SidebarOptionM
              Icon={LocalOfferIcon}
              title="Promotions"
              number={(counts.promotions > 0)?counts.promotions:''}
              selected={(active === "inbox" && cat === 1)?true:false}
            />
          </Link1>
          <Divider />
          <Link1 to="/social" className="sidebar-link" onClick={() => {loadingCtx.setCategory(2);loadingCtx.setCurpage(1);dispatch(openSidebar())}}>
            <SidebarOptionM
              Icon={PeopleIcon}
              title="Social"
              number={(counts.social > 0)?counts.social:''}
              selected={(active === "inbox" && cat === 2)?true:false}
            />
          </Link1>
          <Divider />
          <Link1 to="/updates" className="sidebar-link" onClick={() => {loadingCtx.setCategory(3);loadingCtx.setCurpage(1);dispatch(openSidebar())}}>
            <SidebarOptionM
              Icon={InfoIcon}
              title="Updates"
              number={(counts.updates > 0)?counts.updates:''}
              selected={(active === "inbox" && cat === 3)?true:false}
            />
          </Link1>
          <Divider />
          <Link1 to="/sent" className="sidebar-link" onClick={() => {loadingCtx.setCategory(0);loadingCtx.setCurpage(1);dispatch(openSidebar())}}>
            <SidebarOptionM Icon={SendOutlinedIcon} title="Sent" number={''} selected={(active === "sent")?true:false} />
          </Link1>
          <Divider />
          <Link1 to="/spam" className="sidebar-link" onClick={() => {loadingCtx.setCategory(0);loadingCtx.setCurpage(1);dispatch(openSidebar())}}>
            <SidebarOptionM Icon={ReportGmailerrorredOutlinedIcon} title="Spam" number={(counts.spam > 0)?counts.spam:''} selected={(active === "spam")?true:false} />
          </Link1>
          <Divider />
          <Link1 to="/trash" className="sidebar-link" onClick={() => {loadingCtx.setCategory(0);loadingCtx.setCurpage(1);dispatch(openSidebar())}}>
            <SidebarOptionM Icon={DeleteOutlineOutlinedIcon} title="Trash" number={''} selected={(active === "trash")?true:false} />
          </Link1>   
          <Divider />   
        </div>
      </div>
      <div>
        <Typography style={{ paddingLeft: '20px', paddingTop: '5px', paddingBottom: '5px' }}>Apps</Typography>
        <div className="sidebar-mobile">
          <Divider />
          <Link2 href="https://drive.unimail.in" target="_blank" underline="none" className="flex flex-row justify-start items-center p-1 sidebar-link">
              <img className="p-1 pl-2 h-[45px] py-[5px]" src="/drive-logo.png" alt="drive" />
              <Typography className="!text-md !text-neutral-800 pl-2">Drive</Typography>
          </Link2>  
          <Divider />  
          <Link2 href="https://docs.unimail.in/writer" target="_blank" underline="none" className="flex flex-row justify-start items-center p-1 sidebar-link">
              <img className="p-1 pl-2 h-[45px] py-[5px]" src="/doc-logo.svg" alt="docs" />
              <Typography className="!text-md !text-neutral-800 pl-2">Docs</Typography>
          </Link2>     
          <Divider /> 
          <Link2 href="https://docs.unimail.in/spreadsheet" target="_blank" underline="none" className="flex flex-row justify-start items-center p-1 sidebar-link">
              <img className="p-1 pl-2 h-[45px] py-[5px]" src="/sheet-logo.svg" alt="sheets" />
              <Typography className="!text-md !text-neutral-800 pl-2">Sheets</Typography>
          </Link2>    
          <Divider />  
          <Link2 href="https://docs.unimail.in/ppt" target="_blank" underline="none" className="flex flex-row justify-start items-center p-1 sidebar-link">
              <img className="p-1 pl-2 h-[45px] py-[5px]" src="/slide-logo.svg" alt="slides" />
              <Typography className="!text-md !text-neutral-800 pl-2">Slides</Typography>
          </Link2>      
          <Divider /> 
        </div>
      </div>
      <div className="flex justify-center mt-2">
        <Typography variant="h6">{authCtx.getAddress()}</Typography>
      </div>
      <div className="flex justify-center mt-2">
        <Typography variant="body2">©2023  unimail</Typography>
      </div>
    </div>
    
  );
}

export default SidebarM;
