

import ThemeImage from './ThemeImage';

export default function UniMail() {
  return <div className="text-center pb-4">
    <ThemeImage className="w-[128px] inline-block"
      source="uni_mail.png"
      alt="Uni Mail" />
  </div>
}