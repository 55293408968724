
import { useEffect, useContext, useState } from 'react';

import { Box, Divider, IconButton, Link, Typography } from '@mui/material';
import { Avatar } from "../../Components";
import MenuContext from '../../contexts/menuContext';
import AuthContext from '../../contexts/authContext';
import AppsIcon from "@material-ui/icons/Apps";
import { getDomainLogo } from '../../apis';

export default function Header({path}) {
    const authCtx = useContext(AuthContext);
    const menuCtx = useContext(MenuContext);
    const [ state, setState ] = useState('');
    
    const [logo, setLogo] = useState("");
    
    const domain = authCtx.getAddress().split('@')[1];
    const domain_name = domain.split('.')[0].charAt(0).toUpperCase() + domain.split('.')[0].slice(1);

    useEffect(() => {
        // console.log(domain)
        getDomainLogo(domain)
        .then((res) => { 
        if (res.result) {   
            //  console.log(res); 
            setLogo("/" + res.logo);
            // console.log('logo:', logo);
        } else {
            console.log(res.message);
        } 
        })
        .catch((e) => {});
    }, [domain])

    let title = '';
    if (path === 'help')
        title = 'Help';
    else if (path === 'terms')
        title = 'Terms';
    else if (path === 'privacy')
        title = 'Privacy';
    
    return <>
        <Box className="flex bg-slate-50" >
            <Box className="w-[340px] flex items-center">
                <Link href="/" underline="none" className='flex items-center'>
                    <img className="p-4 pr-2 h-[64px]" src="unimail-logo.svg" alt="Unimail" />                    
                </Link>
                <Typography variant="h6" className="text-gray-800 !font-medium">Privacy & Terms</Typography>
            </Box>
            <Box className="flex items-center w-full pl-16">                
            </Box>
            <Box className="flex items-center">
                <Box className="ml-auto" />
                <IconButton onClick={(e) => menuCtx.showApps(e)}>
                    <AppsIcon />
                </IconButton>
                {/* <IconButton size="small" onClick={(e) => menuCtx.showProfile(e)}>
                    <Avatar size={36} isMe={true}/>
                </IconButton> */}
                {
                logo === '/' 
                ? 
                <IconButton size="small" onClick={(e) => menuCtx.showProfile(e)}>
                    <Avatar size={32} isMe={true}/>
                </IconButton>
                :
                <div className="flex items-center justify-center border rounded-lg border-slate-200 border-solid pr-1">         
                    <img className="py-2 pr-2 pl-2 h-[48px]" src={`/domain/${logo}`} alt={domain_name} /> 
                    <IconButton size="small" onClick={(e) => menuCtx.showProfile(e)}>
                        <Avatar size={32} isMe={true}/>
                    </IconButton>
                </div> 
                } 
                <Box className="pl-4" />
            </Box>
        </Box>
        {/* <Divider /> */}
    </>;
}