import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Help() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Creating an Unimail Account
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Step 1: Click on "Sign Up" or "Create Account"
Locate the "Sign Up" or "Create Account" button on the Unimail homepage. Click on it to begin the registration process.

Step 2: Provide Your Information
Fill out the registration form with the required information. This usually includes:
- Full Name: Enter your first and last name.
- Email Address: Choose a unique email address that will serve as your username for logging into Unimail.
- Password: Create a strong password to secure your account. Make sure it meets the specified criteria (e.g., a minimum number of characters, a combination of letters, numbers, and symbols).
-Verify Phone: Verify the recovery phone number.Enter the 	Otp to received to the provided phone number and verify otp.	
- Additional Information: Depending on the registration form, you may be asked to provide additional details such as your date of birth, phone number, recovery email address, gender.

Step 3: Agree to the Terms of Service and Privacy Policy
Read and accept the Terms of Service and Privacy Policy by checking the corresponding checkboxes. It's important to understand the terms and conditions governing the use of Unimail.


Step 4: Congratulations! Start Using Unimail
You have successfully created your Unimail account! Log in with your email address and password to access your inbox, start sending and receiving emails, and explore the various features and functionalities of Unimail.

Additional Tips:
- Keep your account credentials (email address and password) confidential and avoid sharing them with anyone.
- Remember to log out of your account when using Unimail on public or shared devices to maintain account security.
- If you encounter any issues during the account creation process, refer to the "Troubleshooting" section of our help center or contact our customer support for assistance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Compose mail</Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Step 1: Click on the "Compose" Button
Look for the "Compose" button, usually located prominently on the top or left side of the interface. Click on it to start composing your email.

Step 2: Fill in the Recipient(s)
In the "To" field, enter the email address(es) of the recipient(s). You can enter multiple email addresses.
Step 3: Add a Subject
Provide a brief and descriptive subject for your email in the "Subject" field. The subject helps recipients understand the purpose or topic of your email.

Step 4: Compose Your Message
In the main body of the email, compose your message. Use the formatting tools available (such as font styles, bullet points, and alignments) to customize the appearance of your text. You can also include links, images, or attachments as needed.

Step 5: Review and Edit
Before sending, review your email for any spelling or grammatical errors. Ensure that the content is clear, concise, and conveys your intended message accurately. Make any necessary edits or additions.

Step 6: Attach Files (if needed)
If you want to include attachments, look for the "Attach" or "Insert" button, usually represented by a paperclip icon. Click on it to browse and select the files you wish to attach. Follow any prompts or instructions to complete the attachment process.

Step 7: Send the Email
Once you are satisfied with your email, click on the "Send" or "Send Email" button to send it. The email will be delivered to the recipient(s) you specified in the "To" field.



Additional Tips:

Double-check the recipient's email address to ensure accuracy and prevent misdirected emails.
Use the CC (Carbon Copy) or BCC (Blind Carbon Copy) fields to include additional recipients if needed. CC notifies all recipients of each other's email addresses, while BCC keeps recipients' email addresses hidden.
If you want to save a draft of your email and send it later, look for the "Save Draft" or "Drafts" option. Your email will be saved in the "Drafts" folder until you are ready to send it.
If you encounter any difficulties or have questions about sending emails, refer to the "Help" or "Support" section of the Unimail website for further assistance.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            Advanced settings
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            ...
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>Personal data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}