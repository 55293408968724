
import { useState, useContext, useEffect } from 'react';

import DataTable from './DataTable';

import { getRecent } from './apis';

import AuthContext from '../../../contexts/authContext';

const fields = [
    {key: "name", label: "Name"},
    {key: "owner", label: "Owner"},
]

export default function Recent({gridView}) {
    const authCtx = useContext(AuthContext);

    const [files, setFiles] = useState([]);

    const refresh = () => {
        getRecent(authCtx.getAddress())
        .then((res) => {
            setFiles(res.files);
        })
        .catch((e) => {})
    }

    useEffect(() => {
        refresh();
    }, [])

    return <DataTable files={files} fields={fields} />
}