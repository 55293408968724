
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom';

import { Container, UniMail } from '../../Components';
import ChooseMethod from './ChooseMethod';
import VerifyCode from './VerifyCode';
import CreatePassword from './CreatePassword';

import AuthContext from '../../contexts/authContext';

export default function PasswordRecovery() {
    const navigate = useNavigate();
    const location = useLocation();
    const authCtx = useContext(AuthContext);

    const [step, setStep] = useState(0);
    const [contact, setContact] = useState({
        address: "",
        phone: ""
    });
    const [encryptCode, setEncryptCode] = useState("");
    const [recoveryContact, setRecoveryContact] = useState({});

    const onNext = () => {
        setStep(step + 1);
    }

    const onBack = () => {
        setStep(step - 1);
    }

    const goToSignIn = () => {
        navigate('/signin');
    }

    const goToHome = (token) => {
        authCtx.setToken(token);
        navigate('/');
    }

    useEffect(() => {
        if (location.state?.contact) {
            setContact(location.state.contact);
        }
        else {
            navigate('/signin');
        }
    }, [location])

    const render = () => {
        if (step === 0)
            return <ChooseMethod goToSignIn={goToSignIn} setEncryptCode={setEncryptCode} setRecoveryContact={setRecoveryContact} onNext={onNext} contact={contact} />
        else if (step === 1)
            return <VerifyCode goToSignIn={goToSignIn} onBack={onBack} onNext={onNext} verifyData={encryptCode} contact={contact} recoveryContact={recoveryContact} />
        else if (step === 2)
            return <CreatePassword goToHome={goToHome} goToSignIn={goToSignIn} contact={contact} />
    }

    return (
        <Container>
            <UniMail />
            <div className="sm:w-[370px]">
                {render()}
            </div>
        </Container>
    );
}