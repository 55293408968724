import { Divider } from "@mui/material";
import React from "react";
import "./SidebarOptionM.css";

function SidebarOptionM({ Icon, title, number, selected }) {
  return (
    <>
      <div className={`sidebarOption-mobile ${selected && "sidebarOption-mobile--active"}`}>
        <Icon style={{ marginRight: '10px' }} />
        <h3>{title}</h3>
        {(number>0)?<p>{number}</p>:''}      
      </div>
    </>
  );
}

export default SidebarOptionM;
