
import { useState, useRef, useContext } from "react";
import { Box, Button, FormHelperText } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import LoadingContext from '../../contexts/loadingContext';

import { findForgottenUser } from '../../apis';

const t_firstNameError = "Enter first name";
const t_lastNameError = "Enter last name";

export default function InputName({onBack, onNext, contact, setVerifyData, onError}) {
    const loadingCtx = useContext(LoadingContext);

    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const [error, setError] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const handleNext = () => {
        let focusObj = null;

        if (firstName === "") {
            setError(t_firstNameError);
            focusObj = firstNameRef.current;
        }
        else if (lastName === "") {
            setError(t_lastNameError);
            focusObj = lastNameRef.current;
        }

        if (focusObj === null) {
            setError("");
            loadingCtx.setLoading(true);
            setTimeout(() => {
                findForgottenUser(firstName, lastName, contact.address, contact.phone)
                .then((res) => {
                    if (res.result) {
                        setError("");
                        setVerifyData(res.message);
                        onNext();
                    }
                    else
                        onError();
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {})
            }, 1000);
        }
        else {
            focusObj.focus();
        }
    }
    
    return (
        <>
            <p className="text-2xl text-center pb-2">What's your name?</p>
            <p className="text-base text-center pb-8">Enter the name on your Unimail Account</p>
            <Box className="pb-8">
                <TextInput
                    label="First name"
                    value={firstName}
                    size="default"
                    onChange={(e) => {
                        setFirstName(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleNext();
                        }
                    }}
                    error={error !== "" && firstName === ""}
                    inputRef={firstNameRef} />
                <Box className="pb-8" />
                <TextInput
                    label="Last name"
                    value={lastName}
                    size="default"
                    onChange={(e) => {
                        setLastName(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleNext();
                        }
                    }}
                    error={error !== "" && lastName === ""}
                    inputRef={lastNameRef} />
                <FormHelperText error={error !== ""}>
                    <HelperText error={error} />
                </FormHelperText>
            </Box>
            <Box className="flex">
                <Button variant="text" sx={{textTransform: "none"}} onClick={onBack}>Back</Button>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Next</Button>
            </Box>
        </>
    );
}