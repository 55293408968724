import FileItem from "./preview/FileItem";
import {useState, useContext} from 'react';
import Modal from '@mui/material/Modal';
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreviewItem from "./preview";
import { Button, IconButton, Tooltip, div, Typography, useMediaQuery } from "@mui/material";
import { FileIcon, defaultStyles } from 'react-file-icon';
import LoadingContext from "../../../contexts/loadingContext";
import AuthContext from "../../../contexts/authContext";
import DialogContext from "../../../contexts/dialogContext";
import { downLoad, openWithLibre, addToAttachInfo } from "../../../apis";
import fileDownload from "js-file-download";
import { url } from "../../../@axios";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const google = ["doc", "docx", "xls", "xlsx", "ppt", "pptx"];

export const getFileExt = (str) => {
    return str.split('.').pop();
}

const style = {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height : '100%',
};

export default function FileViewer({show, setShow, oid, filename, fileid}) {
        //-----to display file viewer modal-----
    const closeModal = () => setShow(false);
    const loadingCtx = useContext(LoadingContext);
    const authCtx = useContext(AuthContext);
    const diagCtx = useContext(DialogContext);
    const extension = getFileExt(filename);
    const [viewMode, setViewMode] = useState(0);

    //--------------------------------------
    const [age, setAge] = useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleDownloadClick = async (oid, filename, fileid) => {
        // console.log(oid, filename, fileid);
        loadingCtx.setLoading(true); 
        downLoad(authCtx.token, oid, fileid)
        .then((res) => { 
            loadingCtx.setLoading(false);
            fileDownload(res.data, filename);
        })
        .catch((e) => {
            loadingCtx.setLoading(false);
        });
        loadingCtx.setLoading(false);
    };

    const inArray = (arr, val) => {
        for (let i = 0; i < arr.length; i ++) {
            if (arr[i] === val)
                return true;
        }
        return false;
    }

    const getUrl = () => {
        let query = {
            token: authCtx.token,
            oid: oid,
            fileid: fileid
        };
        query = JSON.stringify(query);
        return `${url}/action_download/?param=${encodeURIComponent(query)}`;
    }
    const handleUploadToDriveAndDatabase = (e) => {
        e.stopPropagation();
        fetch(getUrl())
        .then(res => res.arrayBuffer())
        .then(buffer => {
            const file = new File([buffer], filename, {
                type: "text/plain",
            });
            diagCtx.setInitFileList([file]);
            diagCtx.showInsertDrive((link, name) => { 
                const parts = link.split("/");
                const lastPart = parts[parts.length - 1];
                addToAttachInfo(authCtx.token, oid, fileid, filename, lastPart).then((res) => {
                    window.open(`https://docs.unimail.in/spreadsheet/${lastPart}`, '_blank', 'noopener,noreferrer');
                })
            }, 3);
        });
    }

    const changeViewMode = (e) => {
        e.stopPropagation();
        
        if(e.target.value == 1){
            openWithLibre(authCtx.token, oid, fileid).then((res) => { 
                if(res.success) // if is registered to unidrive
                    window.open(`https://docs.unimail.in/spreadsheet/${res.url}`, '_blank', 'noopener,noreferrer');
                else{
                    handleUploadToDriveAndDatabase(e);
                }
            })
        }
        if(e.target.value == 2){
            window.open(`https://docs.google.com/viewer?url=${getUrl()}&embedded=true`, '_blank', 'noopener,noreferrer');
        }
    }

  return <div
            className="absolute top-[0px] left-[0px] right-[0px] bottom-[0px] bg-[#202124d0] flex flex-col"
            style={{display : (show ? 'flex' : 'hidden')}}
            onClick={closeModal}
        >
            <div className="px-4 py-[6px] grid grid-cols-3" style={{background: "linear-gradient(to bottom,rgba(50,50,50,.65) 0%,transparent 100%)"}}>
                <div className="flex items-center">
                    <IconButton size="small" sx={{
                        "& svg": {
                            color: "white",
                        },
                        "&:hover": {
                            border: 0,
                            backgroundColor: 'rgba(150,150,150)',
                            boxShadow: "0 1px 3px 0 rgba(50,50,50,.7),0 4px 8px 3px rgba(50,50,50,.7)"
                        },
                    }}>
                        <ArrowBackIcon/>
                    </IconButton>
                    <div style={{fontSize : 22, height : '1em', minWidth : '1em', width : '1em', color : 'dimgrey',  marginLeft : '8px'}}>
                        <FileIcon extension={getFileExt(filename)} {...defaultStyles[getFileExt(filename)]} width="auto" />
                    </div>
                    <div style={{color : 'white', marginLeft : 10, paddingRight : 1}}>
                        {filename}
                    </div>
                </div>
                <div className="flex items-center justify-center" onClick={(e) => e.stopPropagation()}>
                    {
                        inArray(google, extension) &&
                        <select value={viewMode} onChange={(e) => changeViewMode(e)} className="py-[5px] bg-black text-white text-center rounded-[5px]">
                            <option value={0}>Open with</option>
                            <option value={1}>Open with Uni Office</option>
                            <option value={2}>Open with Google Office</option>
                        </select>
                    }
                </div>
                <div className="flex items-center ml-auto" onClick={(e) => {e.stopPropagation(); handleDownloadClick(oid, filename, fileid);}}>
                    <IconButton size="small" sx={{
                        "& svg": {
                            color: "white",
                        },
                        "&:hover": {
                            border: 0,
                            backgroundColor: 'rgba(150,150,150)',
                            boxShadow: "0 1px 3px 0 rgba(50,50,50,.7),0 4px 8px 3px rgba(50,50,50,.7)"
                        },
                    }}>
                        <DownloadIcon />
                    </IconButton>
                </div>
            </div>
            <div className="h-full flex" width={960}>
                <PreviewItem oid={oid} filename={filename} fileid={fileid} />
            </div>
        </div>
}