
import { useState, useRef, useContext, useEffect } from "react";
import { Link, Box, Button, Typography } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import LoadingContext from '../../contexts/loadingContext';
import AuthContext from "../../contexts/authContext";
import { findUser } from '../../apis';
import { getMailOrPhone  } from '../../utils';

const t_inputError = "Enter an email or phone number";
const t_invalidError = "Enter a valid email or phone number";
const t_existError = "Couldn't find your activated mail account";

export default function Login({onNext, setContact}) {
    const loadingCtx = useContext(LoadingContext);
    const authCtx = useContext(AuthContext);
    const addrRef = useRef(null);
    const [error, setError] = useState("");
    const [address, setAddress] = useState("");

        

    useEffect(() => {
        if (authCtx.address !== '' && authCtx.address !== undefined && authCtx.address !== null) {
            setAddress(authCtx.address);
        }        
    }, [authCtx.address])

    const handleNext = () => {
        const contact = getMailOrPhone(address);
        if (address === "") {
            setError(t_inputError);
            addrRef.current.focus();
        }
        else if (contact === null) {
            setError(t_invalidError);
            addrRef.current.focus();
        }
        else {
            setError("");
            loadingCtx.setLoading(true);
            setTimeout(() => {
                findUser("", "", contact.address, contact.phone)
                .then((res) => {
                    if (res.result) {
                        setError("");
                        setContact(contact)
                        onNext();
                    }
                    else {
                        setError(t_existError);
                        addrRef.current.focus();
                    }
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {})
            }, 1000);
        }
    }
    
    return (
        <>
            <p className="text-2xl text-center pb-2">Sign In</p>
            <p className="text-base text-center pb-4">to continue to UniMail</p>
            <Box className="pb-2">
                <TextInput
                    label="Email or phone"
                    value={address}
                    size="default"
                    onChange={(e) => {
                        setAddress(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleNext();
                        }
                    }}
                    error={error !== ""}
                    helperText={<HelperText error={error} />}
                    inputRef={addrRef} />
            </Box>
            <Box className="pb-16">
                <Link href="/mailrecovery" underline="none">Forgot email?</Link>
            </Box>
            <Typography variant="body2" color="text.disabled">Not your computer? Use Guest mode to sign in privately.</Typography>
            <Box className="pb-16">
                {/* <Link href="#" underline="none">Learn more</Link> */}
            </Box>
            <Box className="flex">
                <Link href="/signup" underline="none">Create account</Link>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Next</Button>
            </Box>
        </>
    );
}