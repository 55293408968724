
import { useState, useRef, useContext } from "react";
import { Box, Button } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import LoadingContext from '../../contexts/loadingContext';
import { getMailOrPhone } from '../../utils';

const t_inputError = "Enter a valid email or phone number";

export default function InputContact({onNext, setContact}) {
    const loadingCtx = useContext(LoadingContext);

    const addrRef = useRef(null);
    const [error, setError] = useState("");
    const [address, setAddress] = useState("");

    const handleNext = () => {
        const contact = getMailOrPhone(address);
        if (contact === null) {
            setError(t_inputError);
            addrRef.current.focus();
        }
        else {
            setError("");
            loadingCtx.setLoading(true);
            setTimeout(() => {
                setContact(contact)
                onNext();
                loadingCtx.setLoading(false);
            }, 1000);
        }
    }
    
    return (
        <>
            <p className="text-2xl text-center pb-2">Find your email</p>
            <p className="text-base text-center pb-8">Enter your phone number or recovery email</p>
            <Box className="pb-8">
                <TextInput
                    label="Phone number or email"
                    value={address}
                    size="default"
                    onChange={(e) => {
                        setAddress(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleNext();
                        }
                    }}
                    error={error !== ""}
                    helperText={<HelperText error={error} />}
                    inputRef={addrRef} />
            </Box>
            <Box className="flex">
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Next</Button>
            </Box>
        </>
    );
}