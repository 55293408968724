import { Checkbox, IconButton } from "@material-ui/core";
import React, {useState, useContext, useRef} from "react";
import "./EmailRow.css";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import LabelImportantOutlinedIcon from "@material-ui/icons/LabelImportantOutlined";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import GradeIcon from '@mui/icons-material/Grade';
import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import LabelImportantTwoToneIcon from '@mui/icons-material/LabelImportantTwoTone';
////////// for test of spam /////////
import ReportGmailerrorredOutlinedIcon from '@mui/icons-material/ReportGmailerrorredOutlined';
/////////////////////////////
import { useNavigate } from "react-router-dom";
import { selectMail } from "../../features/mailSlice";
import { useDispatch } from "react-redux";
import LoadingContext from '../../contexts/loadingContext';
import moment from "moment";
import { getEmailDateTimeFormat, highlightText } from '../../utils';
import { setStatusMail, deleteDraftMail } from '../../apis';
import AuthContext from '../../contexts/authContext';
import SearchContext from '../../contexts/searchContext';
import { openSendMessage, selectOpenMail } from "../../features/mailSlice";
import { FileIcon, defaultStyles } from 'react-file-icon';
import Tooltip from '@mui/material/Tooltip';

function EmailRow({ item, path, onSetStatusMail, onChangeSelectedRow }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadingCtx = useContext(LoadingContext);
  const searchCtx = useContext(SearchContext);
  const authCtx = useContext(AuthContext);
  
  const handleChangeStateClick = (e) => {    
    let id = '';
    if (path === 'drafts')
      id = item._id;
    else
      id = item.message_id;
    onChangeSelectedRow(id, e.target.checked);
  };

  const openMail = () => {
    if (path === 'drafts') {
      dispatch(openSendMessage());
      dispatch(selectMail({id: item._id, is_draft: '1'}));
      loadingCtx.setMaximizeEmail(loadingCtx.maximizeEmail + 1);
    } else {
      dispatch(
        selectMail({
          id: item._id,
          from: item.from,
          to: item.to,
          cc: item.cc,
          bcc: item.bcc,
          subject: item.subject,
          text: item.message,
          html: item.html,
          message_id: item.message_id,
          in_reply_to: item.in_reply_to,
          reference: item.reference,
          attach: item.attach,
          time: getEmailDateTimeFormat(item.date, 1),
          path: path,
          query: item.query
        })
      );
      onSetStatusRow('is_read', 1, 1);
      navigate("/mail");
    }
    
  };

  const onSetStatusTrashedRow = (path) => {
    if (path === 'drafts') {
      deleteDraftMail({token: authCtx.token, oid: item._id})
      .then((res) => {
        // console.log(res);
        
        if(res.result) {
          console.log('draft message deleted with close', res.data);               
        } else {
          console.log('draft message deleting failed with close');
        }        
        onSetStatusMail();               
      })
      .catch((e) => { console.log(e) });
     
    } else {
      onSetStatusRow('is_trashed', 1);
    }
  }

  const onSetStatusRow = (field, value, onlyunreadcount=0) => {
    
    setStatusMail(authCtx.token, item.message_id, field, value)
    .then(function(res) { 
      if (res.result) {
        console.log("Document successfully updated!");
        onSetStatusMail(onlyunreadcount);
        // result.data = { ...result.data, selected: false};
      }
    })
    .catch(function(error) {
        console.error("Error updating document: ", error);
        onSetStatusMail(onlyunreadcount);
    }); 
    
  };

  const renderRowActions = () => {
    let render = <></>
    if (path === "inbox" || path === "sent" || path === "starred" || path === "important" || path === "spam" || path === "drafts") {
      render = 
        <>
          <Tooltip title="Delete" placement="bottom">
            <IconButton className="emailRow-action-button" onClick={(e) => {e.stopPropagation();onSetStatusTrashedRow(path)}} disableRipple>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
    } else if (path === "trash") {
      render = 
        <>
          <Tooltip title="Restore" placement="bottom">
            <IconButton className="emailRow-action-button" onClick={(e) => {e.stopPropagation();onSetStatusRow('is_trashed', 0)}} disableRipple>
              <RestoreFromTrashOutlinedIcon />
            </IconButton>
          </Tooltip>
        </>
    }
    return <>          
      { render }
      {
        (path !== "drafts" && path !== "scheduled") &&
        (item.read?(
          <Tooltip title="Mark as unread" placement="bottom">
            <IconButton className="emailRow-action-button" onClick={(e) => {e.stopPropagation();onSetStatusRow('is_read', 0)}} disableRipple>
              <MarkunreadOutlinedIcon />
            </IconButton>
          </Tooltip>
        ):(
          <Tooltip title="Mark as read" placement="bottom">
            <IconButton className="emailRow-action-button" onClick={(e) => {e.stopPropagation();onSetStatusRow('is_read', 1)}} disableRipple>
              <DraftsOutlinedIcon />
            </IconButton>
          </Tooltip>
        ))
      }           
    </>  
  }

  function classNames(...args) {
    return args.filter(Boolean).join(' ')
  }

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const renderAttachments = () => {
    const attachments = JSON.parse(item.attach);    
    if (Array.isArray(attachments) && attachments.length > 0){
      return (
        <div className="attachments">
          {
            attachments.map((element, index) => (      
              (element.hasOwnProperty('filename'))
              ?    
              <Tooltip key={ index } title={element?.filename} placement="bottom">
                <div className="attachment">
                  <FileIcon className="file-icon" extension={element?.filename.split('.')[element?.filename.split('.').length-1]} {...defaultStyles[element?.filename.split('.')[element?.filename.split('.').length-1]]} />
                  <div className="file-name">{ element?.filename }</div>
                </div>
              </Tooltip>
              :
              ''
            ))
          }
        </div>
      );      
    }        
  }

  var div = document.createElement("div");
  div.innerHTML = item.message.trim();
  var message_text = div.textContent || div.innerText || "";

  return (
    <div className={classNames('emailRow', item.read && "emailRow-read", (item.selected) && "emailRow-selected")} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={openMail}>
      <div className="emailRow-options">
        <Tooltip title="Select" placement="bottom">
          <Checkbox 
          style={{ paddingTop: '0px', paddingBottom: '0px', backgroundColor: 'transparent', alignItems: 'flex-start' }}
          checked={item.selected}
          onChange={handleChangeStateClick}
          disableRipple
          onClick={(e) => e.stopPropagation()}
          /> 
        </Tooltip> 
        {
          item.starred?(
            <Tooltip title="Starred" placement="bottom">
              <IconButton className="emailRow-action-button" style={{paddingLeft: '5px', paddingRight: '5px'}} onClick={(e) => {e.stopPropagation();onSetStatusRow('is_starred', 0)}} disableRipple>
                <GradeIcon sx={{color: "#FC0"}} />
              </IconButton>
            </Tooltip>
          ):(
            <Tooltip title="Not starred" placement="bottom">
              <IconButton className="emailRow-action-button" style={{paddingLeft: '5px', paddingRight: '5px'}} onClick={(e) => {e.stopPropagation();onSetStatusRow('is_starred', 1)}} disableRipple>
                <GradeTwoToneIcon />
              </IconButton>
            </Tooltip>
          )
        }
        {
          item.important?(
            <Tooltip title="Important" placement="bottom">
              <IconButton className="emailRow-action-button" style={{paddingLeft: '5px', paddingRight: '5px'}} onClick={(e) => {e.stopPropagation();onSetStatusRow('is_important', 0)}} disableRipple>
                <LabelImportantIcon sx={{color: "#FC0"}} />
              </IconButton>
            </Tooltip>
          ):(
            <Tooltip title="Click to be important" placement="bottom">
              <IconButton className="emailRow-action-button" style={{paddingLeft: '5px', paddingRight: '5px'}} onClick={(e) => {e.stopPropagation();onSetStatusRow('is_important', 1)}} disableRipple>
                <LabelImportantTwoToneIcon />
              </IconButton>
            </Tooltip>
          )
        }    
      </div>
      <div className={classNames("emailRow-title", item.read && "emailRow-main-read")}>
        {highlightText(item.title, item.query)}
      </div>
      <div className="emailRow-message">
        <div className="content">
          <span className={classNames("emailRow-subject", item.read && "emailRow-main-read")}>
            {highlightText(item.subject, item.query)}&nbsp;
          </span>
          {(message_text !== '')?<span className="emailRow-description"> - {highlightText(message_text, item.query)}</span>:null}
        </div>  
        {
          renderAttachments()
        }        
      </div>
      
      { 
        isHovering?
        renderRowActions():
        (<div className={classNames("emailRow-time", item.read && "emailRow-main-read")}>{getEmailDateTimeFormat(item.date, 1)}</div>) 
      }      
    </div>
  );
}

export default EmailRow;
