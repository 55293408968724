
import { useContext } from 'react';

import { MenuItem, Divider, ListItemIcon, ListItemText, Popover } from '@mui/material';

import OpenWithIcon from '@mui/icons-material/OpenWith';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import LinkIcon from '@mui/icons-material/Link';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import DialogContext from '../../contexts/dialogContext';
import AuthContext from '../../contexts/authContext';

export default function RightContextMenu({pos, show, item, setShow}) {
    const dialogCtx = useContext(DialogContext);
    const authCtx = useContext(AuthContext);

    const handleClose = (e) => {
        setShow(false);
        e.preventDefault();
    };

    const handleShare = () => {
        dialogCtx.showShare();
        setShow(false);
    }

    const handleRename = () => {
        dialogCtx.showRename();
        setShow(false);
    }

    const handleViewDetail = () => {
        dialogCtx.showDetail(true);
        setShow(false);
    }

    const handleMakeCopy = () => {
        dialogCtx.showToast("Created 'copy.file'");
        setShow(false);
    }

    return <Popover
        sx={{
            "& .MuiPaper-root": {
                width: 240,
            }
        }}
        anchorReference="anchorPosition"
        anchorPosition={pos}
        open={show}
        disableEnforceFocus={true}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        onContextMenu={handleClose}
    >
        <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <OpenWithIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Open</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <VisibilityOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Preview</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleShare}>
            <ListItemIcon>
                <PersonAddAltIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleShare}>
            <ListItemIcon>
                <LinkIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Get link</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <StarBorderIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add to Starred</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <StarIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Remove from Starred</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleRename}>
            <ListItemIcon>
                <DriveFileRenameOutlineIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Rename</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleViewDetail}>
            <ListItemIcon>
                <InfoOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleMakeCopy}>
            <ListItemIcon>
                <ContentCopyIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Make a copy</ListItemText>
        </MenuItem>        
        <Divider />
        {/* <MenuItem onClick={handleRemove}>
            <ListItemIcon>
                <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Remove</ListItemText>
        </MenuItem> */}
    </Popover>
}