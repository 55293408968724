
import { useContext } from 'react';

import { Avatar as MuiAvatar } from '@mui/material';
import AuthContext from '../../contexts/authContext';

function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export default function AvatarM({size, name}) {
    const authCtx = useContext(AuthContext);

    if (name === undefined)
        name = authCtx.getAccount();
        
    return <MuiAvatar
        sx={{
            width: size,
            height: size,
            backgroundColor: stringToColor(name),
            textTransform: 'uppercase',
            borderRadius: '3px',
            fontSize: size / 2
        }}
        variant="square" >
        {name === "" ? "" : name[0]}
    </MuiAvatar>
}