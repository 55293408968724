
import ErrorIcon from '@mui/icons-material/Error';

export default function HelperText({error}) {
  return (
    error !== "" ? <>
        <ErrorIcon sx={{fontSize: "1rem", marginRight: "4px"}}/>
        <span className="align-middle">{error}</span>
    </> : ""
  )
}