import styled from 'styled-components';

const FileUploadInfo = styled.div`
  text-align: right;
  font-size: 12px;
`;

const UploadArea = styled.div`
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
  border-style: dashed;
`;

const FlexRowWrap = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  justify-content: end;
`;

const FlexItem = styled.div`
  height: 100%;
`;

export { UploadArea, FileUploadInfo, FlexRowWrap, FlexItem };
