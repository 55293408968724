
import { useEffect, useState, useRef, useContext } from "react";
import { Link, Box, Button, FormHelperText } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingContext from '../../contexts/loadingContext';
import AuthContext from "../../contexts/authContext";

import { formatPhoneNumber, hidePhoneInfo, hideMailInfo } from '../../utils';
import { sendSms, verifySms } from '../../apis';

const t_inputError = "Enter a verification code";
const t_invalidError = "Enter valid verification code";
const t_failedError = "Failed to send verify code";

export default function VerifyCode({goToSignIn, onBack, onNext, contact, recoveryContact, verifyData}) {
    const loadingCtx = useContext(LoadingContext);
    const authCtx = useContext(AuthContext);

    const verifyRef = useRef(null);

    const [error, setError] = useState("");

    const [verifyCode, setVerifyCode] = useState("");
    const [encryptCode, setEncryptCode] = useState("");

    useEffect(() => {
        setEncryptCode(verifyData);
    }, [verifyData]);

    const handleNext = () => {
        if (verifyCode === "") {
            setError(t_inputError);
            verifyRef.current.focus();
        }
        else {
            loadingCtx.setLoading(true);
            setTimeout(() => {
                verifySms(verifyCode, encryptCode)
                .then((res) => {
                    if (res.result) {
                        setError("");
                        onNext();
                    }
                    else {
                        setError(t_invalidError);
                        verifyRef.current.focus();
                    }
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {})
            }, 1000);
        }
    }

    const handleRequest = () => {
        loadingCtx.setLoading(true);
        setTimeout(() => {
            sendSms(recoveryContact.address, recoveryContact.phone, encryptCode)
                .then((res) => {
                    setEncryptCode(res.message);
                    setError("");
                    setVerifyCode('');
                    verifyRef.current.focus();
    
                    loadingCtx.setLoading(false);
                })
                .catch((e) => {
                    setError(t_failedError);
                    verifyRef.current.focus();
    
                    loadingCtx.setLoading(false);
                })
        }, 1000)
    }
    
    return (
        <>
            <p className="text-2xl text-center pb-2">Account recovery</p>
            <p className="text-base text-center pb-4">This helps show that this account really belongs to you</p>
            <p className="text-base text-center pb-8">
                <Button
                    variant="outlined"
                    sx={{
                        textTransform: "none",
                        borderRadius: "99px"
                    }}
                    startIcon={<AccountCircleIcon />}
                    endIcon={<ExpandMoreIcon />}
                    onClick={()=> {authCtx.setAddress(contact.address); goToSignIn();}}>
                    {contact.address}
                    {formatPhoneNumber(contact.phone, true)}
                </Button>
            </p>
            {
                recoveryContact.address !== "" ?
                <p className="text-sm pb-4">An email with a verification code was just sent to {hideMailInfo(recoveryContact.address)}</p> :
                <p className="text-sm pb-4">A text message with a 6-digit verification code was just sent to {hidePhoneInfo(recoveryContact.phone)}</p>
            }
            <Box className="pb-8">
                <TextInput
                    label="Enter code"
                    value={verifyCode}
                    error={error !== ""}
                    inputRef={verifyRef}
                    size="default"
                    onChange={(e) => {
                        const re = /^[0-9]{0,6}$/;
                        // console.log(e.target.value);
                        // if value is not blank, then test the regex
                        if (e.target.value === '' || re.test(e.target.value)) {
                            setVerifyCode(e.target.value)
                        }
                    }} />
                {
                    error === "" ? "" :
                    <FormHelperText error>
                        <HelperText error={error} />
                        {
                            error === t_inputError ? "" :
                            <Link
                                component="button"
                                underline="none"
                                onClick={handleRequest}>
                                <span className="pl-2">Request a new code</span>
                            </Link>
                        }
                    </FormHelperText>
                }
            </Box>
            <Box className="flex">
                <Button variant="text" sx={{textTransform: "none"}} onClick={onBack}>Try another way</Button>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Next</Button>
            </Box>
        </>
    );
}