import axios from '../@axios';

export const _uploadProfile = (token, image) => {
	let formData = new FormData();
	formData.append("token", token);
	formData.append("image", image);

	return new Promise((resolve, reject) => {
		axios.post('/api/v1/user/uploadProfile', formData, {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		}).then((response) => {
			const response_data = response.data;
			if (response.status === 200)
				resolve(response_data);
			else
				reject(response_data);
		})
		.catch((err) => {
			reject(err);
		});
	});
}

export const _getProfile = (token) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/getProfile`, {
				token
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const findUser = (first_name, last_name, address, phone) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/find`, {
				first_name, last_name, address, phone
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const createUser = (first_name, last_name, address, password, verify_phone, recovery_phone, recovery_mail, birthday, gender, last_login) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/create`, {
				first_name, last_name, address, password, verify_phone, recovery_phone, recovery_mail, birthday, gender, last_login
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const sendSms = (address, phone, encryptCode) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/send_sms`, {
				address, phone, encryptCode
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const verifySms = (verifyCode, encryptCode) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/verify_sms`, {
				verifyCode, encryptCode
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const verifySmsMailRecovery = (verifyCode, encryptCode) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/verify_sms_mail_recovery`, {
				verifyCode, encryptCode
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const verifySmsSignIn = (verifyCode, encryptCode, contact, login_ip) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/verify_sms_signin`, {
				verifyCode, encryptCode, contact, login_ip
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const verifyPassword = (contact, password, login_ip) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/verify_password`, {
				contact, password, login_ip
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const findForgottenUser = (first_name, last_name, address, phone) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/find_forgotten_user`, {
				first_name, last_name, address, phone
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getRecoveryInfo = (contact) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_recovery_info`, {
				contact
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const resetPassword = (contact, password) => {
    return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/reset_password`, {
				contact, password
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getDomains = () => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_domains`, { })
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const getDomainLogo = (domain) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`/api/v1/user/get_domain_logo`, {
				domain
			})
			.then((response) => {
				const response_data = response.data;
				if (response.status === 200)
					resolve(response_data);
				else
					reject(response_data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
