
import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import { Container, UniMail } from '../../Components';
import Login from './Login';
import VerifyPassword from './VerifyPassword';
import VerifyPhone from './VerifyPhone';

import AuthContext from '../../contexts/authContext';

export default function SignIn() {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const authCtx = useContext(AuthContext);

    const [step, setStep] = useState(0);
    const [contact, setContact] = useState("");
    const [verifyData, setVerifyData] = useState({});

    useEffect(() => {
        if (location.state?.address) {
            setStep(1);
            setContact({
                address: location.state.address,
                phone: ''
            });
        }
    }, [location])

    const onNext = () => {
        setStep(step + 1);
    }

    const onBack = () => {
        setStep(step - 1);
    }

    const onStart = () => {
        setStep(0);
    }

    const goToHome = (token) => {
        authCtx.setToken(token);

        const redirect = searchParams.get('redirect');
        console.log("redirect", redirect);
        if (redirect === null || redirect === undefined || redirect === '')
            navigate('/');
        else
            window.location.href = redirect;
    }

    const render = () => {
        if (step === 0)
            return <Login onNext={onNext} setContact={setContact} />
        else if (step === 1)
            return <VerifyPassword onNext={onNext} onBack={onBack} contact={contact} goToHome={goToHome} setVerifyData={setVerifyData} />
        else if (step === 2)
            return <VerifyPhone onStart={onStart} onNext={onNext} onBack={onBack} contact={contact} goToHome={goToHome} verifyData={verifyData} />
    }
    
    return (
        <Container>
            <UniMail />
            <div className="sm:w-[370px]">
                { render() }
            </div>
        </Container>
    );
}