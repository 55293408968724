
import { useState, useContext, useEffect } from 'react';

import { Box, Breadcrumbs, Link } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import DataTable from './DataTable';

import { getFolder } from './apis';

import AuthContext from '../../../contexts/authContext';
import DialogContext from '../../../contexts/dialogContext';

const fields = [
    {key: "name", label: "Name"},
    {key: "owner", label: "Owner"},
    {key: "modified", label: "Last Modified"},
]

export default function Folder({setTab}) {
    const authCtx = useContext(AuthContext);
    const dialogCtx = useContext(DialogContext);

    const [files, setFiles] = useState([]);
    const [folders, setFolders] = useState([]);
    const [details, setDetails] = useState(null);

    const refresh = () => {
        getFolder(dialogCtx.driveFolder, authCtx.getAddress())
        .then((res) => {
            setFiles(res.files);
            setFolders(res.folders);
            setDetails(res);
        })
        .catch((e) => {})
    }

    useEffect(() => {
        refresh();
    }, [dialogCtx.driveFolder])

    return <>
        {details !== null && <Box className="px-6 pt-6">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            {
                details.owner ?
                <Link underline="hover" color="inherit" className="cursor-pointer" onClick={() => setTab(null, 0)}>
                    My Drive
                </Link>
                :
                <Link underline="hover" color="inherit" className="cursor-pointer" onClick={() => setTab(null, 1)}>
                    Shared with me
                </Link>
            }
            {
                details.navs.map((path, i) => {
                    return <Link underline="hover" color="inherit" className="cursor-pointer" key={i} onClick={() => dialogCtx.setDriveFolder(path.id)}>
                        {path.text}
                    </Link>
                })
            }
        </Breadcrumbs>
        </Box>}
        <DataTable folders={folders} files={files} fields={fields} />
    </>
}