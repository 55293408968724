
import { useState, useRef, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Link, Checkbox, FormGroup, FormControlLabel, FormControl, Select, MenuItem, InputLabel, FormHelperText } from '@mui/material';
import { TextInput, HelperText } from '../../Components';
import MuiPhoneNumber from 'material-ui-phone-number';
import LoadingContext from '../../contexts/loadingContext';
import AuthContext from '../../contexts/authContext';

import { createUser  } from '../../apis';
import { getPublicIp, getPhoneNumber  } from '../../utils';

const t_birthdayError = "Please select your birthday";
const t_genderError = "Please select your gender";

const months = [
    {value: "0", label: "January"},
    {value: "1", label: "February"},
    {value: "2", label: "March"},
    {value: "3", label: "April"},
    {value: "4", label: "May"},
    {value: "5", label: "June"},
    {value: "6", label: "July"},
    {value: "7", label: "August"},
    {value: "8", label: "September"},
    {value: "9", label: "October"},
    {value: "10", label: "November"},
    {value: "11", label: "December"},
]

export default function PersonalInfo({onNext, onBack, user}) {
    const navigate = useNavigate();
    const loadingCtx = useContext(LoadingContext);
    const authCtx = useContext(AuthContext);

    const yearRef = useRef(null);
    const monthRef = useRef(null);
    const dayRef = useRef(null);
    const genderRef = useRef(null);
    const acceptRef = useRef(null);

    const [birthdayError, setBirthdayError] = useState("");
    const [genderError, setGenderError] = useState("");

    const [phone, setPhone] = useState("");
    const [mail, setMail] = useState("");
    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [gender, setGender] = useState("");

    const [acceptTerms, setAcceptTerms] = useState(false);

    const handleNext = () => {
        let focusObj = null;

        if (gender === "") {
            setGenderError(t_genderError);
            focusObj = genderRef.current;
        }
        else {
            setGenderError("");
        }

        if (month === "") {
            setBirthdayError(t_birthdayError);
            focusObj = monthRef.current;
        }
        else if (day === "") {
            setBirthdayError(t_birthdayError);
            focusObj = dayRef.current;
        }
        else if (year === "") {
            setBirthdayError(t_birthdayError);
            focusObj = yearRef.current;
        }
        else {
            setBirthdayError("");
        }

        if (!acceptTerms) {
            focusObj = acceptRef.current;
            alert('Please accept terms of Unimail');
        }

        if (focusObj !== null) {
            focusObj.focus();
        }
        else {
            loadingCtx.setLoading(true);
            setTimeout(() => {
                getPublicIp()
                .then((publicIp) => {
                    createUser(
                        user.firstName,
                        user.lastName,
                        user.address,
                        user.password,
                        user.verifyPhone,
                        getPhoneNumber(phone),
                        mail,
                        new Date(Date.UTC(year, month, day)),
                        gender,
                        publicIp
                    )
                    .then((res) => {
                        loadingCtx.setLoading(false);
                        if (res.result) {
                            authCtx.setToken(res.token);
                            navigate('/');
                        }
                        else {
                            navigate('/signin');
                        }
                    })
                    .catch((e) => {})
                })
                .catch((e) => {})
            }, 1000);
        }
    }
    
    return (
        <>
            <p className="text-2xl pb-2">{user.firstName}, welcome to Uni Mail</p>
            <p className="text-base pb-12">{user.address}</p>
            <div className="pb-2">
                <MuiPhoneNumber
                    defaultCountry={'in'}
                    variant="outlined"
                    fullWidth
                    label="Phone number (optional)"
                    sx={{
                        "& .MuiInput-root::before": {borderBottom: 0},
                        "& .Mui-error": { marginLeft: 0, marginRight: 0 }
                    }}
                    onChange={(e) => {
                        setPhone(e);
                    }}/>
            </div>
            <p className="text-sm pb-8">We'll use your number for account security. It won't be visible to others.</p>
            <div className="pb-8">
                <TextInput
                    label="Recovery email address (optional)"
                    type="email"
                    value={mail}
                    onChange={(e) => {
                        setMail(e.target.value);
                    }} />
                <p className="text-sm pt-2">We'll use it to keep your account secure</p>
            </div>
            <div className="grid grid-cols-3 gap-4">
                <FormControl fullWidth>
                    <InputLabel size="small">Month</InputLabel>
                    <Select
                        value={month}
                        label="Month"
                        size="small"
                        inputRef={monthRef}
                        error={birthdayError !== "" && month === ""}
                        onChange={(e) => {setMonth(e.target.value)}}
                    >
                        {
                            months.map((item, i) => {
                            return <MenuItem key={i} value={item.value}>
                                {item.label}
                            </MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <TextInput
                    label="Day"
                    value={day}
                    inputRef={dayRef}
                    error={birthdayError !== "" && day === ""}
                    onChange={(e) => {
                        const re = /^[0-9]{0,2}$/;
                        // console.log(e.target.value);
                        // if value is not blank, then test the regex
                        if (e.target.value === '' || re.test(e.target.value)) {
                            setDay(e.target.value)
                        }
                    }} />
                <TextInput
                    label="Year"
                    value={year}
                    inputRef={yearRef}
                    error={birthdayError !== "" && year === ""}
                    onChange={(e) => {
                        const re = /^[0-9]{0,4}$/;
                        // console.log(e.target.value);
                        // if value is not blank, then test the regex
                        if (e.target.value === '' || re.test(e.target.value)) {
                            setYear(e.target.value)
                        }
                    }} />
            </div>
            <FormHelperText error={birthdayError !== ""} className="pb-1">
                <HelperText error={birthdayError} />
            </FormHelperText>
            <p className="text-sm pb-8 mt-2">Your birthday</p>
            <div className="pb-16">
                <FormControl fullWidth>
                    <InputLabel size="small">Gender</InputLabel>
                    <Select
                        value={gender}
                        label="Gender"
                        size="small"
                        inputRef={genderRef}
                        error={genderError !== ""}
                        onChange={(e) => {setGender(e.target.value)}}
                    >
                        <MenuItem value='F'>Female</MenuItem>
                        <MenuItem value='M'>Male</MenuItem>
                        <MenuItem value='N'>Rather not say</MenuItem>
                        <MenuItem value='C'>Custom</MenuItem>
                    </Select>
                </FormControl>
                <FormHelperText error={genderError !== ""}>
                    <HelperText error={genderError} />
                </FormHelperText>
            </div>
            <div className="pb-16 flex items-center">
                <FormGroup>
                    <FormControlLabel inputRef={acceptRef} control={<Checkbox value={acceptTerms} onChange={(e) => {setAcceptTerms(e.target.checked)}} />} label="Accept terms of Unimail" style={{width: 'fit-content'}}/>
                </FormGroup>
                <Link href="/terms" /*underline="none"*/>Read Terms</Link>
            </div>
            <div className="flex">
                <Button variant="text" sx={{textTransform: "none"}} onClick={onBack}>Back</Button>
                <p className="ml-auto"></p>
                <Button variant="contained" sx={{textTransform: "none"}} onClick={handleNext}>Ok</Button>
            </div>
        </>
    ); 
}