import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function Terms() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Introduction" {...a11yProps(0)} />
        <Tab label="Description of Service" {...a11yProps(1)} />
        <Tab label="Use of Service" {...a11yProps(2)} />
        <Tab label="User Accounts" {...a11yProps(3)} />
        <Tab label="Privacy" {...a11yProps(4)} />
        <Tab label="Intellectual Property" {...a11yProps(5)} />
        <Tab label="Limitation of Liability" {...a11yProps(6)} />
        <Tab label="Indemnification" {...a11yProps(7)} />
        <Tab label="Acceptance of Terms of Service" {...a11yProps(8)} />
        <Tab label="Termination" {...a11yProps(9)} />
        <Tab label="Modification of Terms of Service" {...a11yProps(10)} />
        <Tab label="User Conduct" {...a11yProps(11)} />
        <Tab label="User Content" {...a11yProps(12)} />
      </Tabs>
      <TabPanel value={value} index={0} style={{ width: '70%' }}>
      Welcome to Unimail! By using our service, you agree to be bound by the following terms and conditions ("Terms of Service"). Please read them carefully before using our service.

As a user of Unimail, you are expected to read and fully understand the Terms of Service before using the service. By agreeing to these terms, you are legally bound to abide by the rules and guidelines set forth by Unimail, which include refraining from engaging in any activities that violate the law or infringe upon the rights of others. You are also responsible for maintaining the confidentiality of your account and password, as well as for any content that you transmit through the service.

It is important to note that Unimail reserves the right to modify or update the Terms of Service at any time, and that your continued use of the service following any such modification constitutes your agreement to be bound by the modified Terms of Service. It is your responsibility to review the Terms of Service periodically to ensure that you are aware of any modifications. If you do not agree to these Terms of Service, please refrain from using our service.
      </TabPanel>
      <TabPanel value={value} index={1} style={{ width: '70%' }}>
      Unimail is an online email service that provides users with a convenient and efficient way to send and receive emails using a web-based interface. This means that users can access their email account from any device with internet access, without the need for any software or special setup.

In addition to basic email functionality, Unimail offers users a variety of additional features to enhance their email experience. These features include email filters, which allow users to automatically sort and organize their emails based on certain criteria such as sender, subject, or keyword. The service also includes an address book, which allows users to easily store and manage contact information for their email recipients.

Furthermore, Unimail provides users with a video meeting feature, which enables users to host and join virtual meetings directly within their email interface. The service also includes a drive feature, allowing users to store and access files and documents online. Additionally, Unimail provides office features like sheets, docs, and ppt, enabling users to create and collaborate on spreadsheets, documents, and presentations with others.

Moreover, Unimail provides users with a calendar feature, allowing them to keep track of important events and appointments directly within their email interface. With this feature, users can schedule and organize meetings, set reminders, and view their calendar alongside their email inbox, making it easier to manage their time and schedule.
      </TabPanel>
      <TabPanel value={value} index={2} style={{ width: '70%' }}>
      By agreeing to these Terms of Service, you acknowledge that you will only use Unimail's services for lawful purposes, in strict compliance with all applicable laws, regulations, and ethical standards, and in accordance with the terms and conditions set forth by Unimail. Additionally, you agree to be solely responsible for any content you transmit through the service, including text, images, videos, or any other digital media.

Unimail strictly prohibits the use of its services for spamming or other forms of unsolicited messages, including but not limited to advertisements, chain letters, or pyramid schemes. Moreover, the transmission of any email containing viruses or other harmful code is strictly prohibited and may result in the immediate termination of your account.

Unimail reserves the right to investigate and take appropriate legal action against any user who violates these Terms of Service. By using Unimail's services, you agree to cooperate with any investigation conducted by Unimail or its authorized agents. Any violation of these Terms of Service may result in the suspension or termination of your account, and Unimail reserves the right to pursue legal action against any user who engages in illegal or unauthorized activities while using the service.
      </TabPanel>
      <TabPanel value={value} index={3} style={{ width: '70%' }}>
      To access Unimail's services, you must first create a user account and provide accurate and complete information about yourself. You agree to maintain the confidentiality of your account and password, and you acknowledge that you are solely responsible for all activities that occur under your account. If you become aware of any unauthorized use of your account, including but not limited to unauthorized access or use of your password, you agree to notify Unimail immediately to prevent any further unauthorized access or use of your account. Failure to comply with these requirements may result in the immediate termination of your account or other appropriate action by Unimail.

You agree to use the Unimail service only for lawful purposes and in compliance with these Terms of Service. Any use of our service that violates any applicable law or regulation is strictly prohibited. You agree not to use the Unimail service for any illegal, abusive, or unauthorized purpose. You acknowledge that you are solely responsible for all content that you transmit through our service, and that Unimail has no responsibility or liability for such content.

You agree not to use the Unimail service to transmit spam or other unsolicited messages, or to send emails containing viruses or other harmful code. You acknowledge that any attempt to interfere with the proper functioning of the Unimail service may result in legal action against you, and that Unimail may take any steps it deems necessary to prevent such interference.
      </TabPanel>
      <TabPanel value={value} index={4} style={{ width: '70%' }}>
      We understand that your privacy is important and we are committed to protecting your personal information. To ensure your data is safe, we have implemented various security measures, and we continuously monitor our systems for any potential threats or vulnerabilities. We also adhere to strict data protection laws and regulations in order to safeguard your privacy. Please review our Privacy Policy carefully to understand how we collect, use, and protect your personal information. If you have any questions or concerns about our Privacy Policy, please contact us.
      </TabPanel>
      <TabPanel value={value} index={5} style={{ width: '70%' }}>
      We have invested significant time, effort, and resources into creating and developing our service, and as such, we own all rights, title, and interest in and to it, including all intellectual property rights. You may use our service for personal and non-commercial purposes only, and any other use is strictly prohibited without our express written consent. If you wish to use our service for any commercial purpose, please contact us to discuss a possible partnership or licensing agreement.
      </TabPanel>
      <TabPanel value={value} index={6} style={{ width: '70%' }}>
      We make every effort to ensure that our service is reliable, efficient, and of high quality, but we cannot guarantee that it will always be perfect or meet your expectations. Therefore, we provide our service "as is" and without any warranty of any kind, either express or implied. We do not make any representations or warranties about the accuracy, completeness, or reliability of our service or the content transmitted through it. Moreover, we are not liable for any damages arising out of your use of our service, including but not limited to lost profits or data, or any direct, indirect, incidental, special, or consequential damages, even if we have been advised of the possibility of such damages. In any case, our liability to you for any reason whatsoever shall be limited to the amount that you have paid us, if any, for the use of our service.
      </TabPanel>
      <TabPanel value={value} index={7} style={{ width: '70%' }}>
      By agreeing to these Terms of Service, you understand and acknowledge that you are solely responsible for your use of our service and any consequences that may result. You agree to indemnify, defend, and hold us and our affiliates, officers, agents, partners, and employees harmless from any claims, damages, or expenses, including but not limited to attorney's fees, arising out of your use of our service or any violation of these Terms of Service by you or any other person accessing our service through your account. You agree to cooperate with us in the defense of any such claim, and we reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you.
      </TabPanel>
      <TabPanel value={value} index={8} style={{ width: '70%' }}>
      By continuing to use our service after any modifications to these Terms of Service have been made, you acknowledge and agree that you have read, understood, and accepted the modified terms. It is your responsibility to review these Terms of Service periodically for any updates or changes. If you do not agree to the modified terms, please refrain from using our service.
      </TabPanel>
      <TabPanel value={value} index={9} style={{ width: '70%' }}>
      We may terminate your use of our service at any time, with or without cause, and without notice.

In the event of termination, you must immediately cease using our service and any related materials. We shall not be liable for any damages or costs arising from the termination of your use of our service.

A termination of your use of our service may result in the deactivation or deletion of your account and all related information and data associated with it. We reserve the right to refuse service to anyone for any reason at any time. Upon termination of your use of our service, any provisions of these Terms of Service that by their nature should survive termination shall continue to apply, including, but not limited to, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
      </TabPanel>
      <TabPanel value={value} index={10} style={{ width: '70%' }}>
      In order to ensure that our service remains up-to-date and aligned with our goals and values, we reserve the right to modify these Terms of Service at any time. Any changes will be posted on our website and will take effect immediately. Your continued use of our service after any modifications indicate your acceptance of the revised Terms of Service. Therefore, we encourage you to regularly review the Terms of Service to stay informed about any updates.

Any changes to the Terms of Service will be posted on our website, and it is your responsibility to review them periodically. If you do not agree to the modified terms, you must stop using our service. We may also, in our sole discretion, add or remove features or functionalities from our service without notice or liability to you.If you have any questions or concerns about these Terms of Service, please contact us.
      </TabPanel>
      <TabPanel value={value} index={11} style={{ width: '70%' }}>
      By using our service, you acknowledge and agree that you will conduct yourself in a manner that is respectful and lawful, and that you will not use our service to engage in any activity that is illegal, harmful, or offensive in any way. You understand that any material you transmit or store using our service must comply with all applicable laws and regulations, and you acknowledge that you are solely responsible for any such material. Furthermore, you agree not to harass, bully, intimidate, or otherwise harm any other users of our service, and to respect their right to use our service without interference. 

Furthermore, you agree not to use our service to engage in any conduct that could damage, disable, overburden, or impair our servers or networks. You may not attempt to gain unauthorized access to our service or any other user's account. You agree to comply with all applicable laws and regulations regarding your use of our service.
You may not use our service to distribute spam or unsolicited messages, or to send emails containing viruses, Trojan horses, or any other malicious code. You are solely responsible for any content that you transmit through our service and for ensuring that such content does not violate any applicable laws or regulations. We reserve the right to remove any content that we deem to be in violation of these terms or that is otherwise objectionable.

We reserve the right to investigate any reports of user misconduct and  We may also, at our sole discretion, limit, suspend or terminate your access to our service or take any other action we deem appropriate if we believe that you have violated these terms or any applicable law, or for any other reason without prior notice or liability. By using our service, you acknowledge and agree that we shall not be liable to you or to any third party for any modification, suspension or discontinuance of our service.
      </TabPanel>
      <TabPanel value={value} index={12} style={{ width: '70%' }}>
      You retain ownership of any content that you transmit through our service, but you grant us a non-exclusive, royalty-free, worldwide license to use, reproduce, modify, and distribute that content in connection with our service. You represent and warrant that you have all necessary rights to grant this license.

By transmitting any content through our service, you acknowledge and agree that you are solely responsible for the content you submit and the consequences that may result from your submission. You further acknowledge and agree that we have the right, but not the obligation, to monitor and review any content transmitted through our service and that we may delete or remove any content that we, in our sole discretion, determine to be in violation of these Terms of Service or that is otherwise objectionable.
You are solely responsible for any content that you transmit through our service, and you agree not to transmit any content that is illegal, defamatory, obscene, or otherwise objectionable. We reserve the right to remove any content that we determine violates these terms, without notice. You acknowledge and agree that we may access, preserve, and disclose your account information and content if required to do so by law or in a good faith belief that such access, preservation, or disclosure is reasonably necessary to: (i) comply with legal process; (ii) enforce these Terms of Service; (iii) respond to claims that any content violates the rights of third parties; (iv) respond to your requests for customer service; or (v) protect the rights, property, or personal safety of our company, its users, and the public.

We reserve the right to remove or modify any content transmitted through our service that we believe violates these terms, infringes on the intellectual property rights of others, or is otherwise objectionable. You agree to indemnify and hold us harmless from any claims arising out of your transmission of content through our service. We do not guarantee the accuracy, completeness, or usefulness of any content transmitted through our service, and we are not responsible for any errors or omissions in such content.

Third-Party Websites

Please note that any links to third-party websites provided on our service are provided solely for your convenience and do not indicate our endorsement, sponsorship, or affiliation with any such website. We do not control or assume any responsibility for the content, privacy policies, or practices of any third-party website. Your use of any third-party website is at your own risk, and you should review the applicable terms and policies before using such websites.

We do not endorse or make any representations about any third-party websites, and we disclaim any and all liability in connection with your use of such websites. You agree that we will not be responsible or liable for any damages or losses caused by or in connection with the use of or reliance on any content, goods, or services available on or through any third-party websites.

In no event shall we be liable for any damages arising out of or in connection with your use of any third-party website or service, including but not limited to any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such website or service.

Dispute Resolution

The parties agree that any dispute or controversy arising out of or relating to these Terms of Service or the breach, termination, or invalidity thereof, shall be settled by arbitration administered by the our Country Rules, and judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof. The arbitration will be conducted in the English language, and each party shall bear its own costs and expenses of arbitration.

The arbitration will take place in [city], [state], unless you and we agree to a different location. You and we agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated, or representative action. If for any reason a claim proceeds in court rather than in arbitration, you and we each waive any right to a jury trial.

Waiver

It is important to note that our failure to enforce any provision of these Terms of Service does not constitute a waiver of that provision or our right to enforce it in the future, and we reserve the right to take appropriate action to enforce any provision of these Terms of Service at any time

Changes to the Service

We may make changes to our service, including but not limited to modifications, suspensions, or discontinuations, at any time and without prior notice to you, and we will not be held liable for any resulting damages or losses incurred by you or any third party.

We may add new features or functionalities to our service or impose limits on certain existing features or functionalities at any time. We may also update or revise these features or functionalities, or add new terms to these Terms of Service, and your continued use of the service after such changes have been made constitutes your acceptance of the revised Terms of Service.

We may also add new features or functionality to our service from time to time, and your continued use of the service after any such changes will constitute your acceptance of the updated terms. However, we have no obligation to provide any updates or new features, and we may discontinue any aspect of the service at any time.

Disclaimer of Warranties
Our service is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, as to the operation of our service or the information, content, materials, or products included on our service. You expressly agree that your use of our service is at your sole risk.

Limitation of Liability
We will not be liable for any damages of any kind arising from the use of our service, including, but not limited to direct, indirect, incidental, punitive, and consequential damages. In no event will our liability exceed the amount paid by you, if any, for accessing our service.

Assignment
We may assign these Terms of Service, in whole or in part, at any time without notice to you. You may not assign these Terms of Service or transfer any rights to use our service without our prior written consent.

Governing Law and Jurisdiction

In the event of any dispute arising out of or related to these Terms of Service, the laws of the state of [state] will apply, and any legal action or proceeding will be brought exclusively in the state or federal courts located in [county], [state]. By using our service, you agree to submit to the jurisdiction of such courts and waive any objections to the venue of such courts.

Furthermore, any claim or cause of action you may have with respect to these Terms of Service or our service must be commenced within one (1) year after the claim or cause of action arises or it will be forever barred. Our failure to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of these Terms of Service remain in full force and effect.

Furthermore, if any provision of these Terms of Service is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect. These Terms of Service represent the entire agreement between you and us and supersede all prior negotiations, representations, and agreements between the parties. No waiver or modification of these Terms of Service will be binding unless in writing and signed by both parties.

No Third-Party Beneficiaries

These Terms of Service establish a legal agreement between you and Unimail and are intended solely for your benefit and the benefit of Unimail; they are not intended to create any rights in favor of any third party or be enforceable by any third party. Therefore, any exercise of rights or remedies under these Terms of Service by either party is not subject to the consent or participation of any third party.

We and you acknowledge that the provisions of these Terms of Service are a contract between us and are not intended to confer any rights or remedies upon any third party, and that no third party will have the right to rely on any provision of these Terms of Service.

Furthermore, no third party shall have any right to enforce or rely upon any provision of these Terms of Service, and any attempt to do so shall be deemed invalid. The rights and obligations under these Terms of Service are personal to you and Unimail, and nothing in these Terms of Service shall be deemed to create any partnership, joint venture, agency, or employment relationship between the parties

Notices

In the event that either party needs to send a notice to the other party regarding these Terms of Service, such notice must be in writing and sent to the email address or mailing address provided by the other party, and shall be considered as received by the addressee upon delivery confirmation or after three (3) business days from the date of mailing, whichever comes first.

It is your responsibility to ensure that the contact information associated with your account is accurate and up-to-date, and you agree to promptly notify us of any changes to your contact information. Any notices sent to the email address or mailing address associated with your account will be deemed received by you within 24 hours of their delivery.

Survival
The provisions of these Terms of Service that by their nature should survive termination shall survive any termination or expiration of these Terms of Service.
Thank you for using Unimail! If you have any questions or concerns about these Terms of Service, please contact us at [contact information].

Force Majeure
We will not be liable for any failure or delay in performing our obligations under these Terms of Service if such failure or delay is caused by circumstances beyond our reasonable control, including, without limitation, acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, or strikes.

Feedback
We welcome feedback, comments, and suggestions for improvements to our service ("Feedback"). You may submit Feedback by emailing us atsupport@unimail.com. You agree that all Feedback will become our sole and exclusive property, and you hereby assign to us all of your right, title, and interest in and to all Feedback.

Severability
If any provision of these Terms of Service is held to be invalid or unenforceable by a court or other tribunal of competent jurisdiction, the remaining provisions of these Terms of Service will remain in full force and effect.

Headings
The headings used in these Terms of Service are included for convenience only and will not limit or otherwise affect these Terms of Service.
Electronic Communications
By using our service, you consent to receiving electronic communications from us. These communications may include notices about your account and information concerning or related to our service. You agree that any notices, agreements, disclosures, or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing.

Acknowledgment
By using our service, you acknowledge that you have read these Terms of Service, understand them, and agree to be bound by their terms and conditions.
Thank you for using Unimail! If you have any questions or concerns about these Terms of Service, please contact us at support@unimail.com.

Entire Agreement
These Terms of Service, together with any additional terms to which you agree when using particular elements of our service, constitute the entire agreement between you and Unimail and supersede all prior agreements, representations, and understandings between us. No waiver of any of these Terms of Service will be deemed a further or continuing waiver of such term or condition or any other term or condition.
Waiver
Our failure to enforce any right or provision of these Terms of Service will not be deemed a waiver of such right or provision.
      </TabPanel>
    </Box>
  );
}